import React from "react";
import { Navigate } from "react-router-dom";

//AuthenticationInner pages
// import Basic404 from "../pages/Authentication/";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Dashboard
import Dashboard from "../pages/DashboardCrm";
import EmployeeShifts from "../pages/Timesheets/Shifts/index";
import Roles from "../pages/Roles/index";
import Permission from "../pages/Permission/Permission";
import OficeShift from "../pages/Timesheets/Shifts/index";
import HolidaySheet from "../pages/holidays/index";
import Promotions from "../pages/HR/Promotions/index";
//Employee
import Employee from "../pages/Employee/Employee";
import ImportEmployees from "../pages/Employee/ImportEmployees";
import AssignRole from "../pages/Employee/AssignRole";
import AttendanceModule from "../pages/Attendance";

//Homepage
import Homepage from "../pages/Homepage";
//View attendance
import ViewAttendance from "../pages/View Attendance/ViewAttendance";
//Profile
import Profile from "../pages/Profile/profile/Profile";
import ProfileSetting from "../pages/Profile/setting/Settings";

import Designation from "../pages/Designation/Designation";

//AuthenticationInner pages
// import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/index", component: <Dashboard /> },
  { path: "/employee", component: <Employee /> },
  { path: "/roles", component: <Roles /> },
  { path: "/permissions", component: <Permission /> },
  { path: "/alltimesheets", component: <OficeShift /> },
  { path: "/holidaysheet", component: <HolidaySheet /> },
  { path: "/view-attendance", component: <ViewAttendance /> },
  { path: "/view-attendance", component: <ViewAttendance /> },
  {
    path: "/attendance",
    component: <AttendanceModule />,
  },
  { path: "/office-shift", component: <EmployeeShifts /> },
  { path: "/promotions", component: <Promotions /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  { path: "/importemployees", component: <ImportEmployees /> },
  { path: "/assignrole", component: <AssignRole /> },

  { path: "/homepage", component: <Homepage /> },
  { path: "/profile", component: <Profile /> },
  { path: "/profile-settings", component: <ProfileSetting /> },

  { path: "/designation", component: <Designation /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  //AuthenticationInner pages
  // { path: "/auth-404-basic", component: <Basic404 /> },
];

export { authProtectedRoutes, publicRoutes };
