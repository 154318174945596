import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import {
  ProductsGlobalFilter,
  FilterCollaboration,
  CustomersGlobalFilter,
  OrderGlobalFilter,
  ContactsGlobalFilter,
  CompaniesGlobalFilter,
  LeadsGlobalFilter,
  CryptoOrdersGlobalFilter,
  InvoiceListGlobalSearch,
  TicketsListGlobalFilter,
  NFTRankingGlobalFilter,
  TaskListGlobalFilter,
  ContactsFilter,
} from "../../Components/Common/GlobalSearchFilter";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  selectedData,
  setGlobalFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isRoleListFilter,
  isProductsFilter,
  isLeadsFilter,
  SearchPlaceholder,
  isSearchInput,
  isFilterLeaderBoard,
  isContactsPageFilter,
  category,
  timeScale,
  reductionPotential,
  cost,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <CardBody className="border border-dashed border-end-0 border-start-0">
        <form>
          <Row className="g-3">
            {/* <Col className="d-flex align-items-center justify-content-between w-100"> */}
            <Col className="d-flex align-items-center  w-100">
              {true && (
                <div
                  className={
                    isProductsFilter ||
                    isContactsFilter ||
                    isCompaniesFilter ||
                    isNFTRankingFilter ||
                    isFilterLeaderBoard ||
                    isSearchInput
                      ? "search-box me-2 mb-0 d-inline-block"
                      : "search-box me-2 mb-0 d-inline-block col-3 d-none"
                  }
                >
                  <input
                    onChange={(e) => {
                      setValue(e.target.value);
                      onChange(e.target.value);
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control search /"
                    placeholder={SearchPlaceholder}
                    value={value || ""}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              )}
              {/* <FilterA /> */}
              {isContactsPageFilter && <ContactsFilter />}
            </Col>

            {/* {isFilterBenchmarkAction && <FilterBenchmarkAction />} */}

            {isProductsFilter && <ProductsGlobalFilter />}
            {isCustomerFilter && <CustomersGlobalFilter />}
            {isOrderFilter && <OrderGlobalFilter />}
            {isContactsFilter && <ContactsGlobalFilter />}
            {isCompaniesFilter && <CompaniesGlobalFilter />}
            {isLeadsFilter && <LeadsGlobalFilter />}
            {isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
            {isInvoiceListFilter && <InvoiceListGlobalSearch />}
            {isTicketsListFilter && <TicketsListGlobalFilter />}
            {isNFTRankingFilter && <NFTRankingGlobalFilter />}
            {isTaskListFilter && <TaskListGlobalFilter />}
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

const TableContainer = ({
  columns,
  setInfo,
  category,
  timeScale,
  reductionPotential,
  cost,
  selectedData,
  isHorzontal,
  isFooter,
  data,
  isGlobalSearch,
  isGlobalFilter,
  //
  isProductsFilter,
  isContactsPageFilter,
  isSearchInput,
  isFilterLeaderBoard,
  //
  isCustomerFilter,
  //
  isOrderFilter,
  //
  isContactsFilter,
  //
  isCompaniesFilter,
  //
  isLeadsFilter,
  //
  isCryptoOrdersFilter,
  //
  isInvoiceListFilter,
  //
  isTicketsListFilter,
  //
  isNFTRankingFilter,
  //
  isTaskListFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  handleNextPageClick,
  handlePreviousPageClick,
  ishandleClick,
  currentPage,
  totalPages,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row className="mb-3">
        {isGlobalSearch && (
          <Col md={1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            category={category}
            timeScale={timeScale}
            reductionPotential={reductionPotential}
            cost={cost}
            setGlobalFilter={setGlobalFilter}
            isProductsFilter={isProductsFilter}
            isCustomerFilter={isCustomerFilter}
            selectedData={selectedData}
            isOrderFilter={isOrderFilter}
            isContactsFilter={isContactsFilter}
            isCompaniesFilter={isCompaniesFilter}
            isLeadsFilter={isLeadsFilter}
            isCryptoOrdersFilter={isCryptoOrdersFilter}
            isInvoiceListFilter={isInvoiceListFilter}
            isTicketsListFilter={isTicketsListFilter}
            isNFTRankingFilter={isNFTRankingFilter}
            isSearchInput={isSearchInput}
            isTaskListFilter={isTaskListFilter}
            SearchPlaceholder={SearchPlaceholder}
            isFilterLeaderBoard={isFilterLeaderBoard}
            isContactsPageFilter={isContactsPageFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {isHorzontal
              ? null
              : headerGroups.map((headerGroup) => (
                  <tr
                    className={trClass}
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        key={column.id}
                        className={thClass}
                        {...column.getSortByToggleProps()}
                      >
                        {column.render("Header")}
                        {/* <i class="bi bi-sort-alpha-up-alt"></i> */}
                        {generateSortingIndicator(column)}
                      </th>
                    ))}
                  </tr>
                ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {isHorzontal &&
              headerGroups.map((headerGroup) => (
                <th
                  className={trClass}
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <tr
                      key={column.id}
                      className={thClass}
                      {...column.getSortByToggleProps()}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </tr>
                  ))}
                </th>
              ))}

            {isHorzontal
              ? page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <th>
                        {row.cells.map((cell) => {
                          return (
                            <tr
                              key={cell.id}
                              {...cell.getCellProps()}
                              onClick={() => {
                                setInfo(cell?.row?.original);
                              }}
                            >
                              {cell.render("Cell")}
                            </tr>
                          );
                        })}
                      </th>
                    </Fragment>
                  );
                })
              : page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              {...cell.getCellProps()}
                              onClick={() => {
                                setInfo(cell?.row?.original);
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
          </tbody>
        </Table>
      </div>
      {isFooter ? (
        <Row className="justify-content-md-end justify-content-center align-items-center p-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                //  onClick={previousPage}
                onClick={ishandleClick ? handlePreviousPageClick : previousPage}
                disabled={currentPage > 1 ? canPreviousPage : !canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {currentPage ? currentPage : pageIndex + 1} of{" "}
              {totalPages ? totalPages : pageOptions?.length}
            </strong>
          </Col>
          {!ishandleClick && (
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions?.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
              />
            </Col>
          )}

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                //  onClick={nextPage}
                onClick={ishandleClick ? handleNextPageClick : nextPage}
                disabled={currentPage < totalPages ? canNextPage : !canNextPage}
              >
                {">"}
              </Button>
            </div>
          </Col>
        </Row>
      ) : null}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
