import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import { layouts } from "chart.js";

const SimplePie = ({ dataColors }) => {
  var chartPieBasicColors = getChartColorsArray(dataColors);
  const series = [44, 55, 13, 43];
  var options = {
    chart: {
      height: 300,
      type: "pie",
    },
    labels: [
      "Android developer",
      "Finance Manager",
      "Senior Programmer",
      "Data Analyst",
    ],
    legend: {
      position: "bottom",
    },
    layouts: {
      padding: 50,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartPieBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="pie"
      height={267.7}
    />
  );
};
const UpdateExpenseDonut = ({ dataColors }) => {
  var chartPieBasicColors = getChartColorsArray(dataColors);
  const series = [30, 55];
  var options = {
    chart: {
      height: 300,
      type: "pie",
    },
    labels: ["Expense ", "Deposit"],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartPieBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="pie"
      height={267.7}
    />
  );
};

const SimpleDonut = ({ dataColors }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  const series = [44, 55, 41, 17];
  var options = {
    chart: {
      height: 300,
      type: "donut",
    },
    labels: ["In Progress", "Not Started", "Not Started ", "Completed "],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={267.7}
    />
  );
};

const UpdateDonut = ({ dataColors }) => {
  var chartDonutupdatingColors = getChartColorsArray(dataColors);
  const series = [44, 55, 13, 33];
  var options = {
    chart: {
      height: 280,
      type: "donut",
    },

    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
    },
    colors: chartDonutupdatingColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={267.7}
    />
  );
};

export { SimplePie, SimpleDonut, UpdateDonut, UpdateExpenseDonut };
