import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Col, Button } from "reactstrap";
import Widgets from "../DashboardCrm/Widgets";

const index = () => {
  const [hovered, setHovered] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [showStopBtn, setShowStopBtn] = useState(false);
  const [time, setTime] = useState(0);
  const [date, setDate] = useState(new Date());
  const [userName, setUserName] = useState("Admin");
  const hours = Math.floor(time / 360000);
  const minutes = Math.floor((time % 360000) / 6000);
  const seconds = Math.floor((time % 6000) / 100);
  const milliseconds = time % 100;

  const handleCardHover = (index) => {
    setHovered(index);
  };

  const handleCardLeave = () => {
    setHovered(null);
  };
  const cardsData = [
    {
      iconClass: "ri-home-3-fill",
      text: "Home",
    },
    {
      iconClass: "ri-building-fill",
      text: "Office",
    },
    {
      iconClass: "ri-phone-fill",
      text: "Field",
    },
  ];
  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => setTime(time + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      // console.log(obj)
      setUserName(obj.user.username ? obj.user.username : "Admin");
    }
  }, [userName]);

  useEffect(() => {
    setInterval(() => setDate(new Date()), 1000);
  }, []);

  //Start and Stop time
  const startAndStop = () => {
    setIsRunning(!isRunning);

    setShowStopBtn(!isRunning);
  };

  // reset time
  const reset = () => {
    setTime(0);

    setIsRunning(false);
    setShowStopBtn(false);
  };

  // time 12 hour format
  const get12HourFormat = (hours) => {
    const isPM = hours >= 12;
    const formattedHours = ("0" + (hours % 12 || 12)).slice(-2);
    return `${formattedHours}:${("0" + date.getMinutes()).slice(-2)} ${
      isPM ? "PM" : "AM"
    }`;
  };

  // date format
  const getFormattedDate = () => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = days[date.getDay()];
    const dateNumber = date.getDate();
    const month = months[date.getMonth()];

    return `${day} ${dateNumber} ${month}`;
  };

  document.title = "Homepage | HREVIS";

  return (
    <React.Fragment>
      <div className="page-content homepage">
        <Row style={{ padding: "0px 21px" }}>
          <Widgets />
        </Row>
        <div className="d-inline-flex mb-2">
          <div className="ms-1">
            <i className="ri-calendar-2-line align-bottom me-1"></i>
            {getFormattedDate()}
          </div>
          <div className="ms-3">
            <i className="ri-time-line align-bottom me-1"></i>
            {get12HourFormat(date.getHours())}
          </div>
        </div>
        <div>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <Col className="text-start ">
                  <div className="ms-1">
                    <strong>{`Hello ${userName},`}</strong>
                    <p>{"You are currently punched out"}</p>
                  </div>
                </Col>
                <Col className="text-center ">
                  <div style={{ display: "inline-block", margin: "10px" }}>
                    <div className="circle">
                      <span className="time text-primary">
                        {" "}
                        {hours}:{minutes.toString().padStart(2, "0")}:
                        {seconds.toString().padStart(2, "0")}
                        {/* {milliseconds.toString().padStart(2, "0")} */}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <p className="text-muted mx-1">{`IP  ${"105.161.35.120"}`}</p>
              </Row>
              <p className="mx-1 my-0">Choose working place</p>
              <Row className="justify-content-center">
                <Col className="d-flex flex-row">
                  {cardsData.map((card, index) => (
                    <div
                      key={index}
                      className="card-wrapper"
                      onMouseEnter={() => handleCardHover(index)}
                      onMouseLeave={handleCardLeave}
                    >
                      <Card
                        className={`border m-1 ${
                          hovered === index ? "hovered" : ""
                        }`}
                      >
                        <CardBody className="d-flex flex-column align-items-center justify-content-center">
                          <i
                            className={`${card.iconClass} align-bottom ${
                              hovered === index ? "yellow-icon" : ""
                            }`}
                          ></i>
                          <span
                            className={`card-text ${
                              hovered === index ? "yellow-text" : ""
                            }`}
                          >
                            {card.text}
                          </span>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Col>

                <Col className="text-center align-self-end">
                  {showStopBtn ? (
                    <React.Fragment>
                      <Button
                        onClick={startAndStop}
                        className="btn btn-primary py-2 px-5 mt-3"
                        color="primary"
                      >
                        Punch Out
                      </Button>
                      <Button
                        onClick={() => {
                          setIsRunning(false);
                          setShowStopBtn(false);
                        }}
                        className="btn btn-secondary py-2 px-5 mt-3 ms-1"
                        color="secondary"
                      >
                        Stop Timer
                      </Button>
                      <Button
                        onClick={reset}
                        className="btn btn-danger py-2 px-5 mt-3 ms-1"
                        color="danger"
                      >
                        Reset Timer
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      onClick={startAndStop}
                      className="btn btn-primary py-2 px-5 mt-3"
                      color="primary"
                    >
                      Punch In
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default index;
