import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";
export const getAllPermissions = createAsyncThunk(
  "permission/getAllPermissions",
  async () => {
    try {
      return await services.getPermissions();
    } catch (error) {}
  }
);

export const createNewPermission = createAsyncThunk(
  "permission/createNewPermission",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createPermission(obj);
    } catch (error) {}
  }
);

export const deletePermission = createAsyncThunk(
  "permission/deletePermission",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.onDeletePermission(obj);
    } catch (error) {}
  }
);

export const onUpdatePermission = createAsyncThunk(
  "permission/onUpdatePermission",
  async (obj) => {
    try {
      return await services.updatePermission(obj);
    } catch (error) {}
  }
);
