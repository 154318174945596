import axios from "axios";
import { toast } from "react-toastify";

import {
  ALL_EMPLOYEE,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  TIME_SLOT,
  UPDATE_EMPLOYEE,
} from "../../helpers/url_helper";

import { APIClient } from "../../helpers/api_helper";
const api = new APIClient();

const getEmployees = async () => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${ALL_EMPLOYEE}`
    );

    if (res.success) {
    } else {
      toast.error(res.message, { theme: "colored" });
      toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(res.message, { theme: "colored" });
  }
};

const createEmployee = async (obj) => {
  try {
    const id = toast.loading("Creating employee...", { autoClose: false });

    const res = await api.create(
      `${process.env.REACT_APP_USER_URL}${CREATE_EMPLOYEE}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return res;
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const updateEmployee = async (obj) => {
  try {
    const id = toast.loading("Updating employee...", { autoClose: false });

    const res = await api.put(
      `${process.env.REACT_APP_USER_URL}${UPDATE_EMPLOYEE}${obj._id}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return { res, obj };
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const onDeleteEmployee = async (obj) => {
  try {
    const id = toast.loading("Deleting employee...", { autoClose: false });

    const res = await api.delete(
      `${process.env.REACT_APP_USER_URL}${DELETE_EMPLOYEE}${obj}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return { res, obj };
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const timeSlot = async () => {
  try {
    const res = await api.get(`${process.env.REACT_APP_USER_URL}${TIME_SLOT}`);

    if (res.success) {
      return res?.data;
    } else {
      toast.error(res, { theme: "colored" });
    }
  } catch (error) {
    toast.error(res.message, { theme: "colored" });
  }
};

export const services = {
  getEmployees,
  createEmployee,
  updateEmployee,
  onDeleteEmployee,
  timeSlot,
};
