import React, { useCallback, useMemo, useEffect } from "react";
import Loader from "../../Components/Common/Loader";
import TableContainer from "../../Components/Common/TableContainer";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Form,
  Row,
  Col,
  Input,
  Label,
  CardBody,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { routesOption } from "./DummyData";
function CustomTable({
  permissions,
  onClickDelete,
  modal,
  toggle,
  isEdit,
  validation,
  setModal,
  handlePermissionClick,
  deleteCheckbox,
  setSelectedRoute,
  selectedRoute,
  selectedCan,
  setSelectedCan,
  editPermissions,
  deletePermissions,
}) {
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".permissionCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="permissionCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },

      {
        Header: "Created",
        accessor: "createdAt",
        filterable: false,
      },
      {
        Header: "Can",
        accessor: "can",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item" title="View">
                <Link
                  to="#"
                  onClick={() => {
                    const roleData = cellProps.row.original;
                    //  setInfo(roleData);
                  }}
                >
                  <i className="ri-eye-fill align-bottom text-muted"></i>
                </Link>
              </li>
              {editPermissions && editPermissions?.length > 0 ? (
                <li className="list-inline-item">
                  <Link
                    to="#"
                    onClick={() => {
                      const PermissionData = cellProps.row.original;
                      handlePermissionClick(PermissionData);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {deletePermissions && deletePermissions?.length > 0 ? (
                <li className="list-inline-item">
                  <Link
                    to="#"
                    onClick={() => {
                      const PermissionData = cellProps.row.original;
                      onClickDelete(PermissionData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          );
        },
      },
    ],
    [handlePermissionClick, checkedAll, deletePermissions, editPermissions]
  );

  useEffect(() => {
    if (isEdit) {
      const selectedRouteValue = validation.values.route;
      const selectedCanValue = validation.values.can;
      const selectedRoute = routesOption.reduce((foundRoute, routeGroup) => {
        return (
          foundRoute ||
          routeGroup.options.find((route) => route.name === selectedRouteValue)
        );
      }, null);
      setSelectedRoute(selectedRoute);
      if (selectedRoute) {
        const canOption = selectedRoute.can.find(
          (option) => option.name === selectedCanValue
        );
        setSelectedCan(canOption);
      } else {
        setSelectedCan(null);
      }
    }
  }, [isEdit, validation.values.route, validation.values.can]);

  const canOptions = (selectedRouteName) => {
    if (!selectedRouteName) {
      return [];
    }
    const selectedRouteData = routesOption.reduce((foundRoute, routeGroup) => {
      return (
        foundRoute ||
        routeGroup.options.find((route) => route.name === selectedRouteName)
      );
    }, null);

    if (selectedRouteData) {
      return selectedRouteData.can;
    }
    return [];
  };

  const handleChangeRoute = (selectedOption) => {
    setSelectedRoute(selectedOption);
    setSelectedCan(null);
  };

  return (
    <React.Fragment>
      <Card id="contactList">
        <CardBody className="pt-0">
          <div>
            {permissions && permissions?.length ? (
              <TableContainer
                columns={columns}
                data={permissions || []}
                setInfo={() => {}}
                isGlobalFilter={true}
                isFooter={true}
                customPageSize={8}
                className="custom-header-css"
                divClass="table-responsive table-card mb-0"
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                isSearchInput={true}
                SearchPlaceholder="Search for permission"
              />
            ) : (
              <Loader />
            )}
          </div>
          <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={toggle}>
              {!!isEdit ? "Edit Permission" : "Add Permissions"}
            </ModalHeader>
            <Form
              className="tablelist-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <ModalBody>
                <Input type="hidden" id="id-field" />
                <Row className="g-3">
                  <Col lg={6}>
                    <div>
                      <Label htmlFor="name-field" className="form-label">
                        Route
                      </Label>
                      <Select
                        value={selectedRoute}
                        onChange={(selectedOption) => {
                          validation.setFieldValue(
                            "route",
                            selectedOption.name
                          );
                          handleChangeRoute(selectedOption);
                        }}
                        className="mb-0"
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.name}
                        options={routesOption}
                        id="route-field"
                        name="route"
                      />
                      {validation.touched.route && validation.errors.route ? (
                        <p style={{ color: "red" }}>
                          {validation.errors.route}
                        </p>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <Label htmlFor="can-field" className="form-label">
                      <span style={{ color: "red" }}>*</span>
                      Can
                    </Label>
                    <Select
                      value={selectedCan}
                      onChange={(selectedOption) => {
                        validation.setFieldValue("can", selectedOption.name);
                        setSelectedCan(selectedOption);
                      }}
                      className="mb-0"
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.name}
                      options={canOptions(selectedRoute?.name)}
                      id="can-field"
                      isDisabled={!selectedRoute}
                      name="can"
                    />
                    {validation.touched.can && validation.errors.can ? (
                      <p style={{ color: "red" }}>{validation.errors.can}</p>
                    ) : null}
                  </Col>
                </Row>

                <Row className="g-3 mt-2">
                  <Col>
                    <div>
                      <Label htmlFor="description-field" className="form-label">
                        <span style={{ color: "red" }}>*</span>
                        Description
                      </Label>
                      <Input
                        name="description"
                        id="description-field"
                        className="form-control"
                        placeholder="Description"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div
                  className="hstack gap-2 justify-content-end"
                  style={{ width: "100%" }}
                >
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="add-btn"
                  >
                    {!!isEdit ? "Update" : "Save"}{" "}
                  </button>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
export default CustomTable;
