import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const SelectDropDownMenu = ({ employees, onSelectionChange }) => {
  const [selectedCenter, setSelectedCenter] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const getEmployeesByCenter = (center) => {
    switch (center) {
      case "Center-1":
        return employees.filter((employee) => employee.center === "Center-1");
      case "Center-2":
        return employees.filter((employee) => employee.center === "Center-2");
      default:
        return [];
    }
  };
  const handleCenterChange = (e) => {
    const selectedCenterValue = e.target.value;
    setSelectedCenter(selectedCenterValue);
    setSelectedEmployee("");
    onSelectionChange({ center: selectedCenterValue, employee: "" });
  };
  const handleEmployeeChange = (e) => {
    const selectedEmployeeId = e.target.value;
    setSelectedEmployee(selectedEmployeeId);
    onSelectionChange({ center: selectedCenter, employee: selectedEmployeeId });
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="live-preview">
                <Row className="align-items-center g-3">
                  <Col lg={6}>
                    <select
                      className="form-select"
                      value={selectedCenter}
                      onChange={handleCenterChange}
                    >
                      <option value="">Select Center</option>
                      <option value="Center-1">Center One</option>
                      <option value="Center-2">Center Two</option>
                    </select>
                  </Col>
                  <Col lg={6}>
                    <select
                      className="form-select"
                      value={selectedEmployee}
                      onChange={handleEmployeeChange}
                      disabled={!selectedCenter}
                    >
                      <option value="">Select Employee</option>
                      {getEmployeesByCenter(selectedCenter).map((employee) => (
                        <option key={employee._id} value={employee._id}>
                          {employee.fullName}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SelectDropDownMenu;
