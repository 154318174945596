//LOCAL_BACKEND
export const LOCAL_BE = "http://localhost:8082";

//API ENDPOINTS
//LOGIN
export const USER_LOGIN = "/auth/login";
export const USER_LOGOUT = "logout";

//EMPLOYEE
export const ALL_EMPLOYEE = "/user/getAllEmployee";
export const CREATE_EMPLOYEE = "/user/newEmployee";
export const UPDATE_EMPLOYEE = "/user/update/";
export const DELETE_EMPLOYEE = "/user/deleteEmp/";

export const TIME_SLOT = "/slots/TimeSlot";

//attendence
export const GET_ALL_ATTENDENCE = "/user/AllEmployeesAttendanceHistory";
export const CREATE_ATTENDENCE = "/user/markAttendanceByAdmin";
export const DELETE_ATTENDENCE = "/user/deleteAttendanceByAdmin/";
export const UPDATE_ATTENDENCE = "/user/updateAttendanceByAdmin/";

// Roles
export const ALL_ROLE = "/roles";
//export const ALL_PARENTS = "/parents/get/UniqueParents";
//export const ALL_PARENTS = "/parents";
export const ALL_PARENTS = "/permissions";
export const CREATE_ROLE = "/roles";
export const DELETE_ROLE = "/roles/";
export const UPDATE_ROLE = "/roles/";

// Permissions
export const ALL_PERMISSIONS = "/permissions";
export const CREATE_PERMISSION = "/permissions";
export const DELETE_PERMISSION = "/permissions/";
export const UPDATE_PERMISSION = "/permissions/";

//designations
export const ALL_DESIGNATIONS = "/designation";
export const CREATE_DESIGNATION = "/designation";
export const UPDATE_DESIGNATION = "/designation/";
export const DELETE_DESIGNATION = "/designation/";

// timeslots
export const ALL_TIMESLOTS = "/slots/TimeSlot";
export const CREATE_TIMESLOT = "/slots/TimeSlot";
export const DELETE_TIMESLOT = "/slots/TimeSlot/";
export const UPDATE_TIMESLOT = "/slots/TimeSlot/";
