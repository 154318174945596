import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";

export const getAllEmployees = createAsyncThunk(
  "attendence/getAllEmployees",
  async () => {
    try {
      return await services.getEmployees();
    } catch (error) {}
  }
);
export const getAllAttendence = createAsyncThunk(
  "attendence/getAllAttendence",
  async (page) => {
    try {
      return await services.getAttendence(page);
    } catch (error) {
      console.log(error);
    }
  }
);
export const createAttendence = createAsyncThunk(
  "attendence/createAttendence",
  async (obj) => {
    try {
      return await services.createAttendence(obj);
    } catch (error) {}
  }
);
export const deleteAttendence = createAsyncThunk(
  "attendence/deleteAttendence",
  async (obj) => {
    try {
      return await services.ondeleteAttendence(obj);
    } catch (err) {
      // toast
    }
  }
);
export const updateAttendence = createAsyncThunk(
  "attendence/updateAttendence",
  async (obj) => {
    try {
      return await services.updateAttendence(obj);
    } catch (err) {}
  }
);
