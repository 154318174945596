import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";

export const getAllTimeSlots = createAsyncThunk(
  "timeslot/getAllTimeSlots",
  async () => {
    try {
      return await services.getTimeSlots();
    } catch (error) {}
  }
);

export const createNewTimeSlot = createAsyncThunk(
  "timeslot/createNewTimeSlot",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createTimeSlot(obj);
    } catch (error) {}
  }
);

export const deleteTimeSlot = createAsyncThunk(
  "timeslot/deleteTimeSlot",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.onDeleteTimeSlot(obj);
    } catch (error) {}
  }
);

export const onUpdateTimeSlot = createAsyncThunk(
  "timeslot/onUpdateTimeSlot",
  async (obj) => {
    try {
      return await services.updateTimeSlot(obj);
    } catch (error) {}
  }
);
