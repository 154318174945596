import React from "react";
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
// import { centerOption, gender } from "../../Employee/EmployeData";
const centerOption = [
  {
    _id: 1,
    label: "Center-1",
    value: "Center-1",
  },
  {
    _id: 2,
    label: "Center-2",
    value: "Center-2",
  },
];

const ProfileUpdateForm = ({ validation }) => {
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Full Name
            </Label>
            <Input
              name="fullName"
              id="customername-field"
              className="form-control"
              placeholder="Enter Name"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.fullName || ""}
              invalid={
                validation.touched.fullName && validation.errors.fullName
                  ? true
                  : false
              }
            />
            {validation.touched.fullName && validation.errors.fullName ? (
              <FormFeedback type="invalid">
                {validation.errors.fullName}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Email Address
            </Label>
            <Input
              name="email"
              id="customername-field"
              className="form-control"
              placeholder="Enter Email Address"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Mobile Number
            </Label>
            <Input
              name="mobile"
              id="customername-field"
              className="form-control"
              placeholder="Enter Mobile Number"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.mobile || ""}
              invalid={
                validation.touched.mobile && validation.errors.mobile
                  ? true
                  : false
              }
            />
            {validation.touched.mobile && validation.errors.mobile ? (
              <FormFeedback type="invalid">
                {validation.errors.mobile}
                {validation.errors.mobile}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              CNIC Number
            </Label>
            <Input
              name="CNIC"
              id="customername-field"
              className="form-control"
              placeholder="Enter CNIC Number"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.CNIC || ""}
              invalid={
                validation.touched.CNIC && validation.errors.CNIC ? true : false
              }
            />
            {validation.touched.CNIC && validation.errors.CNIC ? (
              <FormFeedback type="invalid">
                {validation.errors.CNIC}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Date Of Joining
            </Label>
            <Flatpickr
              disabled
              className="form-control"
              id="datepicker-publish-input"
              placeholder="Select Date"
              name="joining_date"
              onBlur={validation.handleBlur}
              value={validation.values.joining_date} // Bind the selected date to the formik values
              onChange={(date) =>
                validation.setFieldValue(
                  "joining_date",
                  date[0].toLocaleDateString("en-GB")
                )
              } // Update the formik value when the date is changed
              options={{
                altInput: true,
                altFormat: "d/m/y",
                dateFormat: "d/m/y",
                enableTime: false,
              }}
            />
            {validation.touched.joining_date &&
            validation.errors.joining_date ? (
              <FormFeedback type="invalid">
                {validation.errors.joining_date}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Date Of Birth
            </Label>
            <Flatpickr
              className="form-control"
              id="datepicker-publish-input"
              placeholder="Select Date"
              name="DOB"
              onBlur={validation.handleBlur}
              value={validation.values.DOB} // Bind the selected date to the formik values
              onChange={(date) =>
                validation.setFieldValue(
                  "DOB",
                  date[0].toLocaleDateString("en-GB")
                )
              } // Update the formik value when the date is changed
              options={{
                altInput: true,
                altFormat: "d/m/y",
                dateFormat: "d/m/y",
                enableTime: false,
              }}
            />
            {validation.touched.DOB && validation.errors.DOB ? (
              <FormFeedback type="invalid">
                {validation.errors.DOB}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Center
            </Label>
            <Select
              isDisabled
              // value={validation.values.center}
              value={centerOption.find(
                (option) => option.value === validation.values.center
              )}
              onChange={(e, selected) => {
                validation.setFieldValue("center", e.value);
              }}
              className="mb-0"
              options={centerOption}
              id="taginput-choices"
            ></Select>
            {validation.touched.center && validation.errors.center ? (
              <p style={{ color: "red" }}>{validation.errors.center}</p>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Time Slot
            </Label>
            <Input
              disabled
              name="time_slot"
              id="customername-field"
              className="form-control"
              placeholder="Enter Current Address"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.time_slot || ""}
              invalid={
                validation.touched.time_slot && validation.errors.time_slot
                  ? true
                  : false
              }
            />
            {validation.touched.time_slot && validation.errors.time_slot ? (
              <FormFeedback type="invalid">
                {validation.errors.time_slot}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Supervisor
            </Label>
            <Input
              disabled
              name="supervisor"
              id="customername-field"
              className="form-control"
              placeholder="Enter Current Address"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.supervisor || ""}
              invalid={
                validation.touched.supervisor && validation.errors.supervisor
                  ? true
                  : false
              }
            />
            {validation.touched.supervisor && validation.errors.supervisor ? (
              <FormFeedback type="invalid">
                {validation.errors.supervisor}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Designation
            </Label>
            <Input
              disabled
              name="designation"
              id="customername-field"
              className="form-control"
              placeholder="Enter Designation"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.designation || ""}
              invalid={
                validation.touched.designation && validation.errors.designation
                  ? true
                  : false
              }
            />
            {validation.touched.designation && validation.errors.designation ? (
              <FormFeedback type="invalid">
                {validation.errors.designation}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Role
            </Label>
            <Input
              disabled
              name="role"
              id="customername-field"
              className="form-control"
              placeholder="Enter Role"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.role || ""}
              invalid={
                validation.touched.role && validation.errors.role ? true : false
              }
            />
            {validation.touched.role && validation.errors.role ? (
              <FormFeedback type="invalid">
                {validation.errors.role}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              Gender
            </Label>
            <Select
              value={gender.find(
                (option) => option.value === validation.values.gender
              )}
              onChange={(e, selected) => {
                validation.setFieldValue("gender", e.value);
              }}
              className="mb-0"
              options={gender}
              id="taginput-choices"
            ></Select>
            {validation.touched.gender && validation.errors.gender ? (
              <p style={{ color: "red" }}>{validation.errors.gender}</p>
            ) : null}
          </div>
        </Col>

        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Current Address
            </Label>
            <Input
              name="current_address"
              id="customername-field"
              className="form-control"
              placeholder="Enter Current Address"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.current_address || ""}
              invalid={
                validation.touched.current_address &&
                validation.errors.current_address
                  ? true
                  : false
              }
            />
            {validation.touched.current_address &&
            validation.errors.current_address ? (
              <FormFeedback type="invalid">
                {validation.errors.current_address}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Permanent Address
            </Label>
            <Input
              name="permanent_address"
              id="customername-field"
              className="form-control"
              placeholder="Enter Current Address"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.permanent_address || ""}
              invalid={
                validation.touched.permanent_address &&
                validation.errors.permanent_address
                  ? true
                  : false
              }
            />
            {validation.touched.permanent_address &&
            validation.errors.permanent_address ? (
              <FormFeedback type="invalid">
                {validation.errors.permanent_address}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={12}>
          <div className="mb-3 pb-2">
            <Label htmlFor="exampleFormControlTextarea" className="form-label">
              Description
            </Label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea"
              rows="3"
              defaultValue="Hi I'm Anna Adame, It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is European languages are members of the same family."
            ></textarea>
          </div>
        </Col>
        <Col lg={12}>
          <div className="hstack gap-2 justify-content-end">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ProfileUpdateForm;
