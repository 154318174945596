import React, { useState } from "react";
import { Label, Input } from "reactstrap";

const ImageInput = ({ validation }) => {
  const [imagePreview, setImagePreview] = useState("");
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 70 * 1024) {
      const imageURL = URL.createObjectURL(file);
      setImagePreview(imageURL);
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64ImageString = event.target.result;
        validation.setFieldValue("photo", base64ImageString);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="text-center">
      <div className="position-relative d-inline-block">
        <div className="position-absolute bottom-0 end-0">
          <Label htmlFor="customer-image-input" className="mb-0">
            <div className="avatar-xs cursor-pointer">
              <div className="avatar-title bg-light border rounded-circle text-muted">
                <i className="ri-image-fill"></i>
              </div>
            </div>
          </Label>
          <Input
            className="form-control d-none"
            id="customer-image-input"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            // onChange={validation.handleChange}
            onChange={handleImageChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.photo && validation.errors.photo}
          />
        </div>
        <div className="avatar-lg p-1">
          <div className="avatar-title bg-light rounded-circle">
            <img
              src={validation.values.photo || imagePreview}
              alt="dummyImg"
              id="customer-img"
              className="avatar-md rounded-circle object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageInput;
