import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { onUpdateEmployee } from "../../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const UploadDocument = () => {
  const dispatch = useDispatch();
  const [cnicFiles, setCnicFiles] = useState("");
  const [medicalFiles, setMedicalFiles] = useState("");
  const [educationFiles, setEducationFiles] = useState("");
  const [experienceFiles, setExperienceFiles] = useState("");
  const [user, setUser] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      setUser(user);
    }
  }, []);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCnicFileChange = async (fileItems) => {
    const selectedFiles = fileItems.map((fileItem) => fileItem.file);
    const base64Files = await Promise.all(
      selectedFiles.map((file) => fileToBase64(file))
    );

    setCnicFiles(base64Files);
  };

  const handleMedicalFileChange = async (fileItems) => {
    const selectedFiles = fileItems.map((fileItem) => fileItem.file);
    const base64Files = await Promise.all(
      selectedFiles.map((file) => fileToBase64(file))
    );
    setMedicalFiles(base64Files);
  };

  const handleEducationFileChange = async (fileItems) => {
    const selectedFiles = fileItems.map((fileItem) => fileItem.file);
    const base64Files = await Promise.all(
      selectedFiles.map((file) => fileToBase64(file))
    );

    setEducationFiles(base64Files);
  };

  const handleExperienceFileChange = async (fileItems) => {
    const selectedFiles = fileItems.map((fileItem) => fileItem.file);
    const base64Files = await Promise.all(
      selectedFiles.map((file) => fileToBase64(file))
    );
    setExperienceFiles(base64Files);
  };

  const handleUploadButtonClick = () => {
    const uploadDoc = {
      _id: user?.user?._id,
    };
    if (cnicFiles[0]) {
      uploadDoc.cnicPic = cnicFiles[0];
    }
    if (educationFiles[0]) {
      uploadDoc.educationDoc = educationFiles[0];
    }
    if (experienceFiles[0]) {
      uploadDoc.experienceLetter = experienceFiles[0];
    }
    if (medicalFiles[0]) {
      uploadDoc.medicalDoc = medicalFiles[0];
    }
    dispatch(onUpdateEmployee(uploadDoc));
    const storedAuthUserString = sessionStorage.getItem("authUser");
    const storedAuthUser = JSON.parse(storedAuthUserString);
    if (cnicFiles[0]) {
      storedAuthUser.user.cnicPic = cnicFiles[0];
    }
    if (educationFiles[0]) {
      storedAuthUser.user.educationDoc = educationFiles[0];
    }
    if (experienceFiles[0]) {
      storedAuthUser.user.experienceLetter = experienceFiles[0];
    }
    if (medicalFiles[0]) {
      storedAuthUser.user.medicalDoc = medicalFiles[0];
    }
    sessionStorage.setItem("authUser", JSON.stringify(storedAuthUser));
    setCnicFiles("");
    setEducationFiles("");
    setExperienceFiles("");
    setMedicalFiles("");
  };

  return (
    <>
      <ToastContainer closeButton={false} />
      <div className="mb-4 pb-2">
        <h5 className="card-title text-decoration-underline mb-3">
          Upload Documents:
        </h5>
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">CNIC Document</h4>
              </CardHeader>
              <CardBody>
                <FilePond
                  files={cnicFiles}
                  onupdatefiles={handleCnicFileChange}
                  allowMultiple={true}
                  maxFiles={1}
                  name="files"
                  className="filepond filepond-input-multiple"
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Medical Document</h4>
              </CardHeader>
              <CardBody>
                <FilePond
                  files={medicalFiles}
                  onupdatefiles={handleMedicalFileChange}
                  allowMultiple={true}
                  maxFiles={1}
                  name="files"
                  className="filepond filepond-input-multiple"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Experience Document</h4>
              </CardHeader>
              <CardBody>
                <FilePond
                  files={experienceFiles}
                  onupdatefiles={handleExperienceFileChange}
                  allowMultiple={true}
                  maxFiles={1}
                  name="files"
                  className="filepond filepond-input-multiple"
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Education Document</h4>
              </CardHeader>
              <CardBody>
                <FilePond
                  files={educationFiles}
                  onupdatefiles={handleEducationFileChange}
                  allowMultiple={true}
                  maxFiles={1}
                  name="files"
                  className="filepond filepond-input-multiple"
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUploadButtonClick}
                disabled={
                  !cnicFiles.length &&
                  !medicalFiles.length &&
                  !experienceFiles.length &&
                  !educationFiles.length
                }
              >
                Update
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UploadDocument;
