import React, { useCallback, useMemo, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Form,
  Row,
  Col,
  Input,
  Label,
  CardBody,
  FormFeedback,
  ModalFooter,
} from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import TableContainer from "../../../Components/Common/TableContainer";
const ShiftsTable = ({
  timeslots,
  onClickDelete,
  modal,
  toggle,
  isEdit,
  validation,
  setModal,
  handleTimeslotClick,
  deleteCheckbox,
}) => {
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".shiftCheckBox");
    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),

        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="shiftCheckBox form-check-input"
              value={cellProps.row.original}
              onChange={() => deleteCheckbox()}
            />
          );
        },

        id: "#",
      },
      {
        Header: "Shift",
        accessor: "slot_Name",
        filterable: false,
      },
      {
        Header: "Check-In Time",
        accessor: "checkInTime",
        filterable: false,
        Cell: (cellProps) => (
          <div className="align-items-center">
            <div className="flex-shrink-0"></div>
            <div>
              <p>{cellProps.row.original.checkInTime}</p>
            </div>
          </div>
        ),
      },
      {
        Header: "Check-Out Time",
        accessor: "checkOutTime",
        filterable: false,
        Cell: (cellProps) => (
          <div className="align-items-center">
            <div className="flex-shrink-0"></div>
            <div>
              <p>{cellProps.row.original.checkOutTime}</p>
            </div>
          </div>
        ),
      },
      {
        Header: "Check-In Relaxation Upto",
        accessor: "checkIn_buffer",
        filterable: false,
        Cell: (cellProps) => (
          <div className="align-items-center">
            <div className="flex-shrink-0"></div>
            <div>
              <p>{cellProps.row.original.checkIn_buffer}</p>
            </div>
          </div>
        ),
      },

      {
        Header: "Action",
        filterable: false,
        Cell: (cellProps) => {
          const { _id } = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <span
                  className="edit-item-btn"
                  onClick={() => {
                    const TimeslotData = cellProps.row.original;
                    handleTimeslotClick(TimeslotData);
                  }}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                </span>
              </li>
              <li className="list-inline-item">
                <span
                  className="remove-item-btn"
                  onClick={() => {
                    const TimeslotData = cellProps.row.original;
                    onClickDelete(TimeslotData);
                  }}
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                </span>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleTimeslotClick, checkedAll]
  );

  return (
    <React.Fragment>
      <Card>
        <CardBody className="pt-0">
          <div>
            {timeslots && timeslots?.length >= 0 ? (
              <TableContainer
                columns={columns}
                data={timeslots || []}
                setInfo={() => {}}
                isFooter={true}
                customPageSize={8}
                className="custom-header-css"
                divClass="table-responsive table-card mb-0"
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                isSearchInput={true}
                SearchPlaceholder="Search for timeslot"
              />
            ) : (
              <Loader />
            )}
          </div>
          <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={toggle}>
              {!!isEdit ? "Edit Shift" : "Add Shift"}
            </ModalHeader>
            <Form
              className="tablelist-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <ModalBody>
                <Input type="hidden" id="id-field" />
                <Row className="g-3">
                  <Col lg={6}>
                    <div>
                      <Label
                        htmlFor="selectedShift"
                        className="form-label text-muted fs-5"
                      >
                        Shifts*
                      </Label>
                      <select
                        className="form-select form-select-lg"
                        name="selectedShift"
                        aria-label=".form-select-lg example"
                        value={validation.values.selectedShift || ""}
                        id="selectedShift"
                        onChange={(e) => {
                          validation.setFieldValue(
                            "selectedShift",
                            e.target.value
                          );
                        }}
                      >
                        <option value="">Select a Shift</option>
                        <option value="Morning">Morning</option>
                        <option value="Evening">Evening</option>
                        <option value="Night">Night</option>
                      </select>
                      {validation.touched.selectedShift &&
                      validation.errors.selectedShift ? (
                        <div>
                          <p className="text-danger">
                            {validation.errors.selectedShift}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <Label
                      htmlFor="checkIn"
                      className="form-label text-muted fs-5"
                    >
                      Check-In Time
                    </Label>
                    <Flatpickr
                      className="form-control"
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        defaultDate: "13:45",
                      }}
                      value={validation.values.checkIn_time}
                      onChange={(selectedDates, dateStr) => {
                        validation.setFieldValue("checkIn_time", dateStr);
                      }}
                    />
                    {validation.touched.checkIn_time &&
                      validation.errors.checkIn_time && (
                        <div className="text-danger">
                          {validation.errors.checkIn_time}
                        </div>
                      )}
                  </Col>
                </Row>
                <Row className="g-3 mt-2">
                  <Col lg={6}>
                    <Label
                      htmlFor="checkoutTime"
                      className="form-label text-muted fs-5"
                    >
                      Check-Out Time
                    </Label>
                    <Input
                      name="checkoutTime"
                      id="checkoutTime"
                      className="form-control"
                      type="time"
                      onChange={(e) => {
                        validation.setFieldValue(
                          "checkOut_time",
                          e.target.value
                        );
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.checkOut_time || ""}
                    />
                  </Col>
                  <Col lg={6}>
                    <Label
                      htmlFor="checkIn_buffer"
                      className="form-label text-muted fs-5"
                    >
                      Check-In Buffer
                    </Label>
                    <Input
                      name="checkIn_buffer"
                      id="checkIn_buffer"
                      className="form-control"
                      type="time"
                      onChange={(e) => {
                        validation.setFieldValue(
                          "checkIn_buffer",
                          e.target.value
                        );
                      }}
                      value={validation.values.checkIn_buffer || ""}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div
                  className="hstack gap-2 justify-content-end"
                  style={{ width: "100%" }}
                >
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="add-btn"
                  >
                    {!!isEdit ? "Update" : "Save"}{" "}
                  </button>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ShiftsTable;
