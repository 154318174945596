import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";

const UpdateModal = ({ isOpen, toggle, formikProps }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <div>Update Time</div>
        <Button
          type="button"
          onClick={() => {
            toggle();
            formikProps.resetForm();
          }}
          className="btn-close"
          aria-label="Close"
        ></Button>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={formikProps.handleSubmit}>
          <div className="row g-3">
            <Col xxl={6}>
              <div>
                <label htmlFor="date" className="form-label">
                  Select Date
                </label>
                <Flatpickr
                  className="form-control"
                  id="datepicker-publish-input"
                  placeholder="Select Date"
                  name="date"
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.date}
                  onChange={(date, dateStr) => {
                    formikProps.setFieldValue("date", dateStr);
                  }}
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    mode: "single",
                    dateFormat: "d/m/y",
                    enableTime: false,
                  }}
                />

                {formikProps.touched.date && formikProps.errors.date && (
                  <div className="text-danger">{formikProps.errors.date}</div>
                )}
              </div>
            </Col>
            <Col xxl={6}>
              <div>
                <label htmlFor="clockIn" className="form-label">
                  Clock In
                </label>
                {/* <Input
                  type="time"
                  className="form-control"
                  id="clockIn"
                  {...formikProps.getFieldProps("clockIn")}
                /> */}
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    defaultDate: "13:45",
                  }}
                  value={formikProps.values.clockIn}
                  onChange={(selectedDates, dateStr) => {
                    formikProps.setFieldValue("clockIn", dateStr);
                  }}
                />

                {formikProps.touched.clockIn && formikProps.errors.clockIn && (
                  <div className="text-danger">
                    {formikProps.errors.clockIn}
                  </div>
                )}
              </div>
            </Col>
            <Col xxl={6}>
              <div>
                <label htmlFor="clockOut" className="form-label">
                  Clock Out
                </label>
                {/* <Input
                  type="time"
                  className="form-control"
                  id="clockOut"
                  {...formikProps.getFieldProps("clockOut")}
                /> */}
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    defaultDate: "13:45",
                  }}
                  value={formikProps.values.clockOut}
                  onChange={(selectedDates, dateStr) => {
                    formikProps.setFieldValue("clockOut", dateStr);
                  }}
                />
                {formikProps.touched.clockOut &&
                  formikProps.errors.clockOut && (
                    <div className="text-danger">
                      {formikProps.errors.clockOut}
                    </div>
                  )}
              </div>
            </Col>
            <div className="col-lg-12">
              <div className="hstack gap-2 justify-content-end">
                <Button color="light" onClick={toggle}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UpdateModal;
