import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import CustomTable from "./CustomTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPermissions,
  createNewPermission,
  deletePermission,
  onUpdatePermission,
} from "../../slices/thunks";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Card,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import usePermissionHandler from "../../helpers/usePermissionHandler";
function Permission() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [permission, setPermission] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedCan, setSelectedCan] = useState(null);
  const { permissions } = useSelector((state) => ({
    permissions: state.Permission.permissions,
  }));

  const { editPermissions, createPermissions, deletePermissions } =
    usePermissionHandler(currentRoute);

  useEffect(() => {
    if (permissions && !permissions.length) {
      dispatch(getAllPermissions());
    }
  }, [dispatch, permissions]);

  useEffect(() => {
    setPermission(permissions);
  }, [permissions]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setPermission(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      route: (permission && permission.route) || "",
      description: (permission && permission.description) || "",
      can: (permission && permission.can) || "",
    },
    validationSchema: Yup.object({
      route: Yup.string().required("Please Enter Route"),
      description: Yup.string().required("Please Enter Description"),
      can: Yup.string().required("Please Enter can"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatePermission = {
          _id: permission ? permission._id : 0,
          route: values.route,
          description: values.description,
          can: values.can,
        };

        dispatch(onUpdatePermission(updatePermission));
        setSelectedRoute(null);
        validation.resetForm();
      } else {
        const newPemission = {
          route: values["route"],
          description: values["description"],
          can: values["can"],
        };
        dispatch(createNewPermission(newPemission));
        setSelectedRoute(null);
        validation.resetForm();
      }
      toggle();
    },
  });

  const handlePermissionClick = useCallback(
    (arg) => {
      const permission = arg;
      setPermission({
        _id: permission._id,
        route: permission.route,
        can: permission.can,
        description: permission.description,
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );
  const onClickDelete = (permission) => {
    setPermission(permission);
    setDeleteModal(true);
  };

  const handleDeletePermission = () => {
    if (permission) {
      dispatch(deletePermission(permission._id));
      setDeleteModal(false);
    }
  };

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(deletePermission(element.value));
    });

    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".permissionCheckBox:checked");
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  document.title = "Permissions | HREVIS";

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer closeButton={false} />

        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeletePermission}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Container fluid>
          <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "0 auto" }} />
          </Row>

          <Card
            style={{
              margin: "auto",
              padding: "14px 30px",
              marginTop: "-15px",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ padding: "0px" }}
            >
              <div>
                {createPermissions && createPermissions.length > 0 ? (
                  <Button
                    color="primary"
                    className="mx-2"
                    onClick={() => {
                      setIsEdit(false);
                      setModal(true);
                    }}
                  >
                    <i
                      className="ri-add-fill"
                      style={{ verticalAlign: "middle" }}
                    />{" "}
                    Add Permissions
                  </Button>
                ) : (
                  ""
                )}

                {isMultiDeleteButton && (
                  <Button
                    onClick={() => {
                      setDeleteModalMulti(true);
                    }}
                  >
                    <i className="ri-delete-bin-2-line"></i>
                  </Button>
                )}
              </div>

              <UncontrolledDropdown direction="start">
                <DropdownToggle color="primary">
                  <i className="ri-more-2-fill"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="dropdown-item">
                    <i className="ri-file-pdf-fill align-bottom me-2 text-muted" />{" "}
                    PDF
                  </DropdownItem>
                  <DropdownItem className="dropdown-item">
                    <i className="ri-file-text-fill align-bottom me-2 text-muted" />{" "}
                    File
                  </DropdownItem>
                  <DropdownItem className="dropdown-item">
                    <i className="ri-printer-fill align-bottom me-2 text-muted" />{" "}
                    Printer
                  </DropdownItem>
                  <DropdownItem className="dropdown-item">
                    <i className="ri-eye-fill align-bottom me-2 text-muted" />{" "}
                    Eye
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Card>

          <CustomTable
            permissions={permissions}
            onClickDelete={onClickDelete}
            modal={modal}
            toggle={toggle}
            isEdit={isEdit}
            validation={validation}
            setModal={setModal}
            handlePermissionClick={handlePermissionClick}
            deleteCheckbox={deleteCheckbox}
            setSelectedRoute={setSelectedRoute}
            selectedRoute={selectedRoute}
            selectedCan={selectedCan}
            setSelectedCan={setSelectedCan}
            editPermissions={editPermissions}
            deletePermissions={deletePermissions}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Permission;
