export const tabs = [
  {
    tab: 1,
    label: "Pos-Admins",
  },
  {
    tab: 2,
    label: "Bap",
  },
  {
    tab: 3,
    label: "System configuration",
  },
  {
    tab: 4,
    label: "Brand-Portal",
  },
  {
    tab: 5,
    label: "Account-Portal",
  },
  {
    tab: 6,
    label: "All Faqs",
  },
  {
    tab: 7,
    label: "Business Cstegories",
  },
  {
    tab: 8,
    label: "requested Credit limit",
  },
  {
    tab: 9,
    label: "Portal OPne",
  },
  {
    tab: 10,
    label: "Portal Two",
  },
];
export const data = [
  {
    id: 1,
    createdAt: "2024-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user 1",
  },
  {
    id: 2,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user 2",
  },
  {
    id: 3,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user 3",
  },
  {
    id: 4,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user",
  },
  {
    id: 5,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user",
  },
  {
    id: 6,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user",
  },
  {
    id: 7,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user",
  },
  {
    id: 8,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user",
  },
  {
    id: 9,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user",
  },
  {
    id: 10,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user",
  },
  {
    id: 11,
    createdAt: "2023-03-11",
    type: "CSR_ADMIN",
    description: "Role for super user",
  },
];
export const permissions = [
  {
    id: "1",
    label: "bap.statements.pay-invoice",
  },
  {
    id: "2",
    label: "bap2.statements.pay-invoice",
  },
  {
    id: "3",
    label: "bap3.statements.pay-invoice",
  },
  {
    id: "4",
    label: "bap4.statements.pay-invoice",
  },
  {
    id: "5",
    label: "bap5.statements.pay-invoice",
  },
  {
    id: "6",
    label: "bap6.statements.pay-invoice",
  },
  {
    id: "7",
    label: "bap7.statements.pay-invoice",
  },
  {
    id: "8",
    label: "bap8.statements.pay-invoice",
  },
  {
    id: "9",
    label: "bap9.statements.pay-invoice",
  },
  {
    id: "10",
    label: "bap10.statements.pay-invoice",
  },
  {
    id: "11",
    label: "bap5.statements.pay-invoice",
  },
  {
    id: "12",
    label: "bap6.statements.pay-invoice",
  },
  {
    id: "13",
    label: "bap7.statements.pay-invoice",
  },
  {
    id: "14",
    label: "bap8.statements.pay-invoice",
  },
  {
    id: "15",
    label: "bap9.statements.pay-invoice",
  },
  {
    id: "16",
    label: "bap10.statements.pay-invoice",
  },
];
