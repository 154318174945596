// dummyData.js
const routesOption = [
  {
    label: "HomePage",
    options: [
      {
        name: "/homepage",
        label: "Home page",
        can: [
          {
            name: "homepage.view",
            label: "View Homepage",
          },
        ],
      },
    ],
  },

  {
    label: "Roles & Permissions",
    options: [
      {
        name: "/permissions",
        label: "Permission page",
        can: [
          {
            name: "roles.view.permission",
            label: "View Permissions",
          },
          {
            name: "roles.create.permission",
            label: "Create Permission",
          },
          {
            name: "roles.edit.permission",
            label: "Edit Permission",
          },
          {
            name: "roles.delete.permission",
            label: "Delete Permission",
          },
        ],
      },
      {
        name: "/roles",
        label: "Roles page",
        can: [
          {
            name: "roles.view.role",
            label: "View Roles",
          },
          {
            name: "roles.create.role",
            label: "Create Role",
          },
          {
            name: "roles.edit.role",
            label: "Edit Role",
          },
          {
            name: "roles.delete.role",
            label: "Delete Role",
          },
        ],
      },
    ],
  },
  {
    label: "Employees",
    options: [
      {
        name: "/employee",
        label: "Employee page",
        can: [
          {
            name: "employees.view.allemployee",
            label: "View All Employees",
          },
          {
            name: "employees.create.employee",
            label: "Create Employee",
          },
          {
            name: "employees.edit.employee",
            label: "Edit Employee",
          },
          {
            name: "employees.delete.employee",
            label: "Delete Employee",
          },
        ],
      },
      {
        name: "/assignrole",
        label: "Employee Assign Role page",
        can: [
          {
            name: "employees.view.assignrole",
            label: "View Assign Role",
          },
        ],
      },

      {
        name: "/importemployees",
        label: "Employees Import page",
        can: [
          {
            name: "employees.view.importemployee",
            label: "View Import Employee",
          },
        ],
      },
    ],
  },

  {
    label: "Timesheets",
    options: [
      {
        name: "/attendance",
        label: "Attendance page",
        can: [
          {
            name: "timesheets.view.allattendances",
            label: "View All Attendances",
          },
        ],
      },

      {
        name: "/alltimesheets",
        label: "Timesheet page",
        can: [
          {
            name: "timesheets.view.alltimesheets",
            label: "View All Timesheets",
          },
        ],
      },

      {
        name: "/holidaysheet",
        label: "Holiday page",
        can: [
          {
            name: "timesheets.view.holidaysheet",
            label: "View All Holidaysheet",
          },
        ],
      },

      {
        name: "/view-attendance",
        label: "View Attendacne page",
        can: [
          {
            name: "timesheets.view.view-attendance",
            label: "View Attendance",
          },
        ],
      },
    ],
  },

  {
    label: "Core HR",
    options: [
      {
        name: "/promotion",
        label: "Promotion page",
        can: [
          {
            name: "HR.view.promotions",
            label: "View Promotions",
          },
        ],
      },
    ],
  },

  {
    label: "Designation",
    options: [
      {
        name: "/designation",
        label: "Designation page",
        can: [
          {
            name: "designation.view.alldesignation",
            label: "View Designations",
          },
        ],
      },
    ],
  },
];

module.exports = {
  routesOption,
};
