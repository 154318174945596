import { useEffect, useState } from "react";
import { getAttendenceRecord } from "../slices/dashboardCRM/action";

const useFetch = (value) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        let response;
        // Assuming you have appropriate functions to make API calls instead of using dispatch
        switch (value) {
          case "attendence":
            // Make an API call to get attendenceData
            response = await getAttendenceRecord();
            break;

          case "leaveSummary":
            // Make an API call to get leave summary data
            response = await fetchLeaveSummaryData();
            break;

          case "workAnniversary":
            // Make an API call to get work anniversary data
            response = await fetchWorkAnniversaryData();
            break;

          case "birthday":
            // Make an API call to get birthday data
            response = await fetchBirthdayData();
            break;

          case "allEmployees":
            // Make an API call to get all employees data
            response = await fetchAllEmployeesData();
            break;

          default:
            throw new Error("Invalid value.");
        }

        // Assuming the API responses are in JSON format and have a data key
        setData(response);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [value]);

  return { data, loading, error };
};

export default useFetch;
