import React, { useEffect, useState, useCallback } from "react";

import DesignationTable from "./DesignationTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDesignations,
  createNewDesignation,
  deleteDesignation,
  onUpdateDesignation,
} from "../../slices/thunks";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Card, Button } from "reactstrap";
function Designation() {
  const dispatch = useDispatch();
  const [designation, setDesignation] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { designations } = useSelector((state) => ({
    designations: state.Designation.designations,
  }));

  useEffect(() => {
    if (designations && !designations.length) {
      dispatch(getAllDesignations());
    }
  }, [dispatch, designations]);

  useEffect(() => {
    setDesignation(designations);
  }, [designations]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setDesignation(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: (designation && designation.title) || "",
      description: (designation && designation.description) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateDesignation = {
          _id: designation ? designation._id : 0,
          title: values.title,
          description: values.description,
        };
        dispatch(onUpdateDesignation(updateDesignation));
        validation.resetForm();
      } else {
        const newDesignation = {
          title: values["title"],
          description: values["description"],
        };

        dispatch(createNewDesignation(newDesignation));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleDesignationClick = useCallback(
    (arg) => {
      const designation = arg;
      setDesignation({
        _id: designation._id,
        title: designation.title,
        description: designation.description,
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );
  const onClickDelete = (designation) => {
    setDesignation(designation);
    setDeleteModal(true);
  };

  const handleDeleteDesignation = () => {
    if (designation) {
      dispatch(deleteDesignation(designation._id));
      setDeleteModal(false);
    }
  };

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(deletePermission(element.value));
    });

    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".designationCheckBox:checked");
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  document.title = "Designation | HREVIS";
  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer closeButton={false} />

        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteDesignation}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />

        <Container fluid>
          {/* <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "0 auto" }} />
          </Row> */}

          <Card
            style={{
              margin: "auto",
              padding: "14px 30px",
              marginTop: "-15px",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ padding: "0px" }}
            >
              <div>
                <Button
                  color="primary"
                  className="mx-2"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  <i
                    className="ri-add-fill"
                    style={{ verticalAlign: "middle" }}
                  />{" "}
                  Add Designation
                </Button>

                {isMultiDeleteButton && (
                  <Button
                    onClick={() => {
                      setDeleteModalMulti(true);
                    }}
                  >
                    <i className="ri-delete-bin-2-line"></i>
                  </Button>
                )}
              </div>
            </div>
          </Card>

          <DesignationTable
            designations={designations}
            onClickDelete={onClickDelete}
            modal={modal}
            toggle={toggle}
            isEdit={isEdit}
            validation={validation}
            setModal={setModal}
            handleDesignationClick={handleDesignationClick}
            deleteCheckbox={deleteCheckbox}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Designation;
