import React from "react";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";

const UpdateAttendance = ({
  getAttendenceClick,
  isMultiDeleteButton,
  setDeleteModalMulti,
}) => {
  const OnchangeHandler = (e, name) => {
    if (name === "companyName") {
      setState({ ...state, companyName: e.target.value });
    } else if (name === "requirement") {
      setState({ ...state, requirement: e.target.value });
    } else if (name === "status") {
      setState({ ...state, status: e.target.value });
    }
    // else if (name == "date") {
    //   setState({ ...state, date: e.target.value })
    // }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="bg-soft-light">
              <h4 className="mb-4">Update Attendance</h4>
              <Form>
                <Row className="row g-3">
                  <Col xxl={5} sm={4}>
                    <Flatpickr
                      className="form-control"
                      id="datepicker-publish-input"
                      placeholder="Start Date"
                      name="date"
                      onChange={(e) => OnchangeHandler(e, "date")}
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        mode: "multiple",
                        dateFormat: "d.m.y",
                      }}
                    />
                  </Col>
                  <Col xxl={3} sm={4}>
                    <Flatpickr
                      className="form-control "
                      id="datepicker-publish-input"
                      placeholder="End Date"
                      name="date"
                      onChange={(e) => OnchangeHandler(e, "date")}
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        mode: "multiple",
                        dateFormat: "d.m.y",
                      }}
                    />
                  </Col>
                </Row>
              </Form>
              <div className="mt-4">
                <Button
                  className="px-4"
                  style={{ marginRight: "1rem" }}
                  onClick={getAttendenceClick}
                >
                  Get
                </Button>
                {isMultiDeleteButton && (
                  <Button
                    onClick={() => {
                      setDeleteModalMulti(true);
                    }}
                  >
                    <i className="ri-delete-bin-2-line"></i>
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpdateAttendance;
