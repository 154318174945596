import { toast } from "react-toastify";

import {
  ALL_ROLE,
  ALL_PARENTS,
  CREATE_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE,
} from "../../helpers/url_helper";

import { APIClient } from "../../helpers/api_helper";
const api = new APIClient();

const getRoles = async () => {
  try {
    const res = await api.get(`${process.env.REACT_APP_USER_URL}${ALL_ROLE}`);

    if (res.success) {
    } else {
      toast.error(res.message, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(res.message, { theme: "colored" });
  }
};

const getParents = async () => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${ALL_PARENTS}`
    );

    if (res.success) {
    } else {
      toast.error(res.message, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(res.message, { theme: "colored" });
  }
};

const createRole = async (obj) => {
  try {
    const id = toast.loading("Creating Role...", { autoClose: false });

    const res = await api.create(
      `${process.env.REACT_APP_USER_URL}${CREATE_ROLE}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return res;
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const onDeleteRole = async (obj) => {
  try {
    const id = toast.loading("Deleting role...", { autoClose: false });

    const res = await api.delete(
      `${process.env.REACT_APP_USER_URL}${DELETE_ROLE}${obj}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return { res, obj };
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const updateRole = async (obj) => {
  try {
    const id = toast.loading("Updating Role...", { autoClose: false });

    const res = await api.update(
      `${process.env.REACT_APP_USER_URL}${UPDATE_ROLE}${obj._id}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return { res, obj };
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

export const services = {
  getRoles,
  getParents,
  createRole,
  onDeleteRole,
  updateRole,
};
