import React from "react";
import Select from "react-select";
import { ModalBody, Row, Col, Label, Input } from "reactstrap";
import Flatpickr from "react-flatpickr";
import ImageInput from "./ImageInput";
const EmployeeModalBody = ({
  validation,
  centerOption,
  designations,
  options,
  employeeOption,
  roles,
}) => {
  return (
    <ModalBody>
      <Input type="hidden" id="id-field" />
      <Row className="g-3 mb-2">
        <Col lg={12}>
          <ImageInput validation={validation} />
        </Col>
      </Row>
      <Row className="g-3">
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Full Name
            </Label>
            <Input
              name="fullName"
              id="customername-field"
              className="form-control"
              placeholder="Enter Name"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.fullName || ""}
              invalid={
                validation.touched.fullName && validation.errors.fullName
                  ? true
                  : false
              }
            />
            {validation.touched.fullName && validation.errors.fullName ? (
              <FormFeedback type="invalid">
                {validation.errors.fullName}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Email Address
            </Label>
            <Input
              name="email"
              id="customername-field"
              className="form-control"
              placeholder="Enter Email Address"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="g-3 mt-1">
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Date Of Joining
            </Label>
            <Flatpickr
              className="form-control"
              id="datepicker-publish-input"
              placeholder="Select Date"
              name="joining_date"
              onBlur={validation.handleBlur}
              value={validation.values.joining_date} // Bind the selected date to the formik values
              onChange={(date) =>
                validation.setFieldValue(
                  "joining_date",
                  date[0].toLocaleDateString("en-GB")
                )
              } // Update the formik value when the date is changed
              options={{
                altInput: true,
                altFormat: "d/m/y",
                dateFormat: "d/m/y",
                enableTime: false,
              }}
            />
            {validation.touched.joining_date &&
            validation.errors.joining_date ? (
              <FormFeedback type="invalid">
                {validation.errors.joining_date}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Date Of Birth
            </Label>
            <Flatpickr
              className="form-control"
              id="datepicker-publish-input"
              placeholder="Select Date"
              name="DOB"
              onBlur={validation.handleBlur}
              value={validation.values.DOB} // Bind the selected date to the formik values
              onChange={(date) =>
                validation.setFieldValue(
                  "DOB",
                  date[0].toLocaleDateString("en-GB")
                )
              } // Update the formik value when the date is changed
              options={{
                altInput: true,
                altFormat: "d/m/y",
                dateFormat: "d/m/y",
                enableTime: false,
              }}
            />
            {validation.touched.DOB && validation.errors.DOB ? (
              <FormFeedback type="invalid">
                {validation.errors.DOB}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="g-3 mt-1">
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Center
            </Label>
            <Select
              // value={validation.values.center}
              value={centerOption.find(
                (option) => option.value === validation.values.center
              )}
              onChange={(e, selected) => {
                validation.setFieldValue("center", e.value);
              }}
              className="mb-0"
              options={centerOption}
              id="taginput-choices"
            ></Select>
            {validation.touched.center && validation.errors.center ? (
              <p style={{ color: "red" }}>{validation.errors.center}</p>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Designation
            </Label>
            <Select
              value={designations.find(
                (option) => option._id === validation.values.designation
              )}
              onChange={(selectedOption) => {
                validation.setFieldValue("designation", selectedOption?._id);
              }}
              className="mb-0"
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option._id}
              options={designations}
              id="taginput-choices"
            ></Select>
            {validation.touched.designation && validation.errors.designation ? (
              <p style={{ color: "red" }}>{validation.errors.designation}</p>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="g-3 mt-1">
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Current Address
            </Label>
            <Input
              name="current_address"
              id="customername-field"
              className="form-control"
              placeholder="Enter Current Address"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.current_address || ""}
              invalid={
                validation.touched.current_address &&
                validation.errors.current_address
                  ? true
                  : false
              }
            />
            {validation.touched.current_address &&
            validation.errors.current_address ? (
              <FormFeedback type="invalid">
                {validation.errors.current_address}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Permanent Address
            </Label>
            <Input
              name="permanent_address"
              id="customername-field"
              className="form-control"
              placeholder="Enter Current Address"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.permanent_address || ""}
              invalid={
                validation.touched.permanent_address &&
                validation.errors.permanent_address
                  ? true
                  : false
              }
            />
            {validation.touched.permanent_address &&
            validation.errors.permanent_address ? (
              <FormFeedback type="invalid">
                {validation.errors.permanent_address}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="g-3 mt-1">
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Mobile Number
            </Label>
            <Input
              name="mobile"
              id="customername-field"
              className="form-control"
              placeholder="Enter Mobile Number"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.mobile || ""}
              invalid={
                validation.touched.mobile && validation.errors.mobile
                  ? true
                  : false
              }
            />
            {validation.touched.mobile && validation.errors.mobile ? (
              <FormFeedback type="invalid">
                {validation.errors.mobile}
                {validation.errors.mobile}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              CNIC Number
            </Label>
            <Input
              name="CNIC"
              id="customername-field"
              className="form-control"
              placeholder="Enter CNIC Number"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.CNIC || ""}
              invalid={
                validation.touched.CNIC && validation.errors.CNIC ? true : false
              }
            />
            {validation.touched.CNIC && validation.errors.CNIC ? (
              <FormFeedback type="invalid">
                {validation.errors.CNIC}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="g-3 mt-1">
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Time Slot
            </Label>
            <Select
              value={options.find(
                (option) => option.value === validation.values.time_slot
              )}
              onChange={(e, selected) => {
                validation.setFieldValue("time_slot", e.value);
              }}
              className="mb-0"
              options={options}
              id="taginput-choices"
            ></Select>
            {validation.touched.time_slot && validation.errors.time_slot ? (
              <p style={{ color: "red" }}>{validation.errors.time_slot}</p>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Supervisor
            </Label>
            <Select
              value={employeeOption.find(
                (option) => option.value === validation.values.supervisor
              )}
              onChange={(e, selected) => {
                validation.setFieldValue("supervisor", e.value);
              }}
              className="mb-0"
              options={employeeOption}
              id="taginput-choices"
            ></Select>
            {validation.touched.supervisor && validation.errors.supervisor ? (
              <p style={{ color: "red" }}>{validation.errors.supervisor}</p>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="g-3 mt-1">
        <Col lg={6}>
          <div>
            <Label htmlFor="name-field" className="form-label">
              Role
            </Label>
            <Select
              value={roles.find(
                (option) => option._id === validation.values.role
              )}
              onChange={(selectedOption) => {
                validation.setFieldValue("role", selectedOption?._id);
              }}
              className="mb-0"
              getOptionLabel={(option) => option.type}
              getOptionValue={(option) => option._id}
              options={roles}
              id="taginput-choices"
            ></Select>
            {validation.touched.role && validation.errors.role ? (
              <p style={{ color: "red" }}>{validation.errors.role}</p>
            ) : null}
          </div>
        </Col>
      </Row>
    </ModalBody>
  );
};

export default EmployeeModalBody;
