import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Row,
  Col,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import { centerData, statusData } from "./Dummyholidays";
import Flatpickr from "react-flatpickr";
const HolidayModal = (props) => {
  const { isOpen, toggle, isEdit, validation } = props;

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  return (
    <Modal
      size="lg"
      id="createTask"
      isOpen={isOpen}
      toggle={toggle}
      modalClassName="zoomIn"
      centered
      tabIndex="-1"
    >
      <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
        {!!isEdit ? "Edit Holiday" : "Create Holiday"}
      </ModalHeader>
      <ModalBody>
        <div id="task-error-msg" className="alert alert-danger py-2"></div>
        <Form
          id="creattask-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" id="taskid-input" className="form-control" />

          <Row className="g-4 mb-3">
            <Col lg={6}>
              <label htmlFor="task-status" className="form-label">
                Event Name
              </label>

              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Name"
                name="eventName"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.eventName || ""}
                invalid={
                  validation.touched.eventName && validation.errors.eventName
                    ? true
                    : false
                }
              />
              {validation.touched.eventName && validation.errors.eventName ? (
                <FormFeedback eventName="invalid">
                  {validation.errors.eventName}
                </FormFeedback>
              ) : null}
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor="status-field" className="form-label">
                  Center
                </Label>

                <Input
                  name="center"
                  type="select"
                  className="form-select"
                  id="status-field"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.center || ""}
                >
                  {centerData.map((item, key) => (
                    <React.Fragment key={key}>
                      <option>Select your Center </option>
                      {item.options.map((item, key) => (
                        <option value={item.value} key={key}>
                          {item.label}
                        </option>
                      ))}
                    </React.Fragment>
                  ))}
                </Input>
                {validation.touched.center && validation.errors.center ? (
                  <FormFeedback type="invalid">
                    {validation.errors.center}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="g-4 mb-3">
            <Col lg={6}>
              <label htmlFor="task-status" className="form-label">
                Description
              </label>
              <Input
                type="textarea"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Description"
                name="description"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description &&
                  validation.errors.description
                    ? true
                    : false
                }
                rows="3"
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </Col>

            <Col lg={6}>
              <div className="">
                <Label htmlFor="date-field" className="form-label">
                  Start Date
                </Label>

                <Flatpickr
                  name="startDate"
                  id="date-field"
                  className="form-control"
                  placeholder="Select Start Date"
                  options={{
                    altInput: true,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  onChange={(e) => dateformate(e)}
                  value={validation.values.startDate || ""}
                />
                {validation.touched.startDate && validation.errors.startDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.startDate}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="g-4 mb-3">
            <Col lg={6}>
              <div className="">
                <Label htmlFor="date-field" className="form-label">
                  End Date
                </Label>

                <Flatpickr
                  name="endDate"
                  id="date-field"
                  className="form-control"
                  placeholder="Select End Date"
                  options={{
                    altInput: true,
                    altFormat: "d M, Y",
                    dateFormat: "d M, Y",
                  }}
                  onChange={(e) => dateformate(e)}
                  value={validation.values.endDate || ""}
                />
                {validation.touched.endDate && validation.errors.endDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.endDate}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor="status-field" className="form-label">
                  Status
                </Label>

                <Input
                  name="status"
                  type="select"
                  className="form-select"
                  id="status-field"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.status || ""}
                >
                  {statusData.map((item, key) => (
                    <React.Fragment key={key}>
                      <option>Select your Status </option>
                      {item.options.map((item, key) => (
                        <option value={item.value} key={key}>
                          {item.label}
                        </option>
                      ))}
                    </React.Fragment>
                  ))}
                </Input>
                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <div className="justify-content-center" style={{ display: "flex" }}>
            <button type="submit" className="btn btn-success " id="addNewTodo">
              {"Add Holiday"}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default HolidayModal;
