import React, { useCallback, useMemo, useState } from "react";
import Loader from "../../Components/Common/Loader";
import TableContainer from "../../Components/Common/TableContainer";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Form,
  Row,
  Col,
  Input,
  Label,
  CardBody,
  FormFeedback,
} from "reactstrap";
function DesignationTable({
  designations,
  onClickDelete,
  modal,
  toggle,
  isEdit,
  validation,
  setModal,
  handleDesignationClick,
  deleteCheckbox,
}) {
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".designationCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="designationCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },

      {
        Header: "Title",
        accessor: "title",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <span
                  className="edit-item-btn"
                  onClick={() => {
                    const DesignationData = cellProps.row.original;
                    handleDesignationClick(DesignationData);
                  }}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                </span>
              </li>
              <li className="list-inline-item">
                <span
                  className="remove-item-btn"
                  onClick={() => {
                    const DesignationData = cellProps.row.original;
                    onClickDelete(DesignationData);
                  }}
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                </span>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleDesignationClick, checkedAll]
  );
  return (
    <React.Fragment>
      <Card id="contactList">
        <CardBody className="pt-0">
          <div>
            {designations && designations?.length ? (
              <TableContainer
                columns={columns}
                data={designations || []}
                setInfo={() => {}}
                isGlobalFilter={true}
                isFooter={true}
                customPageSize={8}
                className="custom-header-css"
                divClass="table-responsive table-card mb-0"
                tableClass="align-middle table-nowrap"
                theadClass="table-light"
                isSearchInput={true}
                SearchPlaceholder="Search for permission"
              />
            ) : (
              <Loader />
            )}
          </div>
          <Modal isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={toggle}>
              {!!isEdit ? "Edit Permission" : "Add Permissions"}
            </ModalHeader>
            <Form
              className="tablelist-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <ModalBody>
                <Input type="hidden" id="id-field" />
                <Row className="g-3">
                  <Col>
                    <div>
                      <Label htmlFor="route-field" className="form-label">
                        <span style={{ color: "red" }}>*</span>Title
                      </Label>
                      <Input
                        name="title"
                        id="route-field"
                        className="form-control"
                        placeholder="Title"
                        type="text"
                        value={validation.values.title}
                        onChange={validation.handleChange}
                        validate={{
                          required: { value: true },
                        }}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.title && validation.errors.title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="g-3 mt-2">
                  <Col>
                    <div>
                      <Label htmlFor="description-field" className="form-label">
                        <span style={{ color: "red" }}>*</span>
                        Description
                      </Label>
                      <Input
                        name="description"
                        id="description-field"
                        className="form-control"
                        placeholder="Description"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div
                  className="hstack gap-2 justify-content-end"
                  style={{ width: "100%" }}
                >
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="add-btn"
                  >
                    {!!isEdit ? "Update" : "Save"}{" "}
                  </button>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
export default DesignationTable;
