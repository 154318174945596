import React, { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Col,
  Row,
  CardBody,
  Container,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import HolidayCard from "./HolidayCard";
import { data } from "./Dummyholidays";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
const index = () => {
  const [holiday, setHoliday] = useState(null);
  const [modalHoliday, setModalHoliday] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [info, setInfo] = useState([]);
  const toggle = useCallback(() => {
    if (modalHoliday) {
      setModalHoliday(false);
      setHoliday(null);
    } else {
      setModalHoliday(true);
    }
  }, [modalHoliday]);
  const handleDeleteHoliday = () => {
    if (holiday) {
      //  dispatch(onDeleteHoliday(holiday));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (holiday) => {
    setHoliday(holiday);
    setDeleteModal(true);
  };
  function handleCreateHoliday() {
    setModalHoliday(!modalHoliday);
    setIsEdit(false);
    toggle();
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      eventName: (holiday && holiday.eventName) || "",
      center: (holiday && holiday.center) || "",
      description: (holiday && holiday.description) || "",
      startDate: (holiday && holiday.startDate) || "",
      endDate: (holiday && holiday.endDate) || "",
      status: (holiday && holiday.status) || "",
    },
    validationSchema: Yup.object({
      eventName: Yup.string().required("Please Enter Event Name"),
      center: Yup.string().required("Please Enter Center"),
      description: Yup.string().required("Please Enter Description"),
      startDate: Yup.string().required("Please Enter Start"),
      endDate: Yup.string().required("Please Enter End Date"),
      status: Yup.string().required("Please Enter Status"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateHoliday = {
          _id: holiday ? holiday._id : 0,
          eventName: values.eventName,
          center: values.center,
          description: values.description,
          startDate: startDate,
          endDate: endDate,
          status: values.status,
        };
        //  dispatch(onUpdateCustomer(updateHoliday));
        validation.resetForm();
      } else {
        const newHoliday = {
          // _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          eventName: values.eventName,
          center: values.center,
          description: values.description,
          startDate: startDate,
          endDate: endDate,
          status: values.status,
        };
        //  dispatch(onAddNewHoliday(newHoliday));
        validation.resetForm();
      }
      toggle();
    },
  });
  const handleHolidayClick = useCallback(
    (arg) => {
      const holiday = arg;
      setHoliday({
        _id: holiday._id,
        eventName: holiday.eventName,
        center: holiday.center,
        description: holiday.description,
        startDate: holiday.startDate,
        endDate: holiday.endDate,
        status: holiday.status,
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".holidayCheckBox");
    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      //  dispatch(onDeleteCustomer(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };
  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".holidayCheckBox:checked");
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="holidayCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "",
        accessor: "_id",
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: "Event name",
        accessor: "eventName",

        Cell: (cellProps) => {
          return (
            <>
              <div style={{ display: "inline-grid" }}>
                {cellProps.value}
                <Badge color="success">Published</Badge>
              </div>
            </>
          );
        },
        filterable: false,
      },
      {
        Header: "Center",
        accessor: "center",
        filterable: true,
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        filterable: true,
      },
      {
        Header: "End Date",
        accessor: "endDate",
        filterable: true,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item" title="View">
                <Link
                  to="#"
                  onClick={() => {
                    const holidayData = cellProps.row.original;
                    setInfo(holidayData);
                  }}
                >
                  <i className="ri-eye-fill align-bottom text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Edit">
                <Link
                  className="edit-item-btn"
                  to="#"
                  onClick={() => {
                    const holidayData = cellProps.row.original;
                    handleHolidayClick(holidayData);
                  }}
                >
                  <i className="ri-pencil-fill align-bottom text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Delete">
                <Link
                  className="remove-item-btn"
                  onClick={() => {
                    const holidayData = cellProps.row.original.id;
                    onClickDelete(holidayData);
                  }}
                  to="#"
                >
                  <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleHolidayClick, checkedAll]
  );
  document.title = "Holidays | Webevis - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "auto" }} />
          </Row>
          <ExportCSVModal
            show={isExportCSV}
            onCloseClick={() => setIsExportCSV(false)}
            data={data}
          />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteHoliday}
            onCloseClick={() => setDeleteModal(false)}
          />
          <DeleteModal
            show={deleteModalMulti}
            onDeleteClick={() => {
              deleteMultiple();
              setDeleteModalMulti(false);
            }}
            onCloseClick={() => setDeleteModalMulti(false)}
          />
          <Row style={{ width: "100%", margin: "auto" }}>
            <Card id="customerList">
              <HolidayCard
                isMultiDeleteButton={isMultiDeleteButton}
                setDeleteModalMulti={setDeleteModalMulti}
                handleCreateHoliday={handleCreateHoliday}
                setIsExportCSV={setIsExportCSV}
                data={data}
                columns={columns}
                modalHoliday={modalHoliday}
                toggle={toggle}
                isEdit={isEdit}
                validation={validation}
                setModalHoliday={setModalHoliday}
              />
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default index;
