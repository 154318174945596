import React from "react";
import {
  Card,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  TabContent,
  TabPane,
  Col,
} from "reactstrap";
import classnames from "classnames";
import ProfileUpdateForm from "./ProfileUpdateForm";
import ExperienceForm from "./ExperienceForm";
import UploadDocument from "./UploadDocument";

const EditProfileTabContent = ({
  activeTab,
  tabChange,
  validation,
  timeslot,
  employees,
}) => {
  return (
    <Col xxl={9}>
      <Card className="mt-xxl-n5">
        <CardHeader>
          <Nav
            className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
            role="tablist"
          >
            <NavItem>
              <NavLink
                to="#"
                type="button"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  tabChange("1");
                }}
              >
                Personal Details
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  tabChange("2");
                }}
                type="button"
              >
                Experience
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  tabChange("3");
                }}
                type="button"
              >
                Upload Documents
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="p-4">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <ProfileUpdateForm
                validation={validation}
                timeslot={timeslot}
                employees={employees}
              />
            </TabPane>
            <TabPane tabId="2">
              <ExperienceForm />
            </TabPane>

            <TabPane tabId="3">
              <UploadDocument />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EditProfileTabContent;
