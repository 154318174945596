import React, { useCallback, useState } from "react";
import { Form, Modal, ModalFooter, ModalHeader } from "reactstrap";
// import { centerOption } from "../EmployeData";
import EmployeeModalBody from "./EmployeeModalBody";
const centerOption = [
  {
    _id: 1,
    label: "Center-1",
    value: "Center-1",
  },
  {
    _id: 2,
    label: "Center-2",
    value: "Center-2",
  },
];

function EmployeeModal({
  isEdit,
  timeslot,
  validation,
  employeeModal,
  setEmployeeModal,
  employees,
  designations,
  roles,
}) {
  const toggle = useCallback(() => {
    if (employeeModal) {
      setEmployeeModal(false);
    } else {
      setEmployeeModal(true);
    }
  }, [employeeModal]);

  const employeeOption = employees.map((employee) => ({
    value: employee?._id,
    label: employee?.fullName,
  }));

  const options = timeslot?.map((slot) => ({
    value: slot._id,
    label:
      slot.slot_Name +
      " (" +
      slot.checkIn_buffer +
      " - " +
      slot.checkOutTime +
      ")",
  }));

  return (
    <React.Fragment>
      <Modal
        id="showModal"
        isOpen={employeeModal}
        toggle={toggle}
        centered
        size="lg"
      >
        <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
          {isEdit ? "Update Employee" : "Add Employee"}
        </ModalHeader>
        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <EmployeeModalBody
            validation={validation}
            centerOption={centerOption}
            designations={designations}
            options={options}
            employeeOption={employeeOption}
            roles={roles}
          />
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setEmployeeModal(false);
                }}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {!!isEdit ? "Update" : "Add Employee"}{" "}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
export default EmployeeModal;
