import { createSlice } from "@reduxjs/toolkit";
import {
  getAllEmployee,
  createNewEmployee,
  deleteEmployee,
  getTimeSlot,
  onUpdateEmployee,
} from "./thunk";

export const initialState = {
  employees: [],
  timeslot: [],
  isLoading: false,
  error: "",
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employees = action.payload?.data || [];
      })
      .addCase(getAllEmployee.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNewEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNewEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employees = [...state.employees, action.payload.data];
      })
      .addCase(createNewEmployee.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(onUpdateEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateEmployee.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.employees = state.employees.map((employee) =>
          employee._id.toString() === obj._id.toString()
            ? { ...employee, ...obj }
            : employee
        );
      })
      .addCase(onUpdateEmployee.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(deleteEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.employees = state.employees.filter(
          (employee) => employee._id.toString() !== obj.toString()
        );
      })
      .addCase(deleteEmployee.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getTimeSlot.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTimeSlot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timeslot = action.payload;
      })
      .addCase(getTimeSlot.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default employeeSlice.reducer;
