import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
//Images
import profileBg from "../../../assets/images/profile-bg.jpg";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import TabPaneContent from "./TabPaneContent";
import { projects, document } from "../../../common/data";
const SimplePage = () => {
  document.title = "Profile  | HREvis";
  // SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState("1");
  const [user, setUser] = useState("");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      setUser(user);
    }
  }, []);

  document.title = "Profile | HREvis";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img src={profileBg} alt="" className="profile-wid-img" />
            </div>
          </div>

          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img
                    src={user?.user?.photo ? user?.user?.photo : avatar1}
                    alt="user-img"
                    className="img-thumbnail rounded-circle"
                    style={{ height: "100px" }}
                  />
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-1">
                    {user?.user?.fullName || "Anna Adame"}
                  </h3>
                  <p className="text-white-75">{""}</p>
                  <div className="hstack text-white-50 gap-1">
                    {user?.user?.current_address && (
                      <div className="me-2">
                        <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>
                        {user?.user?.current_address}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  <Nav
                    pills
                    className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#overview-tab"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Overview
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        href="#documents"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Documents
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="flex-shrink-0">
                    <Link to="/profile-settings" className="btn btn-info">
                      <i className="ri-edit-box-line align-bottom"></i> Edit
                      Profile
                    </Link>
                  </div>
                </div>

                <TabContent activeTab={activeTab} className="pt-4 text-muted">
                  <TabPane tabId="1">
                    <TabPaneContent user={user} />
                  </TabPane>
                  <TabPane tabId="2">
                    <Card>
                      <CardBody>
                        <div className="d-flex align-items-center mb-4">
                          <h5 className="card-title flex-grow-1 mb-0">
                            Documents
                          </h5>
                        </div>
                        <Row>
                          <Col
                            xxl={3}
                            xl={4}
                            sm={6}
                            className="element-item project designing development"
                          >
                            <Card className="gallery-box">
                              <div className="gallery-container">
                                <img
                                  className="gallery-img img-fluid mx-auto"
                                  src={user?.user?.cnicPic}
                                  alt=""
                                />
                                <div className="gallery-overlay">
                                  <h5 className="overlay-caption">
                                    {"CNIC Document"}
                                  </h5>
                                </div>
                              </div>
                            </Card>
                          </Col>

                          <Col
                            xxl={3}
                            xl={4}
                            sm={6}
                            className="element-item project designing development"
                          >
                            <Card className="gallery-box">
                              <div className="gallery-container">
                                <img
                                  className="gallery-img img-fluid mx-auto"
                                  src={user?.user?.medicalDoc}
                                  alt=""
                                />
                                <div className="gallery-overlay">
                                  <h5 className="overlay-caption">
                                    {"Medical Document"}
                                  </h5>
                                </div>
                              </div>
                            </Card>
                          </Col>

                          <Col
                            xxl={3}
                            xl={4}
                            sm={6}
                            className="element-item project designing development"
                          >
                            <Card className="gallery-box">
                              <div className="gallery-container">
                                <img
                                  className="gallery-img img-fluid mx-auto"
                                  src={user?.user?.educationDoc}
                                  alt=""
                                />
                                <div className="gallery-overlay">
                                  <h5 className="overlay-caption">
                                    {"Education Document"}
                                  </h5>
                                </div>
                              </div>
                            </Card>
                          </Col>

                          <Col
                            xxl={3}
                            xl={4}
                            sm={6}
                            className="element-item project designing development"
                          >
                            <Card className="gallery-box">
                              <div className="gallery-container">
                                <img
                                  className="gallery-img img-fluid mx-auto"
                                  src={user?.user?.experienceLetter}
                                  alt=""
                                />
                                <div className="gallery-overlay">
                                  <h5 className="overlay-caption">
                                    {" Experience Letter"}
                                  </h5>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SimplePage;
