import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";

export const getAllDesignations = createAsyncThunk(
  "designation/getAllDesignations",
  async () => {
    try {
      return await services.getDesignations();
    } catch (error) {}
  }
);

export const createNewDesignation = createAsyncThunk(
  "designation/createNewDesignation",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createDesignation(obj);
    } catch (error) {}
  }
);

export const deleteDesignation = createAsyncThunk(
  "designation/deleteDesignation",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.onDeleteDesignation(obj);
    } catch (error) {}
  }
);

export const onUpdateDesignation = createAsyncThunk(
  "designation/onUpdateDesignation",
  async (obj) => {
    try {
      return await services.updateDesignation(obj);
    } catch (error) {}
  }
);
