import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Progress, Row, Table } from "reactstrap";

const TabPaneContent = ({ user }) => {
  document.title = "Profile  | HREvis";
  return (
    <div className="tab-pane">
      <Row>
        <Col xxl={3}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-5">Complete Your Profile</h5>
              <Progress
                value={30}
                color="info"
                className="animated-progess custom-progress progress-label"
              >
                <div className="label">30%</div>
              </Progress>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h5 className="card-title mb-3">Info</h5>
              <div className="table-responsive">
                <Table className="table-borderless mb-0">
                  <tbody>
                    <tr>
                      <th className="ps-0" scope="row">
                        Full Name :{" "}
                      </th>
                      <td className="text-muted">
                        {user?.user?.fullName || "Anna Adame"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        Mobile :
                      </th>
                      <td className="text-muted">
                        {user?.user?.mobile || "+(1) 987 6543"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        E-mail :
                      </th>
                      <td className="text-muted">
                        {user?.user?.email || "daveadame@velzon.com"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        Location :
                      </th>
                      <td className="text-muted">
                        {user?.user?.current_address ||
                          "California, United States"}
                      </td>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        Joining Date
                      </th>
                      <td className="text-muted">
                        {user?.user?.joining_date || "24 Nov 2021"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h5 className="card-title mb-4">Portfolio</h5>
              <div className="d-flex flex-wrap gap-2">
                <div>
                  <Link to="#" className="avatar-xs d-block">
                    <span className="avatar-title rounded-circle fs-16 bg-dark text-light">
                      <i className="ri-github-fill"></i>
                    </span>
                  </Link>
                </div>
                <div>
                  <Link to="#" className="avatar-xs d-block">
                    <span className="avatar-title rounded-circle fs-16 bg-primary">
                      <i className="ri-global-fill"></i>
                    </span>
                  </Link>
                </div>
                <div>
                  <Link to="#" className="avatar-xs d-block">
                    <span className="avatar-title rounded-circle fs-16 bg-success">
                      <i className="ri-dribbble-fill"></i>
                    </span>
                  </Link>
                </div>
                <div>
                  <Link to="#" className="avatar-xs d-block">
                    <span className="avatar-title rounded-circle fs-16 bg-danger">
                      <i className="ri-pinterest-fill"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h5 className="card-title mb-4">Skills</h5>
              <div className="d-flex flex-wrap gap-2 fs-15">
                <Link to="#" className="badge badge-soft-primary">
                  Photoshop
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  illustrator
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  HTML
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  CSS
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  Javascript
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  Php
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  Python
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xxl={9}>
          <Card>
            <CardBody>
              <h5 className="card-title mb-3">About</h5>
              <p>
                Hi I'm Anna Adame, It will be as simple as Occidental; in fact,
                it will be Occidental. To an English person, it will seem like
                simplified English, as a skeptical Cambridge friend of mine told
                me what Occidental is European languages are members of the same
                family.
              </p>
              <p>
                You always want to make sure that your fonts work well together
                and try to limit the number of fonts you use to three or less.
                Experiment and play around with the fonts that you already have
                in the software you’re working with reputable font websites.
                This may be the most commonly encountered tip I received from
                the designers I spoke with. They highly encourage that you use
                different fonts in one design, but do not over-exaggerate and go
                overboard.
              </p>
              <Row>
                <Col xs={6} md={4}>
                  <div className="d-flex mt-4">
                    <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                      <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                        <i className="ri-user-2-fill"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="mb-1">Designation :</p>
                      <h6 className="text-truncate mb-0">
                        {user?.user?.designation?.title}
                      </h6>
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={4}>
                  <div className="d-flex mt-4">
                    <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                      <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                        <i className="ri-global-line"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="mb-1">Website :</p>
                      <Link to="#" className="fw-semibold">
                        www.webevis.com
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TabPaneContent;
