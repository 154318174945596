import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";

export const getAllEmployee = createAsyncThunk(
  "employee/getAllEmployee",
  async () => {
    try {
      return await services.getEmployees();
    } catch (error) {}
  }
);

export const createNewEmployee = createAsyncThunk(
  "employee/createEmployee",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createEmployee(obj);
    } catch (error) {}
  }
);

export const onUpdateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async (obj) => {
    try {
      return await services.updateEmployee(obj);
    } catch (error) {}
  }
);

export const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.onDeleteEmployee(obj);
    } catch (error) {}
  }
);

export const getTimeSlot = createAsyncThunk(
  "employee/getTimeSlot",
  async () => {
    try {
      return await services.timeSlot();
    } catch (error) {}
  }
);
