import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";
export const getAllroles = createAsyncThunk("role/getAllroles", async () => {
  try {
    return await services.getRoles();
  } catch (error) {}
});

export const getAllParents = createAsyncThunk(
  "role/getAllParents",
  async () => {
    try {
      return await services.getParents();
    } catch (error) {}
  }
);

export const createNewRole = createAsyncThunk(
  "role/createNewRole",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createRole(obj);
    } catch (error) {}
  }
);

export const deleteRole = createAsyncThunk("role/deleteRole", async (obj) => {
  try {
    //console.log(obj)
    return await services.onDeleteRole(obj);
  } catch (error) {}
});

export const onUpdateRole = createAsyncThunk(
  "role/onUpdateRole",
  async (obj) => {
    try {
      return await services.updateRole(obj);
    } catch (error) {}
  }
);
