import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Loader from "../../Components/Common/Loader";
import TableContainer from "../../Components/Common/TableContainer";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateAttendence, getAllAttendence } from "../../slices/thunks";
import UpdateModal from "./UpdateModal";
function CustomTable({ deleteCheckbox, isAttendenceLoading, onClickDelete }) {
  const dispatch = useDispatch();
  const { attendences, currentPage, totalPages } = useSelector((state) => ({
    attendences: state.Attendence.attendences,
    currentPage: state.Attendence.currentPage,
    totalPages: state.Attendence.totalPages,
  }));
  const [modal_grid, setmodal_grid] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [user, setUser] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      // console.log(obj)
      setUser(user);
    }
  }, []);

  const toggle = useCallback(() => {
    if (modal_grid) {
      setmodal_grid(false);
      setAttendance(null);
    } else {
      setmodal_grid(true);
    }
  }, [modal_grid]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: attendance?.date || "",
      clockIn: attendance?.ci_time || "",
      clockOut: attendance?.co_time || "",
    },
    validationSchema: Yup.object({
      date: Yup.string().required("Date is required"),
      clockIn: Yup.string().required("Clock In time is required"),
      clockOut: Yup.string().required("Clock Out time is required"),
    }),
    onSubmit: (values) => {
      //  console.log(updateAttendence);
      setmodal_grid(false);
      const updateAttendencedata = {
        _id: attendance._id,
        center: attendance.center,
        user_id: attendance.user_id,
        ci_time: values.clockIn,
        co_time: values.clockOut,
        date: values.date,
        day: attendance.day,
        markedBy: user?.user?._id,
      };

      dispatch(updateAttendence(updateAttendencedata));
      formik.resetForm();
    },
  });
  const handleAttendanceClick = useCallback(
    (arg) => {
      const attendance = arg;
      setAttendance({
        _id: attendance._id,
        center: attendance.center,
        user_id: attendance.user_id,
        ci_time: attendance.ci_time,
        co_time: attendance.co_time,
        date: attendance.date,
        day: attendance.day,
      });
      toggle();
    },
    [toggle]
  );
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".attendanceCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="attendanceCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Username",
        accessor: "user_id.username",
        filterable: false,
        Cell: (cellProps) => {
          return <>{cellProps.value}</>;
        },
      },
      // {
      //   Header: "Email",
      //   accessor: "email",
      //   filterable: false,
      // },
      {
        Header: "Center",
        accessor: "center",
        filterable: false,
      },
      {
        Header: "Day",
        accessor: "day",
        filterable: false,
      },
      {
        Header: "CheckIn Time",
        accessor: "ci_time",
        filterable: false,
        Cell: (cellProps) => {
          if (cellProps.row.original.ci_time === null) {
            return (
              <>{/* Display a placeholder or empty value for null ci_time */}</>
            );
          }

          const currentTime = new Date();
          const ciTimeParts = cellProps.row.original.ci_time.split(":");

          currentTime.setHours(parseInt(ciTimeParts[0], 10));
          currentTime.setMinutes(parseInt(ciTimeParts[1], 10));

          const formattedTime = currentTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });

          return <>{formattedTime}</>;
        },
      },

      {
        Header: "CheckOut Time",
        accessor: "co_time",
        filterable: false,
        Cell: (cellProps) => {
          if (cellProps.row.original.co_time === null) {
            return (
              <>{/* Display a placeholder or empty value for null ci_time */}</>
            );
          }
          const currentTime = new Date();
          const ciTimeParts = cellProps.row.original.co_time.split(":");
          currentTime.setHours(parseInt(ciTimeParts[0], 10));
          currentTime.setMinutes(parseInt(ciTimeParts[1], 10));
          const formattedTime = currentTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });

          return <>{formattedTime}</>;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item" title="View">
                <Link
                  to="#"
                  onClick={() => {
                    const attendanceData = cellProps.row.original;
                    // setInfo(attendanceData);
                  }}
                >
                  <i className="ri-eye-fill align-bottom text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Edit">
                <Link
                  className="edit-item-btn"
                  onClick={() => {
                    const attendanceData = cellProps.row.original;
                    handleAttendanceClick(attendanceData);
                  }}
                >
                  <i className="ri-pencil-fill align-bottom text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Delete">
                <Link
                  className="remove-item-btn"
                  onClick={() => {
                    const attendanceData = cellProps.row.original;
                    onClickDelete(attendanceData);
                  }}
                  to="#"
                >
                  <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleAttendanceClick, checkedAll]
  );
  const handleNextPageClick = () => {
    dispatch(getAllAttendence(currentPage + 1));
  };
  const handlePreviousPageClick = (page) => {
    if (currentPage > 1) {
      dispatch(getAllAttendence(currentPage - 1));
    }
  };
  return (
    <React.Fragment>
      <div>
        <Card id="contactList">
          <CardBody className="pt-10">
            <div>
              {isAttendenceLoading ? (
                <Loader />
              ) : attendences?.length >= 0 ? (
                <TableContainer
                  columns={columns}
                  data={attendences}
                  setInfo={() => {}}
                  isGlobalFilter={true}
                  isFooter={true}
                  customPageSize={10}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-0"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  isContactsFilter={false}
                  isSearchInput={true}
                  SearchPlaceholder="Search for name"
                  ishandleClick={true}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handleNextPageClick={handleNextPageClick}
                  handlePreviousPageClick={handlePreviousPageClick}
                />
              ) : (
                <Loader />
              )}
            </div>
          </CardBody>
        </Card>
        <UpdateModal
          isOpen={modal_grid}
          toggle={toggle}
          initialValues={{
            date: attendance?.date || "",
            clockIn: attendance?.ci_time || "",
            clockOut: attendance?.co_time || "",
          }}
          onSubmit={formik.handleSubmit}
          formikProps={formik}
        />
      </div>
    </React.Fragment>
  );
}

export default CustomTable;
