import React, { useCallback, useState, useEffect } from "react";
import CustomTable from "./CustomTable";
import { Button, Container, Row, Card } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import usePermissionHandler from "../../helpers/usePermissionHandler";
import {
  getAllEmployee,
  createNewEmployee,
  deleteEmployee,
  getTimeSlot,
  onUpdateEmployee,
} from "../../slices/thunks";
import { getAllDesignations } from "../../slices/thunks";
import { getAllroles } from "../../slices/thunks";
import DeleteModal from "../../Components/Common/DeleteModal";
import EmployeeModal from "./employeeModal/EmployeModal";
import { useLocation } from "react-router-dom";
export default function Employee() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const { employees, timeslot, designations, roles } = useSelector((state) => ({
    employees: state.Employee.employees,
    timeslot: state.Employee.timeslot,
    designations: state.Designation.designations,
    roles: state.Role.roles,
  }));

  const { editPermissions, createPermissions, deletePermissions } =
    usePermissionHandler(currentRoute);

  useEffect(() => {
    if (roles && !roles.length) {
      dispatch(getAllroles());
    }
  }, [dispatch, roles]);

  useEffect(() => {
    if (designations && !designations.length) {
      dispatch(getAllDesignations());
    }
  }, [dispatch, designations]);

  useEffect(() => {
    dispatch(getTimeSlot());
  }, [dispatch]);

  useEffect(() => {
    if (employees && !employees?.length) {
      dispatch(getAllEmployee());
    }
  }, []);

  useEffect(() => {
    setEmployee(employees);
  }, [employees]);

  useEffect(() => {
    if (!isEmpty(employees)) {
      setEmployee(employees);
      setIsEdit(false);
    }
  }, [employees]);

  useEffect(() => {
    if (!isEmpty(employees)) {
      setEmployee(employees);
      setIsEdit(false);
    }
  }, [employees]);

  const [employeeModal, setEmployeeModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState([]);

  const toggle = useCallback(() => {
    if (employeeModal) {
      setEmployeeModal(false);
      setEmployee(null);
    } else {
      setEmployeeModal(true);
    }
  }, [employeeModal]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      photo: employee?.photo || "",
      fullName: employee?.fullName || "",
      email: employee?.email || "",
      DOB: employee?.DOB || "",
      joining_date: employee?.joining_date || "",
      center: employee?.center || "",
      designation: employee?.designation || "",
      mobile: employee?.mobile || "",
      CNIC: employee?.CNIC || "",
      current_address: employee?.current_address || "",
      permanent_address: employee?.permanent_address || "",
      time_slot: employee?.time_slot || "",
      supervisor: employee?.supervisor || "",
      role: employee?.role || "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Please Enter Email"),
      DOB: Yup.string().required("Please Enter Date Of Birth"),
      joining_date: Yup.string().required("Please Enter Date Of Joining"),
      // shift: Yup.string().required("Please Enter Shift"),
      center: Yup.string().required("Please Enter Center"),
      designation: Yup.string().required("Please Enter Designation"),
      mobile: Yup.string().required("Please Enter Mobile Number"),
      CNIC: Yup.string().required("Please Enter CNIC Number"),
      current_address: Yup.string().required("Please Enter Current Address"),
      permanent_address: Yup.string().required(
        "Please Enter Permanent Address"
      ),
      time_slot: Yup.string().required("Please Enter Time Slot"),
      supervisor: Yup.string().required("Please Select Supervisor"),
      role: Yup.string().required("Please Select Role"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateEmployee = {
          _id: employee ? employee._id : 0,
          photo: values.photo,
          fullName: values.fullName,
          email: values.email,
          DOB: values.DOB,
          joining_date: values.joining_date,
          center: values.center,
          designation: values.designation,
          mobile: values.mobile,
          CNIC: values.CNIC,
          current_address: values.current_address,
          permanent_address: values.permanent_address,
          username: values.email,
          time_slot: values.time_slot,
          supervisor: values.supervisor,
          role: values.role,
        };

        dispatch(onUpdateEmployee(updateEmployee));
        validation.resetForm();
      } else {
        const newEmployee = {
          photo: values["photo"],
          fullName: values["fullName"],
          email: values["email"],
          DOB: values["DOB"],
          joining_date: values["joining_date"],
          center: values["center"],
          designation: values["designation"],
          mobile: values["mobile"],
          CNIC: values["CNIC"],
          current_address: values["current_address"],
          permanent_address: values["permanent_address"],
          username: values["email"],
          time_slot: values["time_slot"],
          supervisor: values["supervisor"],
          role: values["role"],
          password: "12345",
        };
        // save new Employee
        dispatch(createNewEmployee(newEmployee));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleEmployeeClick = useCallback(
    (arg) => {
      const employee = arg;
      setEmployee({
        _id: employee._id,
        photo: employee.photo,
        fullName: employee.fullName,
        email: employee.email,
        DOB: employee.DOB,
        joining_date: employee.joining_date,
        center: employee.center,
        mobile: employee.mobile,
        current_address: employee.current_address,
        permanent_address: employee.permanent_address,
        CNIC: employee.CNIC,
        designation: employee.designation?._id || employee.designation,
        time_slot: employee.time_slot?._id || employee.time_slot,
        supervisor: employee.supervisor?._id || employee.supervisor,
        role: employee.role?._id ? employee.role?._id : employee.role || "",
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const onClickDelete = (employee) => {
    setEmployee(employee);
    setDeleteModal(true);
  };

  const handleDeleteEmployee = () => {
    if (employee) {
      dispatch(deleteEmployee(employee._id));
      setDeleteModal(false);
    }
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(deleteEmployee(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".employeesCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  document.title = "Employees | HREVIS";
  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEmployee}
        onCloseClick={() => setDeleteModal(false)}
      />

      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />

      <div className="page-content">
        <Container fluid>
          <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "0 auto" }} />
          </Row>

          <div className="mb-4">
            {createPermissions && createPermissions.length > 0 ? (
              <Button
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  validation.setFieldValue("designation", "");
                  validation.setFieldValue("center", "");
                  validation.setFieldValue("time_slot", "");
                  setIsEdit(false);
                  setEmployee([]);
                  toggle();
                }}
              >
                + Add new Employee
              </Button>
            ) : (
              ""
            )}

            {isMultiDeleteButton && (
              <Button
                onClick={() => {
                  setDeleteModalMulti(true);
                }}
              >
                <i className="ri-delete-bin-2-line"></i>
              </Button>
            )}
          </div>

          <div>
            <CustomTable
              deleteCheckbox={deleteCheckbox}
              employees={employees}
              setEmployeeModal={setEmployeeModal}
              toBeDeleted={toBeDeleted}
              setToBeDeleted={setToBeDeleted}
              onClickDelete={onClickDelete}
              handleEmployeeClick={handleEmployeeClick}
              timeslot={timeslot}
              designations={designations}
              editPermissions={editPermissions}
              deletePermissions={deletePermissions}
            />
          </div>
          <EmployeeModal
            isEdit={isEdit}
            timeslot={timeslot}
            employeeModal={employeeModal}
            setEmployeeModal={setEmployeeModal}
            validation={validation}
            employees={employees}
            designations={designations}
            roles={roles}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}
