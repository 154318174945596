import React from "react";
import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DealType from "./DealType";
import Widgets from "./Widgets";

import {
  SimplePie,
  SimpleDonut,
  UpdateExpenseDonut,
} from "../Charts/ApexCharts/PieCharts/PieCharts";

import { BarChart } from "../Charts/ChartsJs/ChartsJs";
import Calender from "../../pages/Calendar";

document.title = "Dashboard | HREVIS";

const DashboardCrm = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            style={{ width: "96.5%", margin: "auto" }}
            title="Welcome!"
            pageTitle="Dashboards"
          />
          <Row style={{ padding: "0px 21px" }}>
            <Widgets />
          </Row>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
                width: "99%",
                margin: "auto",
              }}
            >
              <Col xl={8}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">
                      Payment -- Last 6 Months
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {/* <BarChart dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]' /> */}
                  </CardBody>
                </Card>
              </Col>
              <DealType />
            </div>
          </Col>
          <Col
            xl={12}
            style={{
              display: "flex",
              width: "98%",
              margin: "auto",
              gap: "5px",
            }}
          >
            <Col xl={4}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Employee Designation</h4>
                </CardHeader>
                <CardBody>
                  <SimplePie dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    Expense <strong>Vs</strong> Deposit
                  </h4>
                </CardHeader>
                <CardBody>
                  <div>
                    <UpdateExpenseDonut dataColors='["--vz-primary", "--vz-primary-rgb, 0.80", "--vz-primary-rgb, 0.60", "--vz-primary-rgb, 0.45"]' />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Project Status</h4>
                </CardHeader>
                <CardBody>
                  <SimpleDonut dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
                </CardBody>
              </Card>
            </Col>
          </Col>
          <Calender />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardCrm;
