import React, { useState } from "react";
import { Input, Label } from "reactstrap";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
const ProfileImage = ({ user, validation }) => {
  const [imagePreview, setImagePreview] = useState(avatar1);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 70 * 1024) {
      const imageURL = URL.createObjectURL(file);
      setImagePreview(imageURL);
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64ImageString = event.target.result;
        validation.setFieldValue("photo", base64ImageString);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
      <img
        src={user?.user?.photo ? user?.user?.photo : imagePreview}
        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
        alt="user-profile"
      />
      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
        <Input
          id="profile-img-file-input"
          type="file"
          className="profile-img-file-input"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleImageChange}
          onBlur={validation.handleBlur}
          invalid={validation.touched.photo && validation.errors.photo}
        />
        <Label
          htmlFor="profile-img-file-input"
          className="profile-photo-edit avatar-xs"
        >
          <span className="avatar-title rounded-circle bg-light text-body">
            <i className="ri-camera-fill"></i>
          </span>
        </Label>
      </div>
    </div>
  );
};

export default ProfileImage;
