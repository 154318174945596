import React, { useCallback, useMemo, useState } from "react";
import { Card, Table, CardBody, Button } from "reactstrap";
import Loader from "../../Components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import TableContainer from "../../Components/Common/TableContainer";
import { Link } from "react-router-dom";

function CustomTable({
  deleteCheckbox,
  employees,
  onClickDelete,
  handleEmployeeClick,
  timeslot,
  designations,
  editPermissions,
  deletePermissions,
}) {
  const [modal, setModal] = useState(false);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".employeesCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="employeesCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Employee",
        accessor: "fullName",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <React.Fragment>
              <div className="d-flex ">
                <div className="flex-shrink-0">
                  {
                    cellProps.row.original.photo ? (
                      <img
                        src={cellProps.row.original.photo}
                        alt=""
                        className="avatar-xxs rounded-circle"
                      />
                    ) : (
                      <div className="flex-shrink-0 avatar-xs me-2">
                        <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                          {cellProps.row.original?.fullName?.charAt(0)}
                        </div>
                      </div>
                    )
                    // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                  }
                </div>
                <div className="flex-grow-1 ms-2 name">
                  <p style={{ color: "blue" }}>
                    username : {cellProps.row.original?.fullName}{" "}
                  </p>
                  <p>Email : {cellProps.row.original?.email}</p>
                  <p>Gender : {cellProps.row.original?.gender}</p>
                  <p>Date of Birth : {cellProps.row.original?.DOB}</p>
                  {/* <p>Shift : {cellProps.row.original?.time_slot.slot_Name}</p> */}
                  <p>
                    Shift :{" "}
                    {cellProps.row.original?.time_slot &&
                    typeof cellProps.row.original?.time_slot === "object" ? (
                      <span>{cellProps.row.original?.time_slot.slot_Name}</span>
                    ) : cellProps.row.original?.time_slot &&
                      typeof cellProps.row.original?.time_slot === "string" ? (
                      <span>
                        {timeslot.map(
                          (item) =>
                            item._id === cellProps.row.original?.time_slot &&
                            item.slot_Name
                        )}
                      </span>
                    ) : null}
                  </p>
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Company",
        accessor: "company",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <React.Fragment>
              <div className="d-flex ">
                <div className="flex-grow-1 ms-2 name">
                  <p style={{ fontStyle: "bold" }}>
                    Company : {cellProps.row.original?.company}
                  </p>
                  <p>Center : {cellProps.row.original?.center}</p>

                  <p>
                    Designation :{" "}
                    {cellProps.row.original?.designation &&
                    typeof cellProps.row.original?.designation === "object" ? (
                      <span>{cellProps.row.original?.designation?.title}</span>
                    ) : cellProps.row.original?.designation &&
                      typeof cellProps.row.original?.designation ===
                        "string" ? (
                      <span>
                        {designations.map(
                          (item) =>
                            item._id === cellProps.row.original?.designation &&
                            item.title
                        )}
                      </span>
                    ) : null}
                  </p>
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Contact",
        accessor: "email",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <React.Fragment>
              <div className="d-flex ">
                <div className="flex-grow-1 ms-2 name">
                  <p>
                    <i className="ri-mail-line"></i> :{" "}
                    {cellProps.row.original?.email}
                  </p>
                  <p>
                    <i className="ri-phone-line"></i> :{" "}
                    {cellProps.row.original?.mobile}
                  </p>
                  <p>
                    <i className="ri-map-pin-line"></i> :{" "}
                    {cellProps.row.original?.current_address}
                  </p>
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item" title="View">
                <Link
                  to="#"
                  onClick={() => {
                    const employeeData = cellProps.row.original;
                    // setInfo(employeeData);
                  }}
                >
                  <i className="ri-eye-fill align-bottom text-muted"></i>
                </Link>
              </li>

              {editPermissions && editPermissions?.length > 0 ? (
                <li className="list-inline-item" title="Edit">
                  <Link
                    className="edit-item-btn"
                    onClick={() => {
                      const employeeData = cellProps.row.original;
                      handleEmployeeClick(employeeData);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom text-muted"></i>
                  </Link>
                </li>
              ) : (
                ""
              )}

              {deletePermissions && deletePermissions?.length > 0 ? (
                <li className="list-inline-item" title="Delete">
                  <Link
                    className="remove-item-btn"
                    onClick={() => {
                      const employeeData = cellProps.row.original;
                      onClickDelete(employeeData);
                    }}
                    to="#"
                  >
                    <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          );
        },
      },
    ],
    [handleEmployeeClick, checkedAll, editPermissions, deletePermissions]
  );
  return (
    <React.Fragment>
      <div>
        <Card id="contactList" style={{ width: "100%" }}>
          <CardBody className="pt-0">
            <div>
              {employees.length ? (
                <TableContainer
                  columns={columns}
                  data={employees || []}
                  setInfo={() => {}}
                  isGlobalFilter={true}
                  isFooter={true}
                  customPageSize={8}
                  className="custom-header-css"
                  divclassName="table-responsive table-card mb-0"
                  tableclassName="align-middle table-nowrap"
                  theadclassName="table-light"
                  isSearchInput={true}
                  SearchPlaceholder="Search for employee"
                />
              ) : (
                <Loader />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default CustomTable;
