import React from "react";
import { Card, Col } from "reactstrap";
import { UpdateDonut } from "../Charts/ApexCharts/PieCharts/PieCharts";
import { CardBody, CardHeader } from "reactstrap";

const DealType = () => {
  return (
    <React.Fragment>
      <Col xxl={4}>
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0">
              <strong>Employee</strong> Department
            </h4>
          </CardHeader>
          <CardBody>
            <div>
              <UpdateDonut dataColors='["--vz-primary", "--vz-primary-rgb, 0.80", "--vz-primary-rgb, 0.60", "--vz-primary-rgb, 0.45"]' />
              <div className="d-flex align-items-start flex-wrap gap-2 justify-content-center mt-4"></div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default DealType;
