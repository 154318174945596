import React from "react";
import { Row, Col, Label, Input } from "reactstrap";

const ExperienceForm = () => {
  return (
    <form>
      <div id="newlink">
        <div id="1">
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="jobTitle" className="form-label">
                  Job Title
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="jobTitle"
                  placeholder="Job title"
                  defaultValue="Lead Designer / Developer"
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="companyName" className="form-label">
                  Company Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="companyName"
                  placeholder="Company name"
                  defaultValue="Themesbrand"
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <label htmlFor="experienceYear" className="form-label">
                  Experience Years
                </label>
                <Row>
                  {/* ... Your select dropdowns and options here ... */}
                </Row>
              </div>
            </Col>

            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="jobDescription" className="form-label">
                  Job Description
                </Label>
                {/* <textarea
                  className="form-control"
                  id="jobDescription"
                  defaultValue=""
                  rows="3"
                  placeholder="Enter description"
                >
                  You always want to make sure that your fonts work well
                  together and try to limit the number of fonts you use to
                  three or less. Experiment and play around with the fonts
                  that you already have in the software you're working with
                  reputable font websites.
                </textarea> */}
              </div>
            </Col>

            <div className="hstack gap-2 justify-content-end">
              <button className="btn btn-info">Delete</button>
            </div>
          </Row>
        </div>
      </div>
      <div id="newForm" style={{ display: "none" }}></div>

      <Col lg={12}>
        <div className="hstack gap-2">
          <button type="submit" className="btn btn-info">
            Update
          </button>
          {/* Note: Reactstrap doesn't provide a Link component.
              You can use a normal <a> tag for the link */}
          <a href="#" className="btn btn-primary">
            Add New
          </a>
        </div>
      </Col>
    </form>
  );
};

export default ExperienceForm;
