import React, { useEffect, useState, useCallback } from "react";
import SelectDDrownMenu from "./SelectDDrownMenu";
import AddAttendance from "./AddAttendance";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UpdateAttendance from "./UpdateAttendance";
import AttendanceTable from "./AttendanceTable";
import DeleteModal from "../../Components/Common/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEmployees,
  getAllAttendence,
  deleteAttendence,
} from "../../slices/thunks";
import { toast, ToastContainer } from "react-toastify";

const AttendanceModule = () => {
  const dispatch = useDispatch();
  const { employees, attendences, isAttendenceLoading } = useSelector(
    (state) => ({
      employees: state.Attendence.employees,
      attendences: state.Attendence.attendences,
      isAttendenceLoading: state.Attendence.isAttendenceLoading,
    })
  );
  const [selectedData, setSelectedData] = useState({
    center: "",
    employee: "",
  });

  // console.log(attendences)
  const getAttendenceClick = () => {
    dispatch(getAllAttendence(1));
  };
  useEffect(() => {
    if (employees && !employees.length) {
      dispatch(getAllEmployees());
    }
  }, [dispatch, employees]);

  const handleSelectionChange = useCallback((data) => {
    setSelectedData(data);
  }, []);

  const [attendance, setAttendance] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const onClickDelete = (attendance) => {
    setAttendance(attendance);
    setDeleteModal(true);
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(deleteAttendence(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });

    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };
  const handleDeleteAttendance = () => {
    if (attendance) {
      // console.log(attendance._id);
      dispatch(deleteAttendence(attendance._id));
      setDeleteModal(false);
    }
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".attendanceCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  document.title = "Attendance | HREVIS";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteAttendance}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />

        <Container fluid>
          <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "auto" }} />
          </Row>

          <SelectDDrownMenu
            employees={employees}
            onSelectionChange={handleSelectionChange}
          />
          <AddAttendance selectedData={selectedData} />
          <UpdateAttendance
            getAttendenceClick={getAttendenceClick}
            isMultiDeleteButton={isMultiDeleteButton}
            setDeleteModalMulti={setDeleteModalMulti}
          />
          <AttendanceTable
            deleteCheckbox={deleteCheckbox}
            data={attendences || []}
            isAttendenceLoading={isAttendenceLoading}
            onClickDelete={onClickDelete}
          />
        </Container>

        <ToastContainer closeButton={false} limit={1} />
      </div>
    </React.Fragment>
  );
};

export default AttendanceModule;
