import { toast } from "react-toastify";
import {
  ALL_TIMESLOTS,
  CREATE_TIMESLOT,
  DELETE_TIMESLOT,
  UPDATE_TIMESLOT,
} from "../../helpers/url_helper";

import { APIClient } from "../../helpers/api_helper";
const api = new APIClient();

const getTimeSlots = async () => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${ALL_TIMESLOTS}`
    );

    if (res.success) {
    } else {
      toast.error(res.message, { theme: "colored" });
      toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(res.message, { theme: "colored" });
  }
};

const createTimeSlot = async (obj) => {
  try {
    const id = toast.loading("Creating Designation...", { autoClose: false });

    const res = await api.create(
      `${process.env.REACT_APP_USER_URL}${CREATE_TIMESLOT}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return res;
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const onDeleteTimeSlot = async (obj) => {
  try {
    const id = toast.loading("Deleting Timeslot...", { autoClose: false });

    const res = await api.delete(
      `${process.env.REACT_APP_USER_URL}${DELETE_TIMESLOT}${obj}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return { res, obj };
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const updateTimeSlot = async (obj) => {
  try {
    const id = toast.loading("Updating Timeslot...", { autoClose: false });

    const res = await api.update(
      `${process.env.REACT_APP_USER_URL}${UPDATE_TIMESLOT}${obj._id}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return { res, obj };
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

export const services = {
  getTimeSlots,
  createTimeSlot,
  onDeleteTimeSlot,
  updateTimeSlot,
};
