import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Container,
} from "reactstrap";
import DeleteModal from "../../Components/Common/DeleteModal";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { tabs, data, permissions } from "./Dummydata";
import RoleModal from "./RoleModal";
import PermissionsModal from "./PermissionModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllParents,
  getAllroles,
  createNewRole,
  deleteRole,
  onUpdateRole,
} from "../../slices/thunks";
import RolesTable from "./RolesTable";
import { useLocation } from "react-router-dom";
import usePermissionHandler from "../../helpers/usePermissionHandler";
const index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [role, setRole] = useState(null);
  const [modalRole, setModalRole] = useState(false);
  const [modalPermission, setModalPermission] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [info, setInfo] = useState([]);
  const [verticalTab, setverticalTab] = useState(1);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionError, setPermissionError] = useState(false);
  const { roles, parents } = useSelector((state) => ({
    roles: state.Role.roles,
    parents: state.Role.parents,
  }));

  const { editPermissions, createPermissions, deletePermissions } =
    usePermissionHandler(currentRoute);

  useEffect(() => {
    if (parents && !parents.length) {
      dispatch(getAllParents());
    }
  }, [dispatch, parents]);

  useEffect(() => {
    if (roles && !roles.length) {
      dispatch(getAllroles());
    }
  }, [dispatch, roles]);

  useEffect(() => {
    setRole(roles);
  }, [roles]);
  const toggleVertical = (tab) => {
    if (verticalTab !== tab) {
      setverticalTab(tab);
    }
  };
  const toggle = useCallback(() => {
    if (modalRole) {
      setModalRole(false);
      setRole(null);
    } else {
      setModalRole(true);
    }
  }, [modalRole]);
  const togglePermission = useCallback(() => {
    if (modalPermission) {
      setModalPermission(false);
      setRole(null);
    } else {
      setModalPermission(true);
    }
  }, [modalPermission]);

  const handleDeleteRole = () => {
    if (role) {
      dispatch(deleteRole(role._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (role) => {
    setRole(role);
    setDeleteModal(true);
  };
  function handleCustomizePermissions() {
    setModalPermission(!modalPermission);
    togglePermission();
  }
  function handleCreateRole() {
    setSelectedPermissions([]);
    setModalRole(!modalRole);
    setIsEdit(false);
    toggle();
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: (role && role.type) || "",
      description: (role && role.description) || "",
      parent_permissions: (role && role.parent_permissions) || "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please Enter Role Type"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: (values) => {
      if (selectedPermissions.length === 0) {
        setPermissionError(true);
        return;
      }

      if (isEdit) {
        const updateRole = {
          _id: role ? role._id : 0,
          type: values.type,
          description: values.description,
          parent_permissions: selectedPermissions,
        };
        dispatch(onUpdateRole(updateRole));
        validation.resetForm();
        setSelectedPermissions([]);
      } else {
        const newRole = {
          type: values["type"],
          description: values["description"],
          parent_permissions: selectedPermissions,
        };

        dispatch(createNewRole(newRole));
        validation.resetForm();
        setSelectedPermissions([]);
      }
      toggle();
    },
  });
  const handleRoleClick = useCallback(
    (arg) => {
      const role = arg;
      setRole({
        _id: role._id,
        createdAt: role.createdAt,
        type: role.type,
        description: role.description,
      });
      const selectedPermissionIds = role.parent_permissions
        ?.flatMap((permissionObj) =>
          permissionObj?.permissions?.map((permission) => permission._id)
        )
        .filter((permissionId) => permissionId !== undefined);

      setSelectedPermissions(
        selectedPermissionIds ? selectedPermissionIds : role.parent_permissions
      );
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(deleteRole(element.value));
    });

    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".RoleCheckBox:checked");
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  document.title = "Roles | HREVIS";
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRole}
        onCloseClick={() => setDeleteModal(false)}
      />

      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />

      <div className="page-content">
        <ToastContainer closeButton={false} />
        <Container fluid>
          <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "auto" }} />
          </Row>
          <Row className="mt-2">
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-flex justify-content-space-between align-items-end">
                    <h4 className="mb-0 flex-grow-1">List of All Roles</h4>
                    <div className="flex-shrink-0">
                      {isMultiDeleteButton && (
                        <Button
                          className="mx-2"
                          onClick={() => {
                            setDeleteModalMulti(true);
                          }}
                        >
                          <i className="ri-delete-bin-2-line "></i>
                        </Button>
                      )}

                      {createPermissions && createPermissions.length > 0 ? (
                        <Button
                          color="primary"
                          onClick={handleCreateRole}
                          className="btn btn-primary"
                          style={{ padding: "10px 25px" }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          Create Role
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <RolesTable
                    roles={roles}
                    handleRoleClick={handleRoleClick}
                    setInfo={setInfo}
                    onClickDelete={onClickDelete}
                    deleteCheckbox={deleteCheckbox}
                    setSelectedPermissions={setSelectedPermissions}
                    editPermissions={editPermissions}
                    deletePermissions={deletePermissions}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <RoleModal
        isOpen={modalRole}
        toggle={toggle}
        isEdit={isEdit}
        validation={validation}
        handleCustomizePermissions={handleCustomizePermissions}
        setModalRole={setModalRole}
        permissionError={permissionError}
      />
      <PermissionsModal
        setPermissionError={setPermissionError}
        parents={parents}
        isOpen={modalPermission}
        toggle={togglePermission}
        isEdit={isEdit}
        tabs={tabs}
        toggleVertical={toggleVertical}
        permissions={permissions}
        setModalPermission={setModalPermission}
        setModalRole={setModalRole}
        setSelectedPermissions={setSelectedPermissions}
        selectedPermissions={selectedPermissions}
      />
    </React.Fragment>
  );
};
export default index;
