// usePermissionHandler.js
import { useEffect, useState } from "react";

const usePermissionHandler = (currentRoute) => {
  const [editPermissions, setEditPermissions] = useState([]);
  const [createPermissions, setCreatePermissions] = useState([]);
  const [deletePermissions, setDeletePermissions] = useState([]);

  useEffect(() => {
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));

    if (authUser) {
      const userPermissions =
        authUser?.user?.role?.parent_permissions[0]?.permissions;

      const permissionsMap = {
        edit: [],
        create: [],
        delete: [],
      };

      userPermissions.forEach((permission) => {
        if (permission.route === currentRoute) {
          if (permission.can.includes("edit")) {
            permissionsMap.edit.push(permission);
          }
          if (permission.can.includes("create")) {
            permissionsMap.create.push(permission);
          }
          if (permission.can.includes("delete")) {
            permissionsMap.delete.push(permission);
          }
        }
      });

      setEditPermissions(permissionsMap.edit);
      setCreatePermissions(permissionsMap.create);
      setDeletePermissions(permissionsMap.delete);
    }
  }, [currentRoute]);

  return {
    editPermissions,
    createPermissions,
    deletePermissions,
  };
};

export default usePermissionHandler;
