import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Button,
  Card,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import PromotionsTable from "./promotionsTable";
import PromotionsModal from "./PromotionsModal";
import { useLocation } from "react-router";

const index = () => {
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [info, setInfo] = useState(null);
  const promotionData = [
    {
      id: "1",
      employee: "John",
      company: "Morning",
      promotionTitle: "Senior Executive 1",
      description: "This is a test and this description is for that test",
      date: "31-01-23",
    },
    {
      id: "2",
      employee: "John",
      company: "Morning",
      promotionTitle: "Senior Executive 1",
      description: "This is a test and this description is for that test",
      date: "31-01-23",
    },
    {
      id: "3",
      employee: "John",
      company: "Morning",
      promotionTitle: "Senior Executive 1",
      description: "This is a test and this description is for that test",
      date: "31-01-23",
    },
    {
      id: "4",
      employee: "John",
      company: "Morning",
      promotionTitle: "Senior Executive 1",
      description: "This is a test and this description is for that test",
      date: "31-01-23",
    },
    {
      id: "5",
      employee: "John",
      company: "Morning",
      promotionTitle: "Senior Executive 1",
      description: "This is a test and this description is for that test",
      date: "31-01-23",
    },
  ];
  const [data, setData] = useState(promotionData);
  const updateData = (newData, isDelete) => {
    setData((prevData) => {
      if (isDelete) {
        const updatedData = prevData.filter(
          (contact) => contact.id !== newData.id
        );
        return updatedData;
      } else if (isEdit) {
        const updatedData = [...prevData];
        const indexToEdit = prevData.findIndex(
          (contact) => contact.id === newData.id
        );
        if (indexToEdit !== -1) {
          updatedData[indexToEdit] = newData;
        }
        return updatedData;
      } else {
        return [...prevData, newData];
      }
    });
  };

  const location = useLocation();
  // console.log(location, "LOCATION");

  document.title = "Promotions | HREVIS";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "auto" }} />
          </Row>
          <Card
            style={{
              width: "100%",
              margin: "auto",
              padding: "14px 30px",
              marginTop: "-20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="select"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  color="primary"
                  className="text-white"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  <i
                    className=" ri-add-fill"
                    style={{ verticalAlign: "middle" }}
                  />{" "}
                  Add Promotion
                </Button>
                <Button color="danger" className="text-white">
                  <i
                    className=" ri-indeterminate-circle-fill"
                    style={{ verticalAlign: "middle" }}
                  />{" "}
                  Bulk Delete
                </Button>
              </div>
              <div className="d-flex">
                <UncontrolledDropdown direction="start">
                  <DropdownToggle color="primary">
                    <i className=" ri-more-2-fill"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <i className=" ri-file-pdf-fill fs-4" /> <span> PDF</span>{" "}
                    </DropdownItem>
                    <DropdownItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <i className="  ri-file-text-fill fs-4" />{" "}
                      <span> File</span>{" "}
                    </DropdownItem>
                    <DropdownItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <i className=" ri-printer-fill fs-4" />{" "}
                      <span>Printer</span>{" "}
                    </DropdownItem>
                    <DropdownItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <i className=" ri-eye-fill fs-4" /> <span>Eye</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Card>
          <Row className="mt-4">
            <Col lg={12}>
              <PromotionsTable
                setIsEdit={setIsEdit}
                data={data}
                setModal={setModal}
                modal={modal}
                setInfo={setInfo}
                updateData={updateData}
              />
            </Col>
          </Row>
          {modal && (
            <PromotionsModal
              isEdit={isEdit}
              modal={modal}
              setModal={setModal}
              setInfo={setInfo}
              data={data}
              updateData={updateData}
              info={info}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default index;
