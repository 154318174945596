import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import TableContainer from "../../Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
require("flatpickr/dist/themes/dark.css");
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

function ViewAttendance() {
  const smallDivStyle = {
    fontWeight: "bold",
    textAlign: "Center",
    width: "75px",
    height: "65px",
    border: "0.5px solid black",
    marginRight: "-1px",
  };
  const iconsDiv = {
    textAlign: "Center",
    width: "33px",
    height: "33px",
    backgroundColor: "#f3f3f3",
  };

  const currentDate = new Date();

  const options = { year: "numeric", month: "long" };
  const currentMonthYear = currentDate.toLocaleString("en-US", options);

  const [selectedDate, setSelectedDate] = useState(currentMonthYear);
  const [designDataHeaders, setDesignDataHeaders] = useState([]);
  const [designData, setDesignData] = useState([]);

  const handleDateChange = (dates) => {
    if (dates.length > 0) {
      const selected = dates[0];
      const options = { year: "numeric", month: "long" };
      setSelectedDate(selected.toLocaleString("en-US", options));
    } else {
      setSelectedDate(null);
    }
  };

  function getDesignTableHeaders(monthYear) {
    if (!monthYear) {
      return [];
    }

    const [month, year] = monthYear.split(" ");
    const monthNumber = new Date(`${month} 1, ${year}`).getMonth();
    const daysInMonth = new Date(year, monthNumber + 1, 0).getDate();
    const designTableHeaders = [
      { Header: "Employee Name", accessor: "employee_name" },
    ];

    // Add days in the selected month to designTableHeaders
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, monthNumber, day);
      const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      designTableHeaders.push({
        Header: `${dayName} ${formattedDay}`,
        accessor: `${dayName.toLowerCase()}_${formattedDay}`,
      });
    }

    // Add other headers at the end
    designTableHeaders.push(
      { Header: "(%)", accessor: "percentage" },
      { Header: "W", accessor: "w" },
      { Header: "P", accessor: "p" },
      { Header: "A", accessor: "a" },
      { Header: "L", accessor: "l" }
    );

    // Generate designData for the selected month
    const daysInSelectedMonth = new Date(year, monthNumber + 1, 0).getDate();
    const designData = [];

    // Add employee_name property to rowData
    const rowData = { employee_name: "M.Uzair" };

    for (let day = 1; day <= daysInSelectedMonth; day++) {
      const date = new Date(year, monthNumber, day);
      const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      const isWeekend = dayName === "Sat" || dayName === "Sun";
      const weekendValue = isWeekend ? "W" : "";

      // Update rowData for each day
      rowData[`${dayName.toLowerCase()}_${formattedDay}`] = weekendValue;
    }

    // Add rowData to designData
    designData.push({
      ...rowData,
      percentage: "-",
      w: "5",
      p: "0",
      a: "0",
      l: "0",
    });

    return { designTableHeaders, designData };
  }

  useEffect(() => {
    const { designTableHeaders, designData } =
      getDesignTableHeaders(selectedDate);
    setDesignDataHeaders(designTableHeaders);
    setDesignData(designData);
  }, [selectedDate]);

  return (
    <>
      <Row className="mx-5 g-0" style={{ marginTop: "8rem", height: "10rem" }}>
        <Col style={{ height: "1rem" }} lg={12}>
          <Card style={{ backgroundColor: "#f3f3f3" }}>
            <CardBody>
              <h5>Add Attendance</h5>
            </CardBody>
          </Card>
        </Col>
        <Col
          style={{ marginTop: "2rem", borderTop: "2px solid #fed06c" }}
          lg={12}
        >
          <Card>
            <CardBody>
              <p style={{ marginLeft: "19rem" }}>Month *</p>
              <Col style={{ marginLeft: "19rem" }} xl={2} md={6}>
                <div style={{ width: "35rem" }} className="input-group mb-4">
                  <Flatpickr
                    placeholder="Select month"
                    className="form-control"
                    options={{
                      plugins: [
                        new monthSelectPlugin({
                          shorthand: true,

                          theme: "dark",
                        }),
                      ],
                      altFormat: "F Y",
                      dateFormat: "Y-m",
                    }}
                    value={currentMonthYear}
                    onChange={handleDateChange}
                  />
                  <span className="input-group-text" id="basic-addon1">
                    <i className="ri-calendar-2-line"></i>
                  </span>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mx-5 g-0" style={{ height: "10rem" }}>
        <Col style={{ height: "1rem" }} lg={12}>
          <Card style={{ backgroundColor: "#f3f3f3" }}>
            <CardBody>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button style={{ width: "10.5rem" }} color="dark" outline>
                  Search
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mx-5 g-0" style={{ height: "10rem", marginTop: "-78px" }}>
        <Col style={{ height: "1rem" }} lg={12}>
          <Card style={{ backgroundColor: "#f3f3f3" }}>
            <CardBody>
              <h5>Attendance Report</h5>
            </CardBody>
          </Card>
        </Col>
        <Col
          style={{ marginTop: "2rem", borderTop: "2px solid #fed06c" }}
          lg={12}
        >
          <Card>
            <CardBody>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <p className="fw-semibold">Selected Month:&nbsp;</p>
                  {selectedDate}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={smallDivStyle} className="small-div">
                    Weekends: <span className="text-success">W</span>
                  </div>
                  <div style={smallDivStyle} className="small-div">
                    Present:
                    <div
                      style={{ margin: "auto" }}
                      className="col-xl-3 col-lg-4 col-sm-6"
                    >
                      <i className="bx bx-check-circle text-success"></i>
                    </div>
                  </div>
                  <div style={smallDivStyle} className="small-div">
                    Absent:
                    <div
                      style={{ margin: "auto" }}
                      className="col-xl-3 col-lg-4 col-sm-6"
                    >
                      <i className="bx bx-x-circle text-success"></i>
                    </div>
                  </div>
                  <div style={smallDivStyle} className="small-div">
                    Holiday:
                    <div
                      style={{ margin: "auto" }}
                      className="col-xl-3 col-lg-4 col-sm-6"
                    >
                      <i className="mdi mdi-alpha-h-circle text-success"></i>
                    </div>
                  </div>
                  <div style={smallDivStyle} className="small-div">
                    Late:
                    <div
                      style={{ margin: "auto" }}
                      className="col-xl-3 col-lg-4 col-sm-6"
                    >
                      <i className="mdi mdi-clock-outline text-success"></i>
                    </div>
                  </div>
                  <div style={smallDivStyle} className="small-div">
                    Half Day:
                    <div
                      style={{ margin: "auto" }}
                      className="col-xl-3 col-lg-4 col-sm-6"
                    >
                      <i className="bx bxs-star-half text-success"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  className="icons mt-4"
                  style={{ display: "flex", gap: "4px" }}
                >
                  <div style={iconsDiv} className="iconsDiv">
                    <div
                      className="col-xl-3 col-lg-4 col-sm-6"
                      style={{
                        fontSize: "20px",
                        marginLeft: "6px",
                        marginTop: "3px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="bx bx-file-blank"></i>
                    </div>
                  </div>
                  <div style={iconsDiv} className="iconsDiv">
                    <div
                      className="col-xl-3 col-lg-4 col-sm-6"
                      style={{
                        fontSize: "20px",
                        marginLeft: "6px",
                        marginTop: "2px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="mdi mdi-file-excel"></i>
                    </div>
                  </div>
                  <div style={iconsDiv} className="iconsDiv">
                    <div
                      className="col-xl-3 col-lg-4 col-sm-6"
                      style={{
                        fontSize: "20px",
                        marginLeft: "6px",
                        marginTop: "2px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="mdi mdi-file-document"></i>
                    </div>
                  </div>
                  <div style={iconsDiv} className="iconsDiv">
                    <div
                      className="col-xl-3 col-lg-4 col-sm-6"
                      style={{
                        fontSize: "20px",
                        marginLeft: "6px",
                        marginTop: "3px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="bx bxs-file-pdf"></i>
                    </div>
                  </div>
                  <div style={iconsDiv} className="iconsDiv">
                    <div
                      className="col-xl-3 col-lg-4 col-sm-6"
                      style={{
                        fontSize: "20px",
                        marginLeft: "6px",
                        marginTop: "3px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="bx bx-printer"></i>
                    </div>
                  </div>
                </div>
                <div className="mt-4" style={{ display: "flex", gap: "0" }}>
                  <Input
                    style={{ width: "20rem" }}
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    id="labelInput"
                  />
                </div>
              </div>
              <div className="mt-2">
                <SimpleBar style={{ maxHeight: "410px" }}>
                  <TableContainer
                    columns={designDataHeaders}
                    data={designData || []}
                    setInfo={() => {}}
                    customPageSize={1}
                    className="custom-header-css"
                    divClass="table-card mb-0"
                    tableClass="align-middle table-bordered table-nowrap"
                    theadClass="table-light"
                  />
                </SimpleBar>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default ViewAttendance;
