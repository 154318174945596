import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";

import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { createAttendence } from "../../slices/thunks";
import { values } from "lodash";

const AddAttendance = ({ selectedData }) => {
  const options = { weekday: "long" };
  const currentDate = new Date();
  const [user, setUser] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      // console.log(obj)
      setUser(user);
    }
  }, []);

  const dispatch = useDispatch();
  const [modal_grid, setmodal_grid] = useState(false);
  const formik = useFormik({
    initialValues: {
      date: "",
      clockIn: "",
      clockOut: "",
    },
    validationSchema: Yup.object({
      date: Yup.string().required("Date is required"),
      clockIn: Yup.string().required("Clock In time is required"),
      clockOut: Yup.string().required("Clock Out time is required"),
    }),
    onSubmit: (values) => {
      setmodal_grid(false);

      const newAttendence = {
        center: selectedData.center,
        user_id: selectedData.employee,
        ci_time: values.clockIn,
        co_time: values.clockOut,
        date: values.date,
        day: currentDate.toLocaleDateString("en-US", options),
        markedBy: user?.user?._id,
      };
      dispatch(createAttendence(newAttendence));
      formik.resetForm();
    },
  });

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="bg-soft-light">
              <h4 className="mb-4">Add Attendance</h4>
              <Button
                color="primary"
                onClick={() => setmodal_grid(true)}
                disabled={!selectedData.center || !selectedData.employee}
              >
                <i className="las la-plus-circle"></i> Add New
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={modal_grid}
        toggle={() => {
          setmodal_grid(!modal_grid);
        }}
      >
        <ModalHeader>
          <div>Select Time</div>
          <Button
            type="button"
            onClick={() => {
              setmodal_grid(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row g-3">
              <Col xxl={6}>
                <div>
                  <label htmlFor="date" className="form-label">
                    Select Date
                  </label>
                  <Flatpickr
                    className="form-control"
                    id="datepicker-publish-input"
                    placeholder="Select Date"
                    name="date"
                    onBlur={formik.handleBlur}
                    value={formik.values.date}
                    onChange={(date, dateStr) => {
                      formik.setFieldValue("date", dateStr);
                    }}
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      mode: "single",
                      dateFormat: "d/m/y",
                      enableTime: false,
                    }}
                  />

                  {formik.touched.date && formik.errors.date && (
                    <div className="text-danger">{formik.errors.date}</div>
                  )}
                </div>
              </Col>
              <Col xxl={6}>
                <div>
                  <label htmlFor="clockIn" className="form-label">
                    Clock In
                  </label>

                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      defaultDate: "13:45",
                    }}
                    value={formik.values.clockIn}
                    onChange={(selectedDates, dateStr) => {
                      formik.setFieldValue("clockIn", dateStr);
                    }}
                  />
                  {formik.touched.clockIn && formik.errors.clockIn && (
                    <div className="text-danger">{formik.errors.clockIn}</div>
                  )}
                </div>
              </Col>
              <Col xxl={6}>
                <div>
                  <label htmlFor="clockOut" className="form-label">
                    Clock Out
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      defaultDate: "13:45",
                    }}
                    value={formik.values.clockOut}
                    onChange={(selectedDates, dateStr) => {
                      formik.setFieldValue("clockOut", dateStr);
                    }}
                  />
                  {formik.touched.clockOut && formik.errors.clockOut && (
                    <div className="text-danger">{formik.errors.clockOut}</div>
                  )}
                </div>
              </Col>
              <div className="col-lg-12">
                <div className="hstack gap-2 justify-content-end">
                  <Button color="light" onClick={() => setmodal_grid(false)}>
                    Close
                  </Button>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddAttendance;
