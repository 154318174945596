import axios from "axios";
import {
  GET_EMPLOYEES,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAIL,
  GET_LEAVE_SUMMARY_DATA,
  GET_BIRTHDAY_LIST,
  GET_WORK_ANNIVERSARY,
} from "./actionType";

// common success
export const employeesApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const employeesApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getEmployees = (token) => ({
  type: GET_EMPLOYEES,
  payload: { token },
});

export const updateEmployee = (employee) => ({
  type: UPDATE_EMPLOYEE,
  payload: employee,
});

export const updateEmployeeSuccess = (employee) => ({
  type: UPDATE_EMPLOYEE_SUCCESS,
  payload: employee,
});

export const updateEmployeeFail = (error) => ({
  type: UPDATE_EMPLOYEE_FAIL,
  payload: error,
});

export const addNewEmployee = (employee) => ({
  type: ADD_EMPLOYEE_SUCCESS,
  payload: employee,
});

export const addEmployeeSuccess = (employee) => ({
  type: ADD_EMPLOYEE_SUCCESS,
  payload: employee,
});

export const addEmployeeFail = (error) => ({
  type: ADD_EMPLOYEE_FAIL,
  payload: error,
});

export const deleteEmployee = (employee) => ({
  type: DELETE_EMPLOYEE,
  payload: employee,
});

export const deleteEmployeeSuccess = (employee) => ({
  type: DELETE_EMPLOYEE_SUCCESS,
  payload: employee,
});

export const deleteEmployeeFail = (error) => ({
  type: DELETE_EMPLOYEE_FAIL,
  payload: error,
});

// ATTENDENCE RECORD
export const getAttendenceRecord = async (userId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_USER_URL}/webevis/GetAttendanceHistory/646283cafb127a0d276feb27`,

      {
        headers: {
          Accept: "aplication/json",
        },
      }
    );
    return response;
  } catch (error) {}
};

export const getAttendenceSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// LEAVE SUMMARY
export const LeaveSummaryData = (params) => ({
  type: GET_LEAVE_SUMMARY_DATA,
  payload: { params },
});
export const LeaveSummarySuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// BIRTHDAY LIST
export const BirthdayList = () => ({
  type: GET_BIRTHDAY_LIST,
});
export const BirthdayListSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// WORK ANNIVERSARY
export const workAnniversary = () => ({
  type: GET_WORK_ANNIVERSARY,
});

export const workAnniversarySuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
