import React, { useMemo, useCallback } from "react";
import TableContainer from "../../Components/Common/TableContainer";
import { Link } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
const RolesTable = ({
  roles,
  handleRoleClick,
  onClickDelete,
  deleteCheckbox,
  setSelectedPermissions,
  editPermissions,
  deletePermissions,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="RoleCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        filterable: false,
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item" title="View">
                <Link
                  to="#"
                  onClick={() => {
                    const roleData = cellProps.row.original;
                    //  setInfo(roleData);
                  }}
                >
                  <i className="ri-eye-fill align-bottom text-muted"></i>
                </Link>
              </li>
              {editPermissions && editPermissions?.length > 0 ? (
                <li className="list-inline-item" title="Edit">
                  <Link
                    className="edit-item-btn"
                    to="#"
                    onClick={() => {
                      const roleData = cellProps.row.original;
                      setSelectedPermissions([]);
                      handleRoleClick(roleData);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom text-muted"></i>
                  </Link>
                </li>
              ) : (
                ""
              )}

              {deletePermissions && deletePermissions?.length > 0 ? (
                <li className="list-inline-item" title="Delete">
                  <Link
                    className="remove-item-btn"
                    onClick={() => {
                      const roleData = cellProps.row.original;
                      onClickDelete(roleData);
                    }}
                    to="#"
                  >
                    <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          );
        },
      },
    ],
    [handleRoleClick, editPermissions, deletePermissions]
  );

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".RoleCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  return (
    <>
      <div>
        {roles && roles?.length ? (
          <TableContainer
            columns={columns}
            data={roles || []}
            setInfo={() => {}}
            isGlobalFilter={true}
            isFooter={true}
            customPageSize={10}
            className="custom-header-css"
            divClass="table-responsive table-card mb-0"
            tableClass="align-middle table-nowrap"
            theadClass="table-light"
            isSearchInput={true}
            SearchPlaceholder="Search by type"
            isRoleListFilter={true}
          />
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default RolesTable;
