import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import EditImage from "./EditImage";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { onUpdateEmployee } from "../../../slices/thunks";
//import images
import progileBg from "../../../assets/images/profile-bg.jpg";
import EditProfileTabContent from "./EditProfileTabContent";
const Settings = () => {
  const dispatch = useDispatch();
  document.title = "Profile Settings | HREvis";
  const [activeTab, setActiveTab] = useState("1");
  const [user, setUser] = useState("");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      // console.log(obj)
      setUser(user);
    }
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      photo: user?.user?.photo || "",
      fullName: user?.user?.fullName || "",
      email: user?.user?.email || "",
      DOB: user?.user?.DOB || "",
      joining_date: user?.user?.joining_date || "",
      center: user?.user?.center || "",
      mobile: user?.user?.mobile || "",
      CNIC: user?.user?.CNIC || "",
      gender: user?.user?.gender || "",
      current_address: user?.user?.current_address || "",
      permanent_address: user?.user?.permanent_address || "",
      time_slot:
        `${user?.user?.time_slot.slot_Name} (${user?.user?.time_slot.checkInTime} - ${user?.user?.time_slot.checkOutTime} )` ||
        "",
      supervisor: user?.user?.supervisor?.fullName || " ",
      role: user?.user?.role?.type || "",
      designation: user?.user?.designation?.title || "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Please Enter Email"),
      DOB: Yup.string().required("Please Enter Date Of Birth"),
      joining_date: Yup.string().required("Please Enter Date Of Joining"),
      // shift: Yup.string().required("Please Enter Shift"),
      center: Yup.string().required("Please Enter Center"),
      designation: Yup.string().required("Please Enter Designation"),
      mobile: Yup.string().required("Please Enter Mobile Number"),
      CNIC: Yup.string().required("Please Enter CNIC Number"),
      gender: Yup.string().required("Please Select Gender"),
      current_address: Yup.string().required("Please Enter Current Address"),
      permanent_address: Yup.string().required(
        "Please Enter Permanent Address"
      ),
      time_slot: Yup.string().required("Please Enter Time Slot"),
      supervisor: Yup.string().required("Please Select Supervisor"),
      role: Yup.string().required("Please Select Role"),
    }),
    onSubmit: (values) => {
      const updateEmployee = {
        _id: user?.user?._id,
        photo: values["photo"],
        fullName: values["fullName"],
        email: values["email"],
        DOB: values["DOB"],
        joining_date: values["joining_date"],
        mobile: values["mobile"],
        CNIC: values["CNIC"],
        gender: values["gender"],
        current_address: values["current_address"],
        permanent_address: values["permanent_address"],
        username: values["email"],
      };

      dispatch(onUpdateEmployee(updateEmployee));

      const storedAuthUserString = sessionStorage.getItem("authUser");
      const storedAuthUser = JSON.parse(storedAuthUserString);
      storedAuthUser.user.photo = values["photo"];
      storedAuthUser.user.fullName = values["fullName"];
      storedAuthUser.user.email = values["email"];
      storedAuthUser.user.DOB = values["DOB"];
      storedAuthUser.user.joining_date = values["joining_date"];
      storedAuthUser.user.mobile = values["mobile"];
      storedAuthUser.user.CNIC = values["CNIC"];
      storedAuthUser.user.gender = values["gender"];
      storedAuthUser.user.current_address = values["current_address"];
      storedAuthUser.user.permanent_address = values["permanent_address"];
      storedAuthUser.user.username = values["email"];

      sessionStorage.setItem("authUser", JSON.stringify(storedAuthUser));
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer closeButton={false} />
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                    <Input
                      id="profile-foreground-img-file-input"
                      type="file"
                      className="profile-foreground-img-file-input"
                    />
                    <Label
                      htmlFor="profile-foreground-img-file-input"
                      className="profile-photo-edit btn btn-light"
                    >
                      <i className="ri-image-edit-line align-bottom me-1"></i>{" "}
                      Change Cover
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <EditImage user={user} validation={validation} />
                    <h5 className="fs-16 mb-1">{user?.user?.fullName}</h5>
                    <p className="text-muted mb-0">
                      {user?.user?.designation?.title}
                    </p>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-5">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">Complete Your Profile</h5>
                    </div>
                    <div className="flex-shrink-0">
                      <Link
                        to="#"
                        className="badge bg-light text-primary fs-12"
                      >
                        <i className="ri-edit-box-line align-bottom me-1"></i>{" "}
                        Edit
                      </Link>
                    </div>
                  </div>
                  <div className="progress animated-progress custom-progress progress-label">
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{ width: "30%" }}
                      aria-valuenow="30"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="label">30%</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-4">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">Portfolio</h5>
                    </div>
                    <div className="flex-shrink-0">
                      <Link
                        to="#"
                        className="badge bg-light text-primary fs-12"
                      >
                        <i className="ri-add-fill align-bottom me-1"></i> Add
                      </Link>
                    </div>
                  </div>
                  <div className="mb-3 d-flex">
                    <div className="avatar-xs d-block flex-shrink-0 me-3">
                      <span className="avatar-title rounded-circle fs-16 bg-dark text-light">
                        <i className="ri-github-fill"></i>
                      </span>
                    </div>
                    <Input
                      type="email"
                      className="form-control"
                      id="gitUsername"
                      placeholder="Username"
                      defaultValue="@daveadame"
                    />
                  </div>
                  <div className="mb-3 d-flex">
                    <div className="avatar-xs d-block flex-shrink-0 me-3">
                      <span className="avatar-title rounded-circle fs-16 bg-primary">
                        <i className="ri-global-fill"></i>
                      </span>
                    </div>
                    <Input
                      type="text"
                      className="form-control"
                      id="websiteInput"
                      placeholder="www.example.com"
                      defaultValue="www.velzon.com"
                    />
                  </div>
                  <div className="mb-3 d-flex">
                    <div className="avatar-xs d-block flex-shrink-0 me-3">
                      <span className="avatar-title rounded-circle fs-16 bg-success">
                        <i className="ri-dribbble-fill"></i>
                      </span>
                    </div>
                    <Input
                      type="text"
                      className="form-control"
                      id="dribbleName"
                      placeholder="Username"
                      defaultValue="@dave_adame"
                    />
                  </div>
                  <div className="d-flex">
                    <div className="avatar-xs d-block flex-shrink-0 me-3">
                      <span className="avatar-title rounded-circle fs-16 bg-danger">
                        <i className="ri-pinterest-fill"></i>
                      </span>
                    </div>
                    <Input
                      type="text"
                      className="form-control"
                      id="pinterestName"
                      placeholder="Username"
                      defaultValue="Advance Dave"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <EditProfileTabContent
              activeTab={activeTab}
              tabChange={tabChange}
              validation={validation}
              //  timeslot={timeslot}
              // employees={employees}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
