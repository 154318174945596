import { createSlice } from "@reduxjs/toolkit";
import {
  getAllTimeSlots,
  createNewTimeSlot,
  deleteTimeSlot,
  onUpdateTimeSlot,
} from "./thunk";

export const initialState = {
  timeslots: [],
  isLoading: false,
  error: "",
};

const TimeslotSlice = createSlice({
  name: "timeslot",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllTimeSlots.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllTimeSlots.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timeslots = action.payload?.data;
      })
      .addCase(getAllTimeSlots.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNewTimeSlot.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNewTimeSlot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timeslots = [...state.timeslots, action.payload.data];
      })
      .addCase(createNewTimeSlot.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteTimeSlot.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteTimeSlot.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.timeslots = state.timeslots.filter(
          (designation) => designation._id.toString() !== obj.toString()
        );
      })
      .addCase(deleteTimeSlot.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdateTimeSlot.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateTimeSlot.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.timeslots = state.timeslots.map((timeslot) =>
          timeslot._id.toString() === obj._id.toString()
            ? { ...timeslot, ...obj }
            : timeslot
        );
      })
      .addCase(onUpdateTimeSlot.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default TimeslotSlice.reducer;
