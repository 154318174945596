import React, { useCallback } from "react";
import {
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Button,
} from "reactstrap";
import { setIn, useFormik } from "formik";
import * as Yup from "yup";

const PromotionsModal = ({
  modal,
  setModal,
  isEdit,
  updateData,
  info,
  setInfo,
}) => {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (info && info.id) || 0,
      employee: (info && info.employee) || "",
      company: (info && info.company) || "",
      promotionTitle: (info && info.promotionTitle) || "",
      description: (info && info.description) || "",
      date: (info && info.date) || "",
    },
    validationSchema: Yup.object({
      promotionTitle: Yup.string().required(
        "Please write a title for your promotion"
      ),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateShifts = {
          id: values.id,
          employee: values.employee,
          company: values.company,
          promotionTitle: values.promotionTitle,
          description: values.description,
          date: values.date,
        };
        updateData(updateShifts);
        validation.resetForm();
      } else {
        const newContact = {
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          employee: values["employee"],
          company: values["company"],
          promotionTitle: values["promotionTitle"],
          description: values["description"],
          date: values["date"],
        };
        updateData(newContact);
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
    validation.resetForm();
    setInfo(null);
  }, [modal]);
  return (
    <div>
      <Modal size="lg" id="showModal" isOpen={modal} toggle={toggle} centered>
        <div>
          <ModalHeader toggle={toggle}>
            <h4 className="text-dark fw-semi-bold">Add Promotion</h4>
          </ModalHeader>

          <Form
            className="tablelist-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <ModalBody>
              <Input type="hidden" id="id-field" />
              <Row>
                <Row className="mt-5">
                  <Row className="mt-2">
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor="company"
                          className="form-label text-muted fs-5"
                        >
                          Company
                        </Label>
                        <select
                          className="form-select form-select-lg"
                          name="company"
                          aria-label=".form-select-lg example"
                          value={validation.values.company || ""}
                          id="company"
                          onChange={(e) => {
                            validation.setFieldValue("company", e.target.value);
                          }}
                        >
                          <option value="">Select Company..</option>
                          <option value="WebEvis">WebEvis</option>
                          <option value="Plastik">Plastik</option>
                          <option value="MGM">MGM</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor="employee"
                          className="form-label text-muted fs-5"
                        >
                          Employee
                        </Label>
                        <select
                          className="form-select form-select-lg"
                          name="employee"
                          aria-label=".form-select-lg example"
                          value={validation.values.employee || ""}
                          id="employee"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "employee",
                              e.target.value
                            );
                          }}
                        >
                          <option value="">Select Company..</option>
                          <option value="WebEvis">WebEvis</option>
                          <option value="Plastik">Plastik</option>
                          <option value="MGM">MGM</option>
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col lg={6}>
                      <Label
                        htmlFor="promotionTitle"
                        className="form-label text-muted fs-5"
                      >
                        Title
                      </Label>

                      <Input
                        name="promotionTitle"
                        id="promotionTitle"
                        className="form-control form-control-lg"
                        placeholder="In Time"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.promotionTitle || ""}
                      />
                      {validation.touched.promotionTitle &&
                      validation.errors.promotionTitle ? (
                        <div>
                          <p className="text-danger">
                            {validation.errors.promotionTitle}
                          </p>
                        </div>
                      ) : null}
                    </Col>
                    <Col lg={6}>
                      <Label
                        htmlFor="date"
                        className="form-label text-muted fs-5"
                      >
                        Promotion Date
                      </Label>

                      <Input
                        name="date"
                        id="date"
                        className="form-control form-control-lg"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.date || ""}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={6}>
                      <Label
                        htmlFor="description"
                        className="form-label text-muted fs-5"
                      >
                        Description
                      </Label>

                      <textarea
                        name="description"
                        id="description"
                        className="form-control form-control-lg"
                        placeholder="description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                      />
                    </Col>
                  </Row>
                </Row>
              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <div className="w-50 d-grid ">
                <Button type="submit" color="primary text-white fs-4">
                  Add
                </Button>
              </div>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default PromotionsModal;
