import axios from "axios";
import { USER_LOGIN } from "../../../helpers/url_helper";
import { toast } from "react-toastify";

const login = async (username, password) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_USER_URL}${USER_LOGIN}`,
      {
        username,
        password,
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    // toast.error(error.data.message, { theme: "colored" });
    return error;
  }
};

export const service = {
  login,
};
