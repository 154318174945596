import { file } from "@babel/types";
import React, { useState } from "react";
import { Card, Row, Button, CardBody, Col } from "reactstrap";

const ImportEmployees = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const downloadSampleFile = () => {};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "text/csv" || fileType === "application/vnd.ms-excel") {
        setSelectedFile(file);
      } else {
        alert("Please select a CSV or EXCEL file.");
      }
    } else {
      setSelectedFile(null);
    }
  };

  const handleSaveClick = () => {
    if (selectedFile) {
      console.log("selected File: ", selectedFile);
    } else {
      alert("Please select a file first.");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Card className="m-2">
          <CardBody>
            <Row>
              <Col>
                <h3 className="mb-4">Import EXCEL/CSV file only</h3>
                <Button
                  color="primary"
                  onClick={downloadSampleFile}
                  className="btn btn-primary w-auto mb-2"
                >
                  <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                  Download Sample File
                </Button>
                <p>
                  The first line in downloaded sample file should remain as it
                  is. Please do not change the order of columns in file.
                </p>
                <p>
                  The correct column order is (First Name, Last Name, Staff Id,
                  Username, Email, Password, Joining Date, Gender, Date of
                  Birth, Contact Number, Address, City, Zip, County, Attendance
                  Type, Company Name, Department Name, Designation Name, Shift
                  Name, Role Name).
                </p>
                <ul>
                  <li>Date format should be (According to general settings)</li>
                  <li>
                    Company, Department, Designation, Shift and Role name must
                    be matched with your existing data.
                  </li>
                  <li>Gender must be Male / Female / Other.</li>
                  <li>Attendance Type must be general / ip_based.</li>
                  <li>
                    You must follow the file, otherwise you will get an error
                    while importing the file.
                  </li>
                </ul>
                <label>Upload File</label>
                <input
                  type="file"
                  className="d-block"
                  accept=".csv, application/vnd.ms-excel"
                  onChange={handleFileChange}
                />
                <span className="d-block mb-3">
                  Please select excel/csv file (allowed file size 2MB)
                </span>
                <Button
                  color="primary"
                  onClick={handleSaveClick}
                  className="btn btn-primary w-auto mb-2"
                  disabled={!selectedFile}
                >
                  <i className="ri-checkbox-line align-bottom me-1"></i> Save
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ImportEmployees;
