import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [myRoutes, setMyRoutes] = useState("");
  const [isDashboard, setIsDashboard] = useState(false);
  const [isViewAttendance, setIsViewAttendance] = useState(false);
  const [isTimesheets, setIsTimesheets] = useState(false);
  const [isHR, setIsHR] = useState(false);
  const [isRole, setIsRole] = useState(false);
  const [isAttandanceDashboard, setIsAttandanceDashboard] = useState(false);
  const [isLeaveDashboard, setIsLeaveDashboard] = useState(false);
  const [isEmployeesDashboard, setIsEmployeesDashboard] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isHomepage, setisHomepage] = useState(false);
  const [isDesignation, setisDesignation] = useState(false);
  localStorage.setItem("iscurrentState", iscurrentState);
  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }
  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Role") {
      setIsRole(false);
    }
  }, [history]);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const user = JSON.parse(sessionStorage.getItem("authUser"));
      setMyRoutes(user?.user?.role?.parent_permissions[0]?.permissions);
    }
  }, []);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },

    {
      id: "homepage",
      label: "Homepage",
      icon: "ri-home-2-fill",
      link: "/#",
      link: "/homepage",
      stateVariables: isHomepage, // Different state variable
      click: function (e) {
        e.preventDefault();
        setisHomepage(!isHomepage); // Different state setter function
        setIscurrentState("Homepage"); // Different current state
        updateIconSidebar(e);
      },
    },

    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-fill",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "role&permission",
      label: "Roles And Permissions",
      icon: " ri-team-fill",
      link: "/#",
      stateVariables: isRole,
      disable: false,
      click: function (e) {
        e.preventDefault();
        setIsRole(!isRole);
        setIscurrentState("Role");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "role",
          label: "Role",
          link: "/roles",
          parentId: "role&permission",
        },
        {
          id: "permission",
          label: "Permissions",
          link: "/permissions",
          parentId: "role&permission",
        },
      ],
    },
    {
      id: "users",
      label: "Employees",
      icon: " ri-team-fill",
      link: "/#",
      stateVariables: isEmployeesDashboard,
      disable: false,
      click: function (e) {
        e.preventDefault();
        setIsEmployeesDashboard(!isEmployeesDashboard);
        setIscurrentState("Employees");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "employees",
          label: "All Employee",
          link: "/employee",
          parentId: "users",
        },
        {
          id: "importemployees",
          label: "Import Employees",
          link: "/importemployees",
          parentId: "users",
        },
        {
          id: "assignrole",
          label: "Assign Role",
          link: "/assignrole",
          parentId: "users",
        },
      ],
    },
    {
      id: "timesheets",
      label: "Timesheets",
      icon: "ri-time-line",
      link: "/#",
      disable: false,
      stateVariables: isTimesheets, // Different state variable
      click: function (e) {
        e.preventDefault();
        setIsTimesheets(!isTimesheets); // Different state setter function
        setIscurrentState("Timesheets");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "alltimesheet",
          label: "Add/Update Shifts",
          link: "/alltimesheets",
          disable: true,
          parentId: "timesheets",
        },
        {
          id: "holidaysheet",
          label: "Add/Update Holiday",
          link: "/holidaysheet",
          disable: true,
          parentId: "timesheets",
        },
        {
          id: "viewattendance",
          label: "View Attendance",
          link: "/view-attendance",
          disable: true,
          parentId: "timesheets",
        },
        {
          id: "4",
          label: "Attandance",
          link: "/attendance",
          disable: true,
          parentId: "timesheets",
        },
      ],
    },

    {
      id: "HR",
      label: "Core HR",
      icon: "ri-time-line",
      link: "/#",
      disable: false,
      stateVariables: isHR, // Different state variable
      click: function (e) {
        e.preventDefault();
        setIsHR(!isHR); // Different state setter function
        setIscurrentState("Core HR"); // Different current state
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "1",
          label: "Promotions",
          link: "/promotions",
          parentId: "HR",
        },
      ],
    },
    {
      id: "designation",
      label: "Designation",
      icon: "ri-home-2-fill",
      link: "/#",
      link: "/designation",
      stateVariables: isDesignation, // Different state variable
      click: function (e) {
        e.preventDefault();
        setisDesignation(!isDesignation); // Different state setter function
        setIscurrentState("Designation"); // Different current state
        updateIconSidebar(e);
      },
    },
  ];

  const filterMenuAndSubItems = (items, matchingRoutes) => {
    if (!items) return [];

    return items
      .map((item) => {
        if (item.subItems) {
          const filteredSubItems = filterMenuAndSubItems(
            item.subItems,
            matchingRoutes
          );

          item.subItems = filteredSubItems.filter((subItem) =>
            matchingRoutes.some((route) => subItem.link?.includes(route))
          );

          return item.subItems.length > 0 ? item : null;
        }

        return matchingRoutes.some((route) => item.link?.includes(route))
          ? item
          : null;
      })
      .filter(Boolean);
  };

  const matchingRoutes =
    (myRoutes &&
      myRoutes
        ?.filter((myroute) => myroute.can.includes("view"))
        .map((myroute) => myroute.route)) ||
    [];
  const filteredItems = filterMenuAndSubItems(menuItems, matchingRoutes);
  const staticValue = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-fill",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
  ];
  const combinedItems = [...staticValue, ...filteredItems];

  return <React.Fragment>{combinedItems}</React.Fragment>;
};
export default Navdata;
