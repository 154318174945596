import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//Import Icons

import {
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Flatpickr from "react-flatpickr";
import listPlugin from "@fullcalendar/list";

const Calender = () => {
  // document.title = "Calendar | Velzon - React Admin & Dashboard Template";
  // Set initial states for events, modal, selectedDay, selectedNewDay, isEdit, and isEditButton
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [modal, setModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedNewDay, setSelectedNewDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(true);

  // useFormik hook for form validation and handling form submission

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.className) || "",
      location: (event && event.location) || "",
      description: (event && event.description) || "",
      defaultDate: (event && event.start) || "",
      datetag: (event && event.start) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: (values) => {
      var updatedDay = "";
      if (selectedNewDay) {
        updatedDay = new Date(selectedNewDay[1]);
        updatedDay.setDate(updatedDay.getDate() + 1);
      }

      if (isEdit) {
        // Update existing event
        const updatedEvent = {
          id: event.id,
          title: values.title,
          className: values.category,
          start: selectedNewDay ? selectedNewDay[0] : event.start,
          end: selectedNewDay ? updatedDay : event.end,
          location: values.location,
          description: values.description,
        };
        setEvents((prevEvents) =>
          prevEvents.map((prevEvent) =>
            prevEvent.id === updatedEvent.id ? updatedEvent : prevEvent
          )
        );
      } else {
        // Add a new event
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedNewDay[0] : new Date(),
          end: selectedDay ? updatedDay : new Date(),
          className: values.category,
          location: values["location"],
          description: values["description"],
        };
        setEvents((prevEvents) => [...prevEvents, newEvent]);
      }
      validation.resetForm();
      setSelectedDay(null);
      setSelectedNewDay(null);
      toggle();
    },
  });

  // Function to toggle the modal for adding/editing events
  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
      setIsEdit(false);
      setIsEditButton(true);
    } else {
      setModal(true);
    }
  };

  /**
   * Handling date click on calendar
   */

  const handleDateClick = (arg) => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );

    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedNewDay(date);
    setSelectedDay(modifiedData);
    toggle();
  };

  const str_dt = function formatDate(date) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date),
      month = "" + monthNames[d.getMonth()],
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day + " " + month, year].join(",");
  };

  const date_r = function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg) => {
    const event = arg.event;

    const st_date = event.start;
    const ed_date = event.end;
    const r_date =
      ed_date == null
        ? str_dt(st_date)
        : str_dt(st_date) + " to " + str_dt(ed_date);
    const er_date =
      ed_date == null
        ? date_r(st_date)
        : date_r(st_date) + " to " + date_r(ed_date);

    setEvent({
      id: event.id,
      title: event.title,
      start: event.start,
      end: event.end,
      className: event.classNames,
      category: event.classNames[0],
      location: event._def.extendedProps.location,
      description: event._def.extendedProps.description,
      defaultDate: er_date,
      datetag: r_date,
    });

    setIsEdit(true);
    setIsEditButton(false);
    toggle();
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Col xs={12}>
            <Row>
              <Col xl={3}>
                <Card className="card-h-100">
                  <CardBody>
                    <button
                      className="btn btn-primary w-100"
                      id="btn-new-event"
                      onClick={toggle}
                    >
                      <i className="mdi mdi-plus"></i> Create New Event
                    </button>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={9}>
                <Card className="card-h-100">
                  <CardBody>
                    <FullCalendar
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                        listPlugin,
                      ]}
                      initialView="dayGridMonth"
                      slotDuration={"00:15:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                      }}
                      events={events}
                      editable={true}
                      droppable={true}
                      selectable={true}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={modal} id="event-modal" centered>
              <ModalHeader
                toggle={toggle}
                tag="h5"
                className="p-3 bg-soft-info modal-title"
              >
                {!!isEdit ? "Edit Event" : "Add Event"}
              </ModalHeader>
              <ModalBody>
                <Form
                  className={
                    !!isEdit
                      ? "needs-validation view-event"
                      : "needs-validation"
                  }
                  name="event-form"
                  id="form-event"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {!!isEdit ? (
                    <div className="text-end">
                      <Link
                        to="#"
                        className="btn btn-sm btn-soft-primary"
                        id="edit-event-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          submitOtherEvent();
                          return false;
                        }}
                      >
                        Edit
                      </Link>
                    </div>
                  ) : null}

                  <Row className="event-form">
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Type</Label>
                        <Input
                          className={
                            !!isEdit
                              ? "form-select d-none"
                              : "form-select d-block"
                          }
                          name="category"
                          id="event-category"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category || ""}
                        >
                          <option value="bg-soft-danger">Danger</option>
                          <option value="bg-soft-success">Success</option>
                          <option value="bg-soft-primary">Primary</option>
                          <option value="bg-soft-info">Info</option>
                          <option value="bg-soft-dark">Dark</option>
                          <option value="bg-soft-warning">Warning</option>
                        </Input>
                        {validation.touched.category &&
                        validation.errors.category ? (
                          <FormFeedback type="invalid">
                            {validation.errors.category}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Event Name</Label>
                        <Input
                          className={
                            !!isEdit
                              ? "form-control d-none"
                              : "form-control d-block"
                          }
                          placeholder="Enter event name"
                          type="text"
                          name="title"
                          id="event-title"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ""}
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label>Event Date</Label>
                        <div
                          className={
                            !!isEdit ? "input-group d-none" : "input-group"
                          }
                        >
                          <Flatpickr
                            className="form-control"
                            id="event-start-date"
                            name="defaultDate"
                            placeholder="Select Date"
                            value={validation.values.defaultDate || ""}
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                            }}
                            onChange={(date) => {
                              setSelectedNewDay(date);
                            }}
                          />
                          <span className="input-group-text">
                            <i className="ri-calendar-event-line"></i>
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="event-location">Location</Label>
                        <div>
                          <Input
                            type="text"
                            className={
                              !!isEdit
                                ? "form-control d-none"
                                : "form-control d-block"
                            }
                            name="location"
                            id="event-location"
                            placeholder="Event location"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.location || "No Location"}
                          />
                          {validation.touched.location &&
                          validation.errors.location ? (
                            <FormFeedback type="invalid">
                              {validation.errors.location}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Description</Label>
                        <textarea
                          className={
                            !!isEdit
                              ? "form-control d-none"
                              : "form-control d-block"
                          }
                          id="event-description"
                          name="description"
                          placeholder="Enter a description"
                          rows="3"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            validation.values.description || "No Description"
                          }
                        ></textarea>
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <div className="hstack gap-2 justify-content-end">
                    {isEditButton && (
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="btn-save-event"
                      >
                        {!!isEdit ? "Edit Event" : "Add Event"}
                      </button>
                    )}
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Calender;
