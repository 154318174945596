import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_ALL_ATTENDENCE,
  CREATE_ATTENDENCE,
  DELETE_ATTENDENCE,
  UPDATE_ATTENDENCE,
  ALL_EMPLOYEE,
} from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
const api = new APIClient();

const getAttendence = async (page) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${GET_ALL_ATTENDENCE}/page/${page}`
    );
    if (res.success) {
      return { data: res.data, page };
    } else {
      toast.error(res.message, { theme: "colored" });
    }
    return { data: res.data, page };
  } catch (error) {
    toast.error(res.message, { theme: "colored" });
  }
};

const createAttendence = async (obj) => {
  try {
    const id = toast.loading("Creating attendance...", { autoClose: false });

    const res = await api.create(
      `${process.env.REACT_APP_USER_URL}${CREATE_ATTENDENCE}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return res;
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const ondeleteAttendence = async (obj) => {
  try {
    const id = toast.loading("Please wait...");

    const res = await api.delete(
      `${process.env.REACT_APP_USER_URL}${DELETE_ATTENDENCE}${obj}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return { res, obj };
  } catch (error) {
    toast.update(id, {
      render: error.message,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const updateAttendence = async (obj) => {
  try {
    const id = toast.loading("Updating attendance...", { autoClose: false });

    const res = await api.put(
      `${process.env.REACT_APP_USER_URL}${UPDATE_ATTENDENCE}${obj._id}`,
      obj
    );

    if (res.success) {
      toast.update(id, {
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(id, {
        render: res.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }

    return { res, obj };
  } catch (error) {
    console.log(error);
    toast.update(id, {
      render: "An error occurred",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  }
};

const getEmployees = async () => {
  try {
    const res = api.get(`${process.env.REACT_APP_USER_URL}${ALL_EMPLOYEE}`);
    if (res.success) {
      return res?.data;
    } else {
      toast.error(res.message, { theme: "colored" });
      return res;
    }
  } catch (error) {
    //console.log(error, "getEmployees");
    toast.error(res.message, { theme: "colored" });
  }
};

export const services = {
  createAttendence,
  getAttendence,
  ondeleteAttendence,
  ondeleteAttendence,
  updateAttendence,
  getEmployees,
};
