import { createSlice } from "@reduxjs/toolkit";

import {
  getAllroles,
  getAllParents,
  createNewRole,
  deleteRole,
  onUpdateRole,
} from "./thunk";
export const initialState = {
  roles: [],
  parents: [],
  isLoading: false,
  error: "",
};

const roleSlice = createSlice({
  name: "role",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllroles.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllroles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.roles = action.payload?.data;
      })
      .addCase(getAllroles.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllParents.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllParents.fulfilled, (state, action) => {
        state.isLoading = false;
        const uniqueParents = {};
        if (action.payload && action.payload.data) {
          action.payload.data.forEach((entry) => {
            const firstWord = entry.can.split(".")[0];
            if (!uniqueParents[firstWord]) {
              uniqueParents[firstWord] = [];
            }
            uniqueParents[firstWord].push(entry);
          });
          state.parents = Object.values(uniqueParents);
        }
      })
      .addCase(getAllParents.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNewRole.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNewRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.roles = [...state.roles, action.payload.data];
      })
      .addCase(createNewRole.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteRole.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.roles = state.roles.filter(
          (role) => role._id.toString() !== obj.toString()
        );
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdateRole.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateRole.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.roles = state.roles.map((role) =>
          role._id.toString() === res.data._id.toString()
            ? { ...role, ...res.data }
            : role
        );
      })
      .addCase(onUpdateRole.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default roleSlice.reducer;
