import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import ShiftsTable from "./shiftsTable";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTimeSlots,
  createNewTimeSlot,
  deleteTimeSlot,
  onUpdateTimeSlot,
} from "../../../slices/timeslot/thunk";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../Components/Common/DeleteModal";
const index = () => {
  const dispatch = useDispatch();
  const [timeslot, setTimeslot] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { timeslots } = useSelector((state) => ({
    timeslots: state.Timeslot.timeslots,
  }));

  useEffect(() => {
    if (timeslots && !timeslots.length) {
      dispatch(getAllTimeSlots());
    }
  }, [dispatch, timeslots]);

  useEffect(() => {
    setTimeslot(timeslots);
  }, [timeslots]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTimeslot(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      slot_Name: (timeslot && timeslot.slot_Name) || "",
      checkIn_time: (timeslot && timeslot.checkInTime) || "",
      checkOut_time: (timeslot && timeslot.checkOutTime) || "",
      checkIn_buffer: (timeslot && timeslot.checkIn_buffer) || "",
    },
    validationSchema: Yup.object({
      slot_Name: Yup.string().required("Please Select a Shift"),
      checkIn_time: Yup.string().required("Please Select Check-in Time"),
      checkOut_time: Yup.string().required("Please Select Check-out Time"),
      checkIn_buffer: Yup.string().required("Please Select Check-in Buffer"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateShift = {
          id: timeslot ? timeslot._id : 0,
          slot_Name: values.slot_Name,
          checkInTime: values.checkInTime,
          checkOutTime: values.checkOutTime,
          checkIn_buffer: values.checkIn_buffer,
        };
        // dispatch(onUpdateTimeSlot(updateDesignation));
        validation.resetForm();
      } else {
        const newShift = {
          slot_Name: values["slot_Name"],
          checkInTime: values["checkInTime"],
          checkOutTime: values["checkOutTime"],
          checkIn_buffer: values["checkIn_buffer"],
        };
        dispatch(createNewTimeSlot(newShift));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleTimeslotClick = useCallback(
    (arg) => {
      const timeslot = arg;
      setTimeslot({
        _id: timeslot._id,
        slot_Name: timeslot.slot_Name,
        checkInTime: timeslot.checkInTime,
        checkOutTime: timeslot.checkOutTime,
        checkIn_buffer: timeslot.checkIn_buffer,
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const onClickDelete = (timeslot) => {
    setTimeslot(timeslot);
    setDeleteModal(true);
  };

  const handleDeleteTimeslot = () => {
    if (timeslot) {
      dispatch(deleteTimeSlot(timeslot._id));
      setDeleteModal(false);
    }
  };

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(deleteTimeSlot(element.value));
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".shiftCheckBox:checked");
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  document.title = "Timesheet | HREVIS";

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer closeButton={false} />

        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteTimeslot}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />

        <Container fluid>
          <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "auto" }} />
          </Row>
          <Card
            style={{
              width: "100%",
              margin: "auto",
              marginTop: "-10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "14px 30px",
              }}
            >
              <div
                className="select"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  color="primary"
                  className="text-white mx-2"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  <i
                    className=" ri-add-fill"
                    style={{ verticalAlign: "middle" }}
                  />{" "}
                  Add Shift Time
                </Button>
                {isMultiDeleteButton && (
                  <Button
                    onClick={() => {
                      setDeleteModalMulti(true);
                    }}
                  >
                    <i className="ri-delete-bin-2-line"></i>
                  </Button>
                )}
              </div>
              <div className="d-flex">
                <UncontrolledDropdown direction="start">
                  <DropdownToggle color="primary">
                    <i className=" ri-more-2-fill"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <i className=" ri-file-pdf-fill fs-4" /> <span> PDF</span>{" "}
                    </DropdownItem>
                    <DropdownItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <i className="  ri-file-text-fill fs-4" />{" "}
                      <span> File</span>{" "}
                    </DropdownItem>
                    <DropdownItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <i className=" ri-printer-fill fs-4" />{" "}
                      <span>Printer</span>{" "}
                    </DropdownItem>
                    <DropdownItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <i className=" ri-eye-fill fs-4" /> <span>Eye</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Card>
          <Row>
            <ShiftsTable
              timeslots={timeslots}
              onClickDelete={onClickDelete}
              modal={modal}
              toggle={toggle}
              isEdit={isEdit}
              validation={validation}
              setModal={setModal}
              handleTimeslotClick={handleTimeslotClick}
              deleteCheckbox={deleteCheckbox}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default index;
