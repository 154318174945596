import { createSlice } from "@reduxjs/toolkit";
import {
  getAllPermissions,
  createNewPermission,
  deletePermission,
  onUpdatePermission,
} from "./thunk";

export const initialState = {
  permissions: [],
  isLoading: false,
  error: "",
};

const permissionSlice = createSlice({
  name: "permission",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllPermissions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = action.payload?.data;
      })
      .addCase(getAllPermissions.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNewPermission.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNewPermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = [...state.permissions, action.payload.data];
      })
      .addCase(createNewPermission.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePermission.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePermission.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.permissions = state.permissions.filter(
          (permission) => permission._id.toString() !== obj.toString()
        );
      })
      .addCase(deletePermission.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdatePermission.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdatePermission.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.permissions = state.permissions.map((permission) =>
          permission._id.toString() === obj._id.toString()
            ? { ...permission, ...obj }
            : permission
        );
      })
      .addCase(onUpdatePermission.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default permissionSlice.reducer;
