import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDesignations,
  createNewDesignation,
  deleteDesignation,
  onUpdateDesignation,
} from "./thunk";

export const initialState = {
  designations: [],
  isLoading: false,
  error: "",
};

const designationSlice = createSlice({
  name: "designation",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllDesignations.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllDesignations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.designations = action.payload?.data;
      })
      .addCase(getAllDesignations.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createNewDesignation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNewDesignation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.designations = [...state.designations, action.payload.data];
      })
      .addCase(createNewDesignation.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteDesignation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteDesignation.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.designations = state.designations.filter(
          (designation) => designation._id.toString() !== obj.toString()
        );
      })
      .addCase(deleteDesignation.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(onUpdateDesignation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdateDesignation.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.designations = state.designations.map((designation) =>
          designation._id.toString() === obj._id.toString()
            ? { ...designation, ...obj }
            : designation
        );
      })
      .addCase(onUpdateDesignation.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default designationSlice.reducer;
