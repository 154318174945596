import React, { useCallback, useMemo, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import TableContainer from "../../../Components/Common/TableContainer";

const PromotionsTable = ({
  data,
  setIsEdit,
  setModal,
  modal,
  setInfo,
  updateData,
  setIsDelete,
}) => {
  const [allChecked, setAllChecked] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
    }
  }, [modal]);

  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;
    },
    [toggle]
  );

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
        setAllChecked(true);
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
        setAllChecked(false);
      });
      setToBeDeleted([]);
      setAllChecked(false);
    }

    // deleteCheckbox();
  }, []);

  const handleEditClick = (e, cellProps) => {
    e.preventDefault();
    setInfo(cellProps.row.original);
    setIsEdit(true);
    setModal(true);
  };

  const handleDelete = (e, cellProps) => {
    e.preventDefault();
    updateData(cellProps, true);
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            checked={allChecked}
            onClick={() => checkedAll()}
          />
        ),

        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original}
            />
          );
        },

        id: "#",
      },
      {
        Header: "Employee",
        accessor: "employee",
        filterable: false,
      },
      {
        Header: "Shift",
        accessor: "company",
        filterable: false,
      },
      {
        Header: "Promotion Title",
        accessor: "promotionTitle",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "Date",
        accessor: "date",
        filterable: false,
      },
      {
        Header: "action",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <div onClick={(e) => handleEditClick(e, cellProps)}>
                <i className="ri-eye-fill align-bottom text-black"></i>
              </div>
              <div onClick={(e) => handleEditClick(e, cellProps)}>
                <i className="ri-pencil-fill align-bottom text-black"></i>
              </div>
              <div onClick={(e) => handleDelete(e, cellProps.row.original)}>
                <i className="ri-delete-bin-fill align-bottom text-black"></i>
              </div>
            </div>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
  return (
    <React.Fragment>
      <div>
        <Card id="contactList" style={{ width: "100%" }}>
          <CardBody className="pt-0">
            <div>
              {data?.length >= 0 ? (
                <TableContainer
                  columns={columns}
                  data={data || []}
                  setInfo={() => {}}
                  isGlobalFilter={true}
                  isFooter={true}
                  customPageSize={8}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-0"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light"
                  handleContactClick={handleContactClick}
                  isContactsFilter={false}
                  isSearchInput={true}
                  SearchPlaceholder="Search for name"
                />
              ) : (
                <Loader />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default PromotionsTable;
