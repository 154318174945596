import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import AttendenceReducer from "./attendence/reducer";

import EmployeeReducer from "./employee/reducer";
import RoleReducer from "./roles/reducer";
import PermissionReducer from "./permissions/reducer";
import Designationreducer from "./designation/reducer";
import TimeslotReducer from "./timeslot/reducer";
// API Key

//Benchmark

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Attendence: AttendenceReducer,
  ForgetPassword: ForgetPasswordReducer,
  Employee: EmployeeReducer,
  Role: RoleReducer,
  Permission: PermissionReducer,
  Designation: Designationreducer,
  Timeslot: TimeslotReducer,
});

export default rootReducer;
