import React, { useState, useCallback, useMemo, useEffect } from "react";
import Loader from "../../Components/Common/Loader";
import {
  Input,
  Card,
  CardHeader,
  Col,
  Row,
  CardBody,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getAllEmployee } from "../../slices/thunks";
import { getAllroles } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";

const AssignRole = () => {
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();

  const { employees, roles } = useSelector((state) => ({
    employees: state.Employee.employees,
    roles: state.Role.roles,
  }));

  useEffect(() => {
    if (employees && !employees.length) {
      dispatch(getAllEmployee());
    }
  }, [dispatch, employees]);

  useEffect(() => {
    setEmployee(employees);
  }, [employees]);

  useEffect(() => {
    if (roles && !roles.length) {
      dispatch(getAllroles());
    }
  }, [dispatch, roles]);

  useEffect(() => {
    setRole(roles);
  }, [roles]);

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".assignroleCheckBox");
    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      //  dispatch(onDeleteCustomer(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".assignroleCheckBox:checked");
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="assignroleCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "",
        accessor: "_id",
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: "Image",
        accessor: "photo",

        Cell: (cell) => {
          return (
            <>
              <div>
                <img
                  src={cell.value || avatar1}
                  alt=""
                  className="rounded-circle avatar-md"
                />
              </div>
            </>
          );
        },
        filterable: false,
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Center",
        accessor: "center",
      },

      {
        Header: "Assign Role",
        accessor: "role",
        Cell: (cellProps) => {
          return (
            <div className="w-50">
              <Input
                name="role"
                style={{ backgroundColor: "#17a2b8", color: "#FFF" }}
                type="select"
                className="form-select"
                value={
                  cellProps?.row?.original?.role?._id
                    ? cellProps?.row?.original?.role?._id
                    : cellProps?.value?._id
                }
                onChange={(event) => {
                  const newRoleId = event.target.value;
                }}
              >
                <option>Assign Role</option>
                {roles &&
                  roles?.map((item, key) => (
                    <option value={item._id} key={item._id}>
                      {item.type}
                    </option>
                  ))}
              </Input>
            </div>
          );
        },
      },
    ],
    [checkedAll, roles]
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />

      <div className="page-content">
        <Container fluid>
          <Row className="mt-2">
            <BreadCrumb style={{ width: "96.5%", margin: "0 auto" }} />
          </Row>
          <Row>
            <Col lg={12}>
              <Card id="customerList" style={{ width: "100%", margin: "auto" }}>
                <CardHeader className="border-0">
                  <Row>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex g-4">
                        <button
                          className="btn btn-primary me-2"
                          color="primary"
                        >
                          <i className="mdi mdi-puzzle align-bottom me-1"></i>
                          Role
                        </button>
                        <select
                          style={{
                            backgroundColor: "#17a2b8",
                            color: "#FFF",
                            width: "auto",
                          }}
                          className="form-select "
                          aria-label="Default select example"
                        >
                          <option>Select Role </option>
                          <option value="r1">Role 1</option>
                          <option value="r2">Role 2</option>
                          <option value="r3">Role 3</option>
                        </select>
                      </div>
                      <div>
                        <UncontrolledDropdown direction="start">
                          <DropdownToggle color="primary">
                            <i className=" ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              {" "}
                              <i className=" ri-file-pdf-fill fs-4" />{" "}
                              <span> PDF</span>{" "}
                            </DropdownItem>
                            <DropdownItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              {" "}
                              <i className="  ri-file-text-fill fs-4" />{" "}
                              <span> File</span>{" "}
                            </DropdownItem>
                            <DropdownItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              {" "}
                              <i className=" ri-printer-fill fs-4" />{" "}
                              <span>Printer</span>{" "}
                            </DropdownItem>
                            <DropdownItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              {" "}
                              <i className=" ri-eye-fill fs-4" />{" "}
                              <span>Eye</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {employees && employees.length ? (
                      <TableContainer
                        columns={columns}
                        data={employees || []}
                        setInfo={() => {}}
                        isGlobalFilter={true}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-0"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        isSearchInput={true}
                        isFooter={true}
                        SearchPlaceholder="Search for username"
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AssignRole;
