import React from "react";
import {
  CardHeader,
  CardBody,
  Row,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import HolidayModal from "./HolidayModal";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const HolidayCard = ({
  isMultiDeleteButton,
  setDeleteModalMulti,
  handleCreateHoliday,
  setIsExportCSV,
  data,
  columns,
  modalHoliday,
  toggle,
  isEdit,
  validation,
  setModalHoliday,
}) => {
  return (
    <React.Fragment>
      <CardHeader className="border-0">
        <Row>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <Button
                color="primary"
                onClick={handleCreateHoliday}
                className="btn btn-primary"
              >
                <i className="ri-add-line align-bottom me-1"></i> Create Holiday
              </Button>
            </div>
            <div>
              {/* {isMultiDeleteButton && (
                <button
                  className="btn btn-soft-danger me-2"
                  onClick={() => setDeleteModalMulti(true)}
                >
                  <i className="ri-delete-bin-2-line align-bottom me-1"></i>
                  Bulk Delete
                </button>
              )} */}
              <UncontrolledDropdown direction="start">
                <DropdownToggle color="primary">
                  <i className=" ri-more-2-fill"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {" "}
                    <i className=" ri-file-pdf-fill fs-4" /> <span> PDF</span>{" "}
                  </DropdownItem>
                  <DropdownItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {" "}
                    <i className="  ri-file-text-fill fs-4" />{" "}
                    <span> File</span>{" "}
                  </DropdownItem>
                  <DropdownItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {" "}
                    <i className=" ri-printer-fill fs-4" /> <span>Printer</span>{" "}
                  </DropdownItem>
                  <DropdownItem
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {" "}
                    <i className=" ri-eye-fill fs-4" /> <span>Eye</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div>
          {data && data?.length ? (
            <TableContainer
              columns={columns}
              data={data || []}
              setInfo={() => {}}
              isGlobalFilter={true}
              customPageSize={10}
              className="custom-header-css"
              divClass="table-responsive table-card mb-0"
              tableClass="align-middle table-nowrap"
              theadClass="table-light"
              isSearchInput={true}
              isFooter={true}
              SearchPlaceholder="Search for holidays"
            />
          ) : (
            <Loader />
          )}
        </div>
        <HolidayModal
          isOpen={modalHoliday}
          toggle={toggle}
          isEdit={isEdit}
          validation={validation}
          setModalHoliday={setModalHoliday}
        />
      </CardBody>
    </React.Fragment>
  );
};

export default HolidayCard;
