export const data = [
  {
    _id: 1,
    eventName: "Eid Ul ADha",
    center: "c1",
    description: "Description 1 ",
    startDate: "10 Jul, 2023",
    endDate: "14 Jul, 2023",
    status: "s1",
  },
  {
    _id: 2,
    eventName: "Victory Day",
    center: "c2",
    description: "Description 2 ",
    startDate: "11 Jul, 2023",
    endDate: "19 Jul, 2023",
    status: "s2",
  },
  {
    _id: 3,
    eventName: "Test Day",
    center: "c3",
    description: "Description 3 ",
    startDate: "11 Jul, 2023",
    endDate: "4 Jul, 2023",
    status: "s3",
  },
  {
    _id: 4,
    eventName: "Chrismas Day",
    center: "c2",
    description: "Description 4",
    startDate: "11 Jul, 2023",
    endDate: "5 Jul, 2023",
    status: "s3",
  },
];

export const centerData = [
  {
    options: [
      { label: "Center 1", value: "c1" },
      { label: "Center 2", value: "c2" },
      { label: "Center 3", value: "c3" },
    ],
  },
];
export const statusData = [
  {
    options: [
      { label: "Status 1", value: "s1" },
      { label: "Status 2", value: "s2" },
      { label: "Status 3", value: "s3" },
    ],
  },
];
