import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Row,
  Col,
  Input,
  FormFeedback,
} from "reactstrap";

const RoleModal = (props) => {
  const {
    isOpen,
    toggle,
    isEdit,
    validation,
    handleCustomizePermissions,
    setModalRole,
    permissionError,
  } = props;

  return (
    <Modal
      size="lg"
      id="createTask"
      isOpen={isOpen}
      toggle={toggle}
      modalClassName="zoomIn"
      centered
      tabIndex="-1"
    >
      <ModalHeader toggle={toggle} className="p-2 bg-soft-success">
        {!!isEdit ? "Edit Role" : "Create Role"}
      </ModalHeader>
      <ModalBody>
        <div id="task-error-msg" className="alert alert-danger py-2"></div>
        <Form
          id="creattask-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <input type="hidden" id="taskid-input" className="form-control" />

          <Row className="g-4 mb-3">
            <Col lg={6}>
              <label htmlFor="task-status" className="form-label">
                Type
              </label>

              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Role Type"
                name="type"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.type || ""}
                invalid={
                  validation.touched.type && validation.errors.type
                    ? true
                    : false
                }
              />
              {validation.touched.type && validation.errors.type ? (
                <FormFeedback type="invalid">
                  {validation.errors.type}
                </FormFeedback>
              ) : null}
            </Col>
            <Col lg={6}>
              <label htmlFor="task-status" className="form-label">
                Description
              </label>

              <Input
                type="text"
                id="task-title-input"
                className="form-control"
                placeholder="Enter Description"
                name="description"
                validate={{ required: { value: true } }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description &&
                  validation.errors.description
                    ? true
                    : false
                }
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className="g-4 mb-3">
            <Col lg={6}></Col>

            <Col lg={6}>
              <button
                type="button"
                className="btn btn-primary w-100 text-center"
                id="customizePermissions"
                onClick={() => {
                  handleCustomizePermissions();
                  setModalRole(false);
                }}
              >
                {"Customize Permissions"}
              </button>
              {permissionError && (
                <p style={{ color: "red" }}>Select at least one permission</p>
              )}
            </Col>
          </Row>
          <button
            type="submit"
            className="btn btn-success w-100"
            id="addNewTodo"
          >
            {!!isEdit ? "Update" : "Add Role"}{" "}
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default RoleModal;
