import React from "react";
import { crmWidgets } from "../../common/data";
import { Spinner } from "reactstrap";
import {
  Card,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import CountUp from "react-countup";
import useFetch from "../../helpers/useFetch";
const Widgets = () => {
  const { data, loading } = useFetch("attendence");
  document.title = "Dashboard | HREVIS";
  return (
    <React.Fragment>
      <Card style={{ padding: "0 10px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            <strong>Attandance </strong>Summary
          </h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown">
              <DropdownToggle
                tag="a"
                className="text-reset dropdown-btn"
                role="button"
              >
                <span className="text-muted">
                  02 Nov 2021 to 31 Dec 2021
                  <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem>Today</DropdownItem>
                <DropdownItem>Last Week</DropdownItem>
                <DropdownItem>Last Month</DropdownItem>
                <DropdownItem>Current Year</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>
        <div className="col-xl-12">
          <div
            className="card crm-widget"
            style={{ marginTop: "0", padding: "0px" }}
          >
            <div className="card-body p-0">
              <div className="row ">
                {loading ? (
                  <div className="d-flex justify-content-center p-3">
                    <Spinner color="primary"> Loading... </Spinner>
                  </div>
                ) : data?.length === 0 ? (
                  <>No records found.</>
                ) : (
                  crmWidgets?.map((widget, index) => {
                    const dataArray = data?.data[widget.text] || [];
                    return (
                      <div className="col" key={index}>
                        <div className="d-flex flex-column align-items-center py-2 px-2">
                          <h5 className="text-muted text-uppercase fs-12">
                            {widget.label}
                          </h5>
                          <div className="flex-grow-1">
                            <h2 className="mb-0">
                              <span className="counter-value" data-target="197">
                                <CountUp
                                  start={0}
                                  prefix={widget.prefix}
                                  suffix={widget.suffix}
                                  separator={widget.separator}
                                  end={dataArray?.length}
                                  decimals={widget.decimals}
                                  duration={4}
                                />
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Widgets;
