import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Button,
  Container,
} from "reactstrap";
import { permissions } from "./Dummydata";

const PermissionsModal = (props) => {
  const {
    setPermissionError,
    parents,
    isOpen,
    toggle,
    isEdit,
    toggleVertical,
    setModalPermission,
    setModalRole,
    setSelectedPermissions,
    selectedPermissions,
  } = props;

  const [activeTab, setActiveTab] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [groupselectAll, setGroupSelectAll] = useState(false);

  useEffect(() => {
    if (parents && parents?.length > 0) {
      setActiveTab(parents[0][0]?._id);
    }
  }, [parents]);

  useEffect(() => {
    if (selectAll) {
      const permissionsOnPage =
        parents
          .find((group) => group[0]?._id === activeTab)
          ?.map((permission) => permission._id) || [];
      setSelectedPermissions(permissionsOnPage);
    } else {
      setSelectedPermissions([]);
    }
    setPermissionError(false);
  }, [selectAll]);

  useEffect(() => {
    if (groupselectAll) {
      const allPermissions = parents.reduce((allPerms, group) => {
        const groupPermissions = group.map((item) => item._id);
        return allPerms.concat(groupPermissions);
      }, []);
      setSelectedPermissions(allPermissions);
    } else {
      setSelectedPermissions([]);
    }
    setPermissionError(false);
  }, [groupselectAll]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
  };
  const grouptoggleSelectAll = () => {
    setGroupSelectAll(!groupselectAll);
  };

  console.log(selectedPermissions);

  return (
    <Modal
      size="xl"
      id="createPermission"
      isOpen={isOpen}
      toggle={toggle}
      modalClassName="zoomIn"
      centered
      tabIndex="-1"
    >
      <ModalHeader toggle={toggle} className="p-3 bg-soft-success">
        {!!isEdit ? "Edit Customize Permissions" : "Customize Permissions"}
      </ModalHeader>
      <ModalBody>
        <Row style={{ alignItems: "center", marginBottom: "40px" }}>
          <Col>
            <div className="search-box me-2 mb-0 d-inline-block">
              <input
                type="text"
                className="form-control search "
                placeholder={"Search Permissions Group"}
              />
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </Col>
          <Col className="text-end pe-5">
            <div className="form-check form-switch form-check-right">
              <Input
                className="form-check-input"
                type="checkbox"
                checked={groupselectAll}
                onChange={grouptoggleSelectAll}
              />
              <Label
                className="form-check-label"
                for="flexSwitchCheckRightDisabled"
              >
                Select All Groups Permissions
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3} style={{ maxHeight: "480px", overflowY: "auto" }}>
                    <Nav
                      tabs
                      className="flex-column nav nav-tabs nav-tabs-custom nav-primary"
                    >
                      {(parents || []).map((group, key) => {
                        const firstWord = group[0]?.can.split(".")[0];
                        const capitalizedFirstWord =
                          firstWord.charAt(0).toUpperCase() +
                          firstWord.slice(1);
                        return (
                          <NavItem key={key}>
                            <NavLink
                              style={{ cursor: group[0]?._id ? "pointer" : "" }}
                              className={classnames({
                                "mb-2": true,
                                active: activeTab === group[0]?._id,
                              })}
                              onClick={() => {
                                toggleVertical(group[0]?._id);
                                setActiveTab(group[0]?._id);
                                setSelectAll(false);
                              }}
                              id="v-pills-home-tab"
                            >
                              {capitalizedFirstWord}
                            </NavLink>
                          </NavItem>
                        );
                      })}
                    </Nav>
                  </Col>
                  <Col lg={9} style={{ maxHeight: "480px", overflowY: "auto" }}>
                    <div>
                      <Row style={{ alignItems: "center" }}>
                        <Col>
                          <Container>
                            <div className="form-check form-switch form-check-right">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                checked={selectAll}
                                onChange={toggleSelectAll}
                              />
                              <Label
                                className="form-check-label"
                                for="flexSwitchCheckRightDisabled"
                              >
                                Select All
                              </Label>
                            </div>
                          </Container>
                        </Col>

                        <Col className="text-end">
                          <div className="search-box me-2 mb-0 d-inline-block">
                            <input
                              type="text"
                              className="form-control search "
                              placeholder={"Search permissions"}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </Col>
                      </Row>

                      <TabContent
                        activeTab={activeTab}
                        className="text-muted mt-4"
                      >
                        {(parents || []).map((item, key) => (
                          <TabPane key={key} tabId={item[0]?._id}>
                            <Row>
                              {(item || []).map((permission, permKey) => {
                                const isChecked = selectedPermissions?.some(
                                  (selectedPermission) =>
                                    selectedPermission === permission._id
                                );

                                return (
                                  <Col lg={4} key={permKey}>
                                    <div className="form-check  mb-3">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={() => {
                                          const permissionId = permission._id;
                                          setSelectedPermissions(
                                            (prevSelected) => {
                                              if (
                                                prevSelected.includes(
                                                  permissionId
                                                )
                                              ) {
                                                return prevSelected.filter(
                                                  (id) => id !== permissionId
                                                );
                                              } else {
                                                return [
                                                  ...prevSelected,
                                                  permissionId,
                                                ];
                                              }
                                            }
                                          );
                                          setPermissionError(false);
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for={`formCheck${permKey}`}
                                      >
                                        {permission.can}
                                      </Label>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </TabPane>
                        ))}
                      </TabContent>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              className="btn btn-success w-100"
              onClick={() => {
                setModalPermission(false);
                setModalRole(true);
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PermissionsModal;
