import { createSlice } from "@reduxjs/toolkit";
import {
  getAllEmployees,
  getAllAttendence,
  createAttendence,
  deleteAttendence,
  updateAttendence,
} from "./thunk";

export const initialState = {
  attendences: [],
  employees: [],
  attendences: [],
  employees: [],
  error: "",
  attendence: null,
  isLoading: false,
  isAttendence: false,
  isAttendenceLoading: false,
  currentPage: 0,
  totalPages: 0,
};

const attendenceSlice = createSlice({
  name: "attendence",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployees.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employees = action.payload.data;
      })
      .addCase(getAllEmployees.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllAttendence.pending, (state, action) => {
        state.isAttendenceLoading = true;
      })
      .addCase(getAllAttendence.fulfilled, (state, action) => {
        const { res, page } = action.payload;
        state.isAttendenceLoading = false;
        if (action.payload && action.payload.data && action.payload.data.data) {
          state.attendences = action.payload.data.data;
          state.totalPages = action.payload.data.totalPages;
          state.currentPage = page;
        } else {
          state.attendences = [];
        }
      })
      .addCase(getAllAttendence.rejected, (state, action) => {
        state.isAttendenceLoading = false;
        state.isAttendenceLoading = false;
      })
      .addCase(createAttendence.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createAttendence.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.attendences = [...state.attendences, action.payload.data];
        state.isAttendence = !state.isAttendence;
      })
      .addCase(createAttendence.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteAttendence.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteAttendence.fulfilled, (state, action) => {
        const { res, obj } = action.payload;
        state.isLoading = false;
        state.attendences = state.attendences.filter(
          (attendance) => attendance._id.toString() !== obj.toString()
        );
      })
      .addCase(deleteAttendence.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAttendence.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateAttendence.fulfilled, (state, action) => {
        state.isLoading = false;
        const { res, obj } = action.payload;
        state.attendences = state.attendences.map((attendance) =>
          attendance._id.toString() === obj._id.toString()
            ? { ...attendance, ...obj }
            : attendance
        );
        state.isAttendence = !state.isAttendence;
      })
      .addCase(updateAttendence.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default attendenceSlice.reducer;
